/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */
import {Content, CONTENT_ATTR, CONTENT_ATTR2, ContentBuildType} from "./content.interface";
import { InquiryPropsBrands } from "./inquiry.interface";

export class NaverShoppingContent extends Content {
    public link: string           // 상품 URL
    public title: string          // 상품명
    public description: string    // 상품 설명
    public lprice: string         // 최저가
    public hprice: string         // 최고가
    public mallName: string       // 쇼핑몰 이름
    public productId: string      // 상품 ID
    public productType: string    // 상품 타입
    public brand: string          // 브랜드
    public maker: string          // 제조사

    constructor(t: ContentBuildType) {
        super(t)
        this.link = ""
        this.title = ""
        this.description = ""
        this.lprice = ""
        this.hprice = ""
        this.mallName = ""
        this.productId = ""
        this.productType = ""
        this.brand = ""
        this.maker = ""
    }

    getTitle(): string {
        return this.title;
    }

    getDescription(): string {
        return this.description;
    }


    getAttr(attr: CONTENT_ATTR): any {
        return {
            lprice: this.lprice,
            hprice: this.hprice,
            productId: this.productId,
            productType: this.productType,
            brand: this.brand,
            maker: this.maker
        };
    }

    getAttrs(): any {
        return {
            [CONTENT_ATTR2.TITLE]: this.title,
            [CONTENT_ATTR2.DESCRIPTION]: this.description,
            [CONTENT_ATTR2.URL]: this.link,
        };
    }

    isBrand(brand: InquiryPropsBrands): boolean {
        const source = this.getBrandName(brand)
        return this.isIncludeTextNoCase(source, [this.brand, this.title, this.description, this.maker])
    }

    fromJSON(data: any) {
        this.link = data.link
        this.title = this.removeHtmlTags(data.title)
        this.description = this.removeHtmlTags(data.description)
        this.lprice = data.lprice
        this.hprice = data.hprice
        this.mallName = data.mallName
        this.productId = data.productId
        this.productType = data.productType
        this.brand = data.brand
        this.maker = data.maker
    }

    static getSample(): any {
        return {
            "link": "https://search.shopping.naver.com/product/30152766618",
            "title": "얼티브 프로틴 파우더",
            "description": "단백질 보충제 프로틴 파우더",
            "lprice": "35000",
            "hprice": "45000",
            "mallName": "네이버쇼핑",
            "productId": "30152766618",
            "productType": "식품",
            "brand": "얼티브",
            "maker": "얼티브"
        };
    }

    get contentUrl(): string {
        return this.link;
    }
}
