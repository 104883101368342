import { useContext } from 'react';
import { SaveContext } from './SaveContext';


export const useSaveContext = (
    props?
) => {
    // Can't find a way to specify the RecordType when CreateContext is declared
    // @ts-ignore
    const context = useContext<RecordType>(SaveContext);
    return (props && props.record) || context;
};
