import {CHART_DATA} from "./chart.interface";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ArcElement, BarElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-dayjs-4'

const colorMap2 = {
    backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(199, 199, 199, 0.2)',
        'rgba(255, 99, 71, 0.2)',
        'rgba(139, 195, 74, 0.2)',
        'rgba(255, 235, 59, 0.2)',
        'rgba(3, 169, 244, 0.2)',
        'rgba(103, 58, 183, 0.2)',
        'rgba(233, 30, 99, 0.2)',
        'rgba(0, 150, 136, 0.2)',
        'rgba(255, 87, 34, 0.2)',
        'rgba(96, 125, 139, 0.2)'
    ],
    borderColor: [
        'rgba(255, 99, 132, 1)', // 빨간색
        'rgba(54, 162, 235, 1)', // 파란색
        'rgba(255, 206, 86, 1)', // 노란색
        'rgba(75, 192, 192, 1)', // 청록색
        'rgba(153, 102, 255, 1)', // 보라색
        'rgba(255, 159, 64, 1)', // 주황색
        'rgba(199, 199, 199, 1)', // 회색
        'rgba(255, 99, 71, 1)', // 토마토색
        'rgba(139, 195, 74, 1)', // 연두색
        'rgba(255, 235, 59, 1)', // 밝은 노란색
        'rgba(3, 169, 244, 1)', // 하늘색
        'rgba(103, 58, 183, 1)', // 자주색
        'rgba(233, 30, 99, 1)', // 핫핑크
        'rgba(0, 150, 136, 1)', // 청록색
        'rgba(255, 87, 34, 1)', // 밝은 주황색
        'rgba(96, 125, 139, 1)'  // 회색
    ],
}

const colorMap = {
    schemeAccent : "7fc97fbeaed4fdc086ffff99386cb0f0027fbf5b17666666",
    schemePaired: "a6cee31f78b4b2df8a33a02cfb9a99e31a1cfdbf6fff7f00cab2d66a3d9affff99b15928",
}


export function InitChart() {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        TimeScale,
        ChartDataLabels,
        ArcElement,
        Tooltip,
    );
}

export function mapChartColor(index: number) {
    const len = colorMap2.borderColor.length
    return colorMap2.borderColor[index % len]
}

export function mapChartColorOld(index: number) {
    const src = colorMap.schemeAccent
    const count = src.length / 6
    return '#' + src.substring((index % count ) * 6, 6)
}

export function ChartDataToChartJS(src: CHART_DATA[]) {
    if (!src) {
        const s = 0
    }
    return {
        datasets: src.map((set, index) => {
            const color = mapChartColor(index)
            return {
                fill: true,
                // backgroundColor: 'red',
                label: set.id,
                data: set.data,
                borderColor: color,
                pointBackgroundColor: color,
            }
        })
    }
}

export function getMinMaxAdaptiveScale(data: any[]) {
    let max = 10, min = 0;
    for (const item of data) {
        let {count} = item
        count = Number(count)
        const digit = count.toString().length
        const unit = Math.pow(10, digit - 1)
        const gap = max * 0.1
        if (count > (max - gap)) {
            max = Math.ceil((count + gap) / unit) * unit
        }
    }
    return {
        min,
        max,
    }
}