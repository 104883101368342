/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2023/06/06
 */
import {useContext, useMemo} from "react";
import {ListContext} from "./ListContext";
import {defaults} from "lodash-es";

export const useListContext = (
    props?: any
) => {
    const context = useContext(ListContext);
    // Props take precedence over the context
    // @ts-ignore
    return useMemo(
        () =>
            defaults(
                {},
                // props != null ? extractListContextProps(props) : {},
                props || {},
                context
            ),
        [context, props]
    );
    // return context
};