/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */

import { InquiryPropsBrands } from "./inquiry.interface";


export interface ContentStatistics {
    totalContentCount: number;
    totalViewCount: number;
    totalLikeCount: number;
}

export interface BrandComparison {
    totalPosts: number;
    recentPosts: number;
    averagePostLength: number;
}


// export type ContentAttr = 'totalCount' | 'likeCount';
export enum CONTENT_ATTR {
    TOTAL_COUNT,
    TOTAL_LIKE_COUNT,
    TOTAL_VIEW_COUNT,
    TOTAL_SUBSCRIBERS,
    TOTAL_REPLAY_COUNT,
    TOTAL_VIDEO_COUNT,
}

const CONTENT_ATTR_NAME = {
    [CONTENT_ATTR.TOTAL_COUNT]: "갯수",
    [CONTENT_ATTR.TOTAL_LIKE_COUNT]: "좋아요수",
    [CONTENT_ATTR.TOTAL_VIEW_COUNT]: "조회수",
    [CONTENT_ATTR.TOTAL_SUBSCRIBERS]: "구독자수",
    [CONTENT_ATTR.TOTAL_REPLAY_COUNT]: "댓글수",
    [CONTENT_ATTR.TOTAL_VIDEO_COUNT]: "동영상수",
}

const CONTENT_ATTR_LABEL = {
    [CONTENT_ATTR.TOTAL_COUNT]: {label:"갯수", footer:"개"},
    [CONTENT_ATTR.TOTAL_LIKE_COUNT]: {label:"좋아요수", footer:"개"},
    [CONTENT_ATTR.TOTAL_VIEW_COUNT]: {label:"조회수", footer:"회"},
    [CONTENT_ATTR.TOTAL_SUBSCRIBERS]: {label:"구독자수", footer:"명"},
    [CONTENT_ATTR.TOTAL_REPLAY_COUNT]: {label:"댓글수", footer:"개"},
    [CONTENT_ATTR.TOTAL_VIDEO_COUNT]: {label:"동영상수", footer:"개"},
}

export enum CONTENT_ATTR2  {
    POST_DATE = "POST_DATE",
    TITLE = "TITLE",
    DESCRIPTION = "DESCRIPTION",
    AUTHOR = "AUTHOR",
    URL = "URL",
}

export interface ContentBuildType {
    // id: number
    name: string        // content type name
    c: any              // content build class
}

export type ATTR_TYPE = number

export abstract class Content {
    private index: number
    private cat: ContentBuildType
    private inquiryBrand: InquiryPropsBrands
    abstract get title(): string;
    abstract get contentUrl(): string;
    abstract get description(): string;
    abstract getAttr(attr: CONTENT_ATTR): ATTR_TYPE;
    abstract fromJSON(data: any): void;

    // 각 콘텐츠의 속성 이름을  공통속성으로 변화하여 오브젝트를 돌려준다
    // 주의) 기존에는 title, description 과 같은 개별 함수를 이용했음. 그때마다 작성하기 불편하여 변경함
    abstract getAttrs(): any;
    // 각 콘텐츠의 속성값을 가져온다. 
    // 속성값은 공통 속성으로 변환된 값이다. 예) (postdate 와 pubDate를 통일)
    getProp(attr2: CONTENT_ATTR2): any {
        const props = this.getAttrs()
        return props[attr2]
    }    

    static getAttrName(attr: CONTENT_ATTR) {
        return CONTENT_ATTR_NAME[attr]
    }

    static getAttrLabel(attr: CONTENT_ATTR) {
        return CONTENT_ATTR_LABEL[attr]
    }

    protected constructor(t: ContentBuildType) {
        this.index = 0
        this.cat = t
    }

    setBrand(brand: InquiryPropsBrands) {
        this.inquiryBrand = brand
    }

    getCat(): ContentBuildType {
        return this.cat
    }

    getDatetype(): ContentBuildType {
        return this.cat
    }

    getBrandName(brand: InquiryPropsBrands): string {
        return brand?.id || ""
    }


    isIncludeTextNoCase(find: string, sourceList: string[]): boolean {
        // 소스 문자열을 소문자로 변환
        const lowerSource = find.toLowerCase();  
        // 타겟 리스트에서 하나라도 포함되면 true 반환
        try {   
            return sourceList.some(item => (item || "").toLowerCase().includes(lowerSource));
        } catch (e) {
            console.error(e)
            return false
        }
    }

    /**
     * 콘텐츠가 브랜드에 포함되는지 확인한다
     * @param brand 
     * @returns 
     */
    // isBrand(brand: InquiryPropsBrands): boolean {
    //     const lowerBrand = this.getBrandName(brand).toLowerCase();
    //     const lowerTitle = (this.title || "").toLowerCase();
    //     const lowerDesc = (this.description || "").toLowerCase();
    //     return lowerTitle.includes(lowerBrand) || lowerDesc.includes(lowerBrand);
    // }
    isBrand(brand: InquiryPropsBrands): boolean {
        const source = this.getBrandName(brand)
        return this.isIncludeTextNoCase(source, [this.title, this.description])
    }

    /**
     * 콘텐츠 제목이나 설명에 포함되는 텍스트가 있는지 확인한다
     * @param text 
     * @returns 
     */
    isInclude(text: string): boolean {
        const lowerText = text.toLowerCase();
        const lowerTitle = (this.title || "").toLowerCase();
        const lowerDesc = (this.description || "").toLowerCase();
        return lowerTitle.includes(lowerText) || lowerDesc.includes(lowerText);
    }


    /**
     * 문자열에서 HTML 태그를 제거합니다.
     * @param text HTML 태그가 포함된 문자열
     * @returns HTML 태그가 제거된 순수 텍스트
     */
    removeHtmlTags(text: string): string {
        // 정규 표현식으로 <로 시작해서 >로 끝나는 부분을 모두 제거합니다.
        if (!text) return ""
        return text.replace(/<[^>]+>/g, '');
    }
}





