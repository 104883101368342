/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */
import dayjs from "dayjs";
import { ATTR_TYPE, Content, CONTENT_ATTR, CONTENT_ATTR2, ContentBuildType } from "./content.interface";
import { InquiryPropsBrands } from "./inquiry.interface";

export class NaverSearchApiContent extends Content {
    compIdx: string
    plAvgDepth: number
    relKeyword: string
    monthlyPcQcCnt: number
    monthlyAvePcCtr: number
    monthlyAvePcClkCnt: number
    monthlyMobileQcCnt: number
    monthlyAveMobileCtr: number
    monthlyAveMobileClkCnt: number

    constructor(t: ContentBuildType) {
        super(t)
        this.compIdx = ""
        this.plAvgDepth = 0
        this.relKeyword = ""
        this.monthlyPcQcCnt = 0
        this.monthlyAvePcCtr = 0
        this.monthlyAvePcClkCnt = 0
        this.monthlyMobileQcCnt = 0
        this.monthlyAveMobileCtr = 0
        this.monthlyAveMobileClkCnt = 0
    }

    get title(): string {
        return "";
    }

    get description(): string {
        return "";
    }

    get contentUrl(): string {
        return "";
    }

    getAttr(attr: CONTENT_ATTR): ATTR_TYPE {
        return 0;
    }

    getAttrs(): any {
        return {
            [CONTENT_ATTR2.POST_DATE]: "",
        };
    }

    isBrand(brand: InquiryPropsBrands): boolean {
        const source = this.getBrandName(brand)
        return this.isIncludeTextNoCase(source, [this.title, this.description])
    }

    fromJSON(data: any) {
        this.compIdx = data.compIdx
        this.plAvgDepth = data.plAvgDepth
        this.relKeyword = data.relKeyword
        this.monthlyPcQcCnt = data.monthlyPcQcCnt
        this.monthlyAvePcCtr = data.monthlyAvePcCtr
        this.monthlyAvePcClkCnt = data.monthlyAvePcClkCnt
        this.monthlyMobileQcCnt = data.monthlyMobileQcCnt
        this.monthlyAveMobileCtr = data.monthlyAveMobileCtr
        this.monthlyAveMobileClkCnt = data.monthlyAveMobileClkCnt
    }

    static getSample(): any {
        return {
            compIdx: "중간",
            plAvgDepth: 10,
            relKeyword: "수분크림",
            monthlyPcQcCnt: 7020,
            monthlyAvePcCtr: 0.35,
            monthlyAvePcClkCnt: 22.6,
            monthlyMobileQcCnt: 44700,
            monthlyAveMobileCtr: 0.87,
            monthlyAveMobileClkCnt: 360.8
        };
    }

}

