import {createContext, useMemo} from "react";
import {pick} from "lodash-es";


export const ListFilterContext = createContext({
    displayedFilters: null,
    filterValues: null,
    hideFilter: null,
    setFilters: null,
    showFilter: null,
    resource: null,
});

// export type ListFilterContextValue = Pick<
//     ListControllerResult,
//     | 'displayedFilters'
//     | 'filterValues'
//     | 'hideFilter'
//     | 'setFilters'
//     | 'showFilter'
//     | 'resource'
// >;

export const usePickFilterContext = (
    context: any
): any =>
    useMemo(
        () =>
            pick(context, [
                'displayedFilters',
                'filterValues',
                'hideFilter',
                'setFilters',
                'showFilter',
                'resource',
            ]),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            context.displayedFilters,
            context.filterValues,
            context.hideFilter,
            context.setFilters,
            context.showFilter,
        ]
    );

ListFilterContext.displayName = 'ListFilterContext';