/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2023/06/06
 */
import * as React from 'react';
import {RecordContextProvider, useRecordContext} from "@core2/index";
import {get} from "lodash-es";
import {LinearProgress, Typography} from "@mui/material";
import {memo} from "react";
import {useReferenceToOne} from "@core2/index";


export const ReferenceToOneField = props => {
    const { source, emptyText = '', ...rest } = props;
    const record = useRecordContext(props);
    const id = get(record, source);
    if (id == null) {
        console.log('id == null')
    }
    return id == null ? (
        emptyText ? (
            <Typography component="span" variant="body2">
                {/*{emptyText && translate(emptyText, { _: emptyText })}*/}
                {emptyText}
            </Typography>
        ) : null
    ) : (
        <NonEmptyReferenceToOneField
            {...rest}
            emptyText={emptyText}
            record={record}
            id={id}
        />
    );
}


export const ReferenceToOneFieldView = props => {
    const {
        children,
        className,
        emptyText,
        error,
        isLoading,
        reference,
        referenceRecord,
        // resourceLinkPath,
        sx,
    } = props;
    // const getRecordRepresentation = useGetRecordRepresentation(reference);
    // const translate = useTranslate();

    if (error) {
        return (
            /* eslint-disable jsx-a11y/role-supports-aria-props */
            // <ErrorIcon
            //     aria-errormessage={error.message ? error.message : error}
            //     role="presentation"
            //     color="error"
            //     fontSize="small"
            // />
            /* eslint-enable */
            <div>Error</div>
        );
    }
    if (isLoading) {
        return <LinearProgress />;
    }
    if (!referenceRecord) {
        // return emptyText ? (
        //     <>{emptyText && translate(emptyText, { _: emptyText })}</>
        // ) : null;
        return <Typography>{emptyText}</Typography>
    }

    // let child = children || (
    //     <Typography component="span" variant="body2">
    //         {getRecordRepresentation(referenceRecord)}
    //     </Typography>
    // );

    let child = children || ''

    // return resourceLinkPath ? (
    //     <Root className={className} sx={sx}>
    //         <RecordContextProvider value={referenceRecord}>
    //             <Link
    //                 to={resourceLinkPath as string}
    //                 className={ReferenceFieldClasses.link}
    //                 onClick={stopPropagation}
    //             >
    //                 {child}
    //             </Link>
    //         </RecordContextProvider>
    //     </Root>
    // ) : (
    //     <RecordContextProvider value={referenceRecord}>
    //         {child}
    //     </RecordContextProvider>
    // );

    return (
        <RecordContextProvider value={referenceRecord}>
            {child}
        </RecordContextProvider>
    )
};

// @ts-ignore
const PureReferenceToOneFieldView = memo(ReferenceToOneFieldView);


export const NonEmptyReferenceToOneField = props => {
    const { children, id, record, reference, target, link, ...rest } = props
    return (
        // <ResourceContextProvider value={reference}>
        <PureReferenceToOneFieldView
            reference={reference}
            {...rest}
            {...useReferenceToOne({
                reference,
                target,
                id,
            })}
            // resourceLinkPath={resourceLinkPath}
        >
            {children}
        </PureReferenceToOneFieldView>
        // </ResourceContextProvider>
    );
};
