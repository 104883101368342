import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialogContext } from './modal-dialog-context';


const ModalDialog: React.FC = () => {
  const { isOpen, dialogContent, closeModal } = useModalDialogContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      aria-labelledby="modal-dialog-title"
    >
      {dialogContent?.title && (
        <DialogTitle sx={{ m: 0, p: 2 }} id="modal-dialog-title">
          {dialogContent.title}
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent dividers>{dialogContent?.content}</DialogContent>
      {dialogContent?.actions && <DialogActions>{dialogContent.actions}</DialogActions>}
    </Dialog>
  );
};

export default ModalDialog;