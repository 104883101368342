import { NetworkError } from "@interfaces/network-error.interface"
import { FirebaseError } from "firebase/app"


const FirebaseErrorCode = {
    'auth/invalid-credential': '인증되지 않은 사용자입니다.',
    'auth/invalid-password': '비밀번호가 올바르지 않습니다.',
    'auth/invalid-email': '이메일 주소가 올바르지 않습니다.',
    'auth/user-not-found': '사용자를 찾을 수 없습니다.',
    'auth/wrong-password': '비밀번호가 올바르지 않습니다.',
    'auth/email-already-in-use': '이미 사용 중인 이메일 주소입니다.',
    'auth/weak-password': '비밀번호는 6자 이상이어야 합니다.',
    'auth/invalid-verification-code': '인증 코드가 올바르지 않습니다.',
    'auth/invalid-verification-id': '인증 ID가 올바르지 않습니다.',
    'auth/invalid-custom-token': '사용자 토큰이 올바르지 않습니다.',
}

const DefinedErrorMessage = {
    "BILLING_KEY_NOT_FOUND": "카드결제 정보 오류 (Billing Key Not Found)",
    "PAYMENT_NOT_FOUND": "결제 정보 오류 (Payment Not Found)",
}

const PGProverErrorMessage = {
    "DEFAULT": "결제 오류 입니다",
    "사용한도초과": "등록된 카드의 한도 금액을 초과 하였습니다. 금액을 확인해주세요.",
}

export function getErrorMessage(error: any) {
    let message = null
    if (error instanceof FirebaseError) {
        // firebase 에러 코드
        message =  FirebaseErrorCode[error.code]
        if (message) {
            return message
        }
    }
    if (error instanceof NetworkError) {
        const fixedMessage = DefinedErrorMessage[error.message]
        if (fixedMessage) {
            return fixedMessage
        }
        if (error.errorDetails) {
            if (error.errorDetails.type === 'PG_PROVIDER') {
                const errName = error.errorDetails.pgMessage || "DEFAULT"
                const fixedMessage = PGProverErrorMessage[errName]
                if (fixedMessage) {
                    return fixedMessage
                }
                return error.message
            }
        }
        return error.message
    }
    if (error.message) {
        const fixedMessage = DefinedErrorMessage[error.message]
        if (fixedMessage) {
            return fixedMessage
        }
        return error.message
    }
    return '알 수 없는 오류'
}