/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2023/05/13
 */
import {createContext} from "react";


export const ShowContext = createContext({
    record: null,
    defaultTitle: null,
    isFetching: null,
    isLoading: null,
    refetch: null,
    resource: null,
});

ShowContext.displayName = 'ShowContext';