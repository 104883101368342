/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 10. 6.
 */

import { createContext } from 'react';


export const InfinitePaginationContext =
    createContext({
        hasNextPage: false,
        fetchNextPage: () => Promise.reject('not implemented'),
        isFetchingNextPage: false,
        hasPreviousPage: false,
        fetchPreviousPage: () => Promise.reject('not implemented'),
        isFetchingPreviousPage: false,
    });

InfinitePaginationContext.displayName = 'InfinitePaginationContext';

