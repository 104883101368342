/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */

import { ATTR_TYPE, Content, CONTENT_ATTR, CONTENT_ATTR2, ContentBuildType } from "./content.interface";
import { InquiryPropsBrands } from "./inquiry.interface";

export enum BLOG_CONTENT_ANALYSIS_DETAIL {
    summary = 0,
    keywordTopics = 2,
    topKeywords = 4,
    keySellingPoint = 6,
    EmotionAnalysis = 8,
    writingGuide = 10,
}

const BLOG_CONTENT_ANALYSIS_HEADERS = [
    ["콘텐츠 내용 요약", "😀 콘텐츠 내용 요약"],
    ["주제별 키워드", "✅ 주제별 키워드"],
    ["자주 언급되는 키워드(TOP20)", "🔅 자주 언급되는 키워드(TOP20)"],
    ["핵심 소구점", "🔍 핵심 소구점"],
    ["감성 분석", "💕 감성 분석"],
    ["작성 가이드", "✒️ 작성 가이드"],
]

export class BlogAnalysisContent extends Content {
    public link: string
    private _title: string
    private _description: string
    public role: string
    public content: string
    public refusal: string
    public details: string[]
    public topKeywords: any[]

    constructor(t: ContentBuildType) {
        super(t)
        this.link = ""
        this._title = ""
        this._description = ""
        this.role = ""
        this.content = ""
        this.refusal = ""
    }

    get title(): string {
        return this._title;
    }

    get description(): string {
        return this._description;
    }

    get contentUrl(): string {
        return this.link;
    }

    getAttr(attr: CONTENT_ATTR): ATTR_TYPE {
        return 0;
    }

    getAttrs(): any {
        return {
            [CONTENT_ATTR2.TITLE]: this._title,
            [CONTENT_ATTR2.DESCRIPTION]: this._description,
            [CONTENT_ATTR2.URL]: this.link,
        };
    }

    isBrand(brand: InquiryPropsBrands): boolean {
        const source = this.getBrandName(brand)
        return this.isIncludeTextNoCase(source, [this.title, this.description])
    }

    parseKeywords(input: string): { keyword: string; count: number }[] {
        const result: { keyword: string; count: number }[] = [];
      
        // 키워드 패턴: 문자열 (숫자)로 구성된 부분을 정규식으로 매칭
        const regex = /([\S]+)\s*\(([^)]+)\)/g;
        let match;
      
        while ((match = regex.exec(input)) !== null) {
          const keyword = match[1]; // 괄호 앞의 키워드
          const countStr = match[2]; // 괄호 안의 값
      
          // 괄호 안의 값에서 숫자만 추출
          const count = parseInt(countStr.replace(/[^\d]/g, ""), 10);
      
          // 숫자로 변환 가능하면 결과에 추가
          if (!isNaN(count)) {
            result.push({ keyword, count });
          } else {
            result.push({ keyword, count: 0 });
          }
        }
      
        return result;
      }

    removeAllNewlines(text: string): string {
        return text.replace(/[\r\n]/g, "");
    }

    splitTextByStrings(
        text: string,
        firstString: string,
        secondString: string
    ): { firstPart: string; secondPart: string } {
        const firstIndex = text.indexOf(firstString);

        if (firstIndex === -1) {
            // 첫 번째 문자열이 없는 경우 전체 텍스트를 첫 번째 부분으로 반환
            return { firstPart: text, secondPart: "" };
        }

        // 첫 번째 문자열 이후의 텍스트 추출
        const afterFirstString = text.slice(firstIndex + firstString.length);

        // 개행 문자까지의 첫 번째 부분 추출
        const newlineIndex = afterFirstString.indexOf("\n");

        let firstPart = newlineIndex !== -1
            ? afterFirstString.slice(0, newlineIndex)
            : afterFirstString;

        let remainingText = newlineIndex !== -1
            ? afterFirstString.slice(newlineIndex + 1)
            : "";

        // 두 번째 문자열 전까지 추출
        const secondIndex = remainingText.indexOf(secondString);

        if (secondIndex !== -1) {
            return {
                firstPart,
                secondPart: remainingText.slice(0, secondIndex),
            };
        }

        // 두 번째 문자열이 없는 경우 남은 텍스트를 두 번째 부분으로 처리
        return {
            firstPart,
            secondPart: remainingText,
        };
    }

    removeLastLine(text: string): string {
        const lastNewlineIndex = text.lastIndexOf("\n");
      
        if (lastNewlineIndex === -1) {
          // 개행 문자가 없는 경우 전체 문자열 반환
          return text;
        }
      
        // 마지막 개행 문자 이전까지의 텍스트 반환
        return text.slice(0, lastNewlineIndex);
      }

    fromJSON(data: any) {
        this.link = data.link
        this._title = "블로그 분석 결과"
        this._description = "블로그 분석 결과 입니다."
        this.role = data.role
        this.content = data.content
        this.refusal = data.refusal
        this.details = []

        // const details: string[] = []
        BLOG_CONTENT_ANALYSIS_HEADERS.forEach((header, index) => {
            // header = ["구분용 텍스트", "출력용 텍스트"]
            // 현 헤더 다음 항목과의 사이 정보를 구하기 위해서, nextHeader 를 구한다. 다음헤더가 없으면 종료 위치를 구한다.
            const [startHeader, titleText] = header
            const nextHeader = BLOG_CONTENT_ANALYSIS_HEADERS[index + 1] ? BLOG_CONTENT_ANALYSIS_HEADERS[index + 1][0] : "@@@end"
            const result = this.splitTextByStrings(data.content,
                // BLOG_CONTENT_ANALYSIS_HEADERS[index][0],
                startHeader,
                nextHeader
            )
            this.details.push(titleText)
            this.details.push(this.removeLastLine(result.secondPart))
        })

        const topKeywords = this.removeAllNewlines(this.details[BLOG_CONTENT_ANALYSIS_DETAIL.topKeywords + 1])
        // const topKeywords = this.details[BLOG_CONTENT_ANALYSIS_DETAIL.topKeywords + 1]
        this.topKeywords = this.parseKeywords(topKeywords)
    }

    static getSample(): any {
        return {
            "role": "assistant",
            "content": "콘텐츠 내용 요약   \n이번 블로그 콘텐츠는 유산....",
            "refusal": null
        };
    }
}
