import * as React from 'react';
import { RecordContext } from './RecordContext';

export const RecordContextProvider = ({
    children,
    value,
}) => (
    <RecordContext.Provider value={value}>{children}</RecordContext.Provider>
);

