


export const StyleTableTemplate = {
    normalStyle: {
        // table: { border: "1px solid #e0e0e0" },
        table: {},
        header: { backgroundColor: "#f5f5f5", fontWeight: "bold" },
        headerCell: { padding: "10px", color: "#333" },
        row: { cursor: "pointer" },
        rowEven: "#ffffff", // 짝수 행 배경색
        rowOdd: "#ffffff", // 홀수 행 배경색
        cell: { padding: "10px", borderBottom: "1px solid #e0e0e0" },
        resultRow: { backgroundColor: "#e9ecef" },
        resultCell: { textAlign: "right", color: "#555" },
        highlightRow: { color: "#0264FB" },

        columnDivider: { // 세로선 관련 속성 그룹화
            topGap: '20%', // 세로선 위쪽 공백
            bottomGap: '20%', // 세로선 아래쪽 공백
            width: '1px', // 세로선 두께
            color: '#cccccc', // 세로선 색상
        },
    }
}