/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 7. 13.
 */

import {ContextType, useMemo} from 'react';
import {pick} from "lodash-es";

/**
 * This hook extracts the `save`, `saving` and mutationMode properties from either the `CreateContext` or `EditContext`. This ensures the `SaveContext` doesn't rerender when those two contexts have other properties changes.
 */
export const usePickSaveContext = (
    context: any
) => {
    const value = useMemo(
        () =>
            pick(context, [
                'save',
                // 'saving',
                // 'mutationMode',
                // 'registerMutationMiddleware',
                // 'unregisterMutationMiddleware',
            ]),
        /* eslint-disable react-hooks/exhaustive-deps */
        [
            context.save,
            // context.saving,
            // context.mutationMode,
            // context.registerMutationMiddleware,
            // context.unregisterMutationMiddleware,
        ]
        /* eslint-enable react-hooks/exhaustive-deps */
    );

    return value;
};
