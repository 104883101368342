
import { useRecordContext } from "@core2/record";
import dayjs from "dayjs";
import { get } from "lodash-es";
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ko'; // 한국어 로케일 가져오기
import { FunctionField } from "./function-field";

// 플러그인 등록
dayjs.extend(relativeTime);


export const DateField = (props) => {
    const { source, fromNow = false, ...rest } = props
    const record = useRecordContext()
    let date = get(record, source)
    if (fromNow) {
        date = dayjs(date).locale("ko").fromNow()
    } else {
        date = dayjs(date).locale("ko").format("YYYY년 MM월 DD일")
    }
    return <div>
        <FunctionField render={(r) => {
            return date
        }} {...rest}/>
    </div>
}



export const DateRangeField = (props) => {
    const { source1, source2, ...rest } = props
    const record = useRecordContext()
    const date1 = dayjs(get(record, source1)).locale("ko").format("YYYY년 MM월 DD일")
    const date2 = dayjs(get(record, source2)).locale("ko").format("YYYY년 MM월 DD일")
    return <div>
        <FunctionField render={(r) => {
            return date1 + " ~ " + date2
        }} />
    </div>
}