
import './locale/i18n';
import { AuthProvider } from "./providers/frontAuthProvider";
import { QueryClientProvider } from "@tanstack/react-query";
import { AppRoutes } from "./AppRoures";
import { AuthContext } from "@core2/auth";
import { DataProviderContext } from "@core2/data";
import { dataProvider } from "@providers/frontDataProvider";
import { AlertProvider, GlobalErrorHandler } from "@components/Alert";
import { AppDataContext, useAppDataContext } from '@providers/AppDataContext';
import { LocalDataProviderContext } from '@shared/providers/local-data-provider-context';
import { InitChart } from '@shared/components/chart';
import { RecentDataProvider } from '@shared/hooks';
import { ContentDataTypeContext } from '@shared/providers';
import { ModalDialogProvider } from '@components/dialog';
import ModalDialog from '@components/dialog/modal-dialog';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '@interfaces/color-index.interface';
import { getQueryClient } from '@providers/QueryClient';
import { CssBaseline } from '@mui/material';
import { useRegisterSW } from 'virtual:pwa-register/react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import { MaintenancePage } from '@templates/home';


InitChart()
const queryClient = getQueryClient()
ChannelService.loadScript()
ChannelService.boot({
    "pluginKey": "ea4e4a56-3a1d-4970-a270-fcee16c5c2a9"
  });

const AppWithDataType = () => {
    const { dataTypes } = useAppDataContext()
    return <>
        <ContentDataTypeContext.Provider value={{ dataTypes }}>
            <LocalDataProviderContext.Provider value={dataProvider}>

                <RecentDataProvider>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <AlertProvider>
                            <ModalDialogProvider>
                                <AppRoutes />
                                <GlobalErrorHandler />
                                <ModalDialog />
                            </ModalDialogProvider>
                        </AlertProvider>
                    </ThemeProvider>
                </RecentDataProvider>

            </LocalDataProviderContext.Provider>
        </ContentDataTypeContext.Provider>
    </>
}


export const AppCore = () => {
    return (
        <div className="App" >
            <QueryClientProvider client={queryClient}>
                <AuthContext.Provider value={AuthProvider}>
                    <DataProviderContext.Provider value={dataProvider}>
                        <AppDataContext.Provider value={{}}>
                            <AppWithDataType />
                            {/* <ReactQueryDevtools initialIsOpen={true} /> */}
                        </AppDataContext.Provider>
                    </DataProviderContext.Provider>
                </AuthContext.Provider>
            </QueryClientProvider>
        </div>
    );
}


export const App = () => {
    const {
        needRefresh,
        updateServiceWorker,
    } = useRegisterSW({
        onRegistered(registration) {
            console.log('Service Worker registered:', registration)
        },
        onNeedRefresh() {
            console.log('New content available; refreshing!')
            // 자동으로 리로드하고 싶다면:
            updateServiceWorker(true)
        },
    })
    return <>
        <AppCore />
        {/* <MaintenancePage /> */}
    </>
}

