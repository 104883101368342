/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */
import { ATTR_TYPE, Content, CONTENT_ATTR } from "./content.interface";
import {
    ListSQL, 
} from ".";
import { InquiryPropsBrands } from "./inquiry.interface";
import { get } from "lodash-es"
import { _ContentCategories } from "./content-type.interface";



export class ContentTypeProptery {
    total: number

    constructor() {
        this.total = 0
    }
}


export class ContentList {
    protected contents: Content[];
    protected listSQLInst
    protected filteredContents: Content[];  // 필터 체이닝을 위한 함수
    protected contentTypes: any

    constructor() {
        this.contents = [];
        this.contentTypes = {}
        this.listSQLInst = new ListSQL()
    }

    public build(data: any[], getDataTypeNameById: (id: any) => string): boolean {
        let TypeIndex = {}
        for (const listItem of data) {
            const dataTypeName = getDataTypeNameById(listItem.dataTypeId)
            const type = _ContentCategories.find(t => t.name === dataTypeName)
            if (!type) {
                console.error(`####  type not found [${dataTypeName}] #####`)
                continue
            }
            if (type && type.c) {
                // 같은 타입의 데이터의 인덱스 기록용
                TypeIndex[type.c] = (TypeIndex[type.name] || 0) + 1
                if (!this.contentTypes[type.name]) {
                    // 새 타입인 경우 정보 초기화
                    this.contentTypes[type.name] = new ContentTypeProptery()
                }
                const dataList = listItem.data.items
                // 콘텐츠 total 값 대입
                this.contentTypes[type.name].total = listItem.data.total || 0
                if (dataList) {
                    // list type data
                    for (const item of listItem.data.items) {
                        const inst = new type.c(type)
                        inst.fromJSON(item)
                        this.contents.push(inst)
                        inst.index = TypeIndex[type.c]
                        TypeIndex[type.c]++
                    }
                } else {
                    // single type data
                    const inst = new type.c(type)
                    inst.fromJSON(listItem.data)
                    this.contents.push(inst)
                    inst.index = TypeIndex[type.c]
                    TypeIndex[type.c]++
                }
            }
        }
        return true
    }

    public getContent(index: number): Content {
        return this.contents[index]
    }

    public identifyContentBrand(brandList: InquiryPropsBrands[]) {
        this.contents.forEach(content => {
            for (const brand of brandList) {
                if (content.isBrand(brand)) {
                    content.setBrand(brand)
                    break
                }
            }
        })
    }

    public getContentTypeProptery(name: string): ContentTypeProptery {
        return this.contentTypes[name]
    }

    public getAttrOfList(list: Content[], attr: CONTENT_ATTR): ATTR_TYPE {
        switch (attr) {
            default: {
                const sum = list.reduce((acc, content) => {
                    const v = content.getAttr(attr)
                    acc += Number(v)
                    return acc
                }, 0)
                return sum
            }
            case CONTENT_ATTR.TOTAL_VIDEO_COUNT:
            case CONTENT_ATTR.TOTAL_COUNT: {
                return list.length
            }
        }
        return 0
    }

    public getAttrsOfList(list: Content[], attrArray: CONTENT_ATTR[]): ATTR_TYPE[] {
        const resultList: ATTR_TYPE[] = []
        for (const attr of attrArray) {
            const r = this.getAttrOfList(list, attr)
            resultList.push(r)
        }
        return resultList
    }

    public getAttrsOfBrands(cat: string | null | undefined, brands: InquiryPropsBrands[], attr: CONTENT_ATTR): ATTR_TYPE[] {
        const catFiltered = cat
            ? this.contents.filter(content => content.getCat().name === cat)
            : this.contents

        const resultList: ATTR_TYPE[] = []
        // for (const brand of brands) {
        //     const filtered = catFiltered.filter(content => content.isBrand(brand))
        //     const r = this.getAttrOfList(filtered, attr)
        //     resultList.push(r)
        // }
        brands.forEach((brand, idx) => {
            const filtered = catFiltered.filter(content => content.isBrand(brand))
            // if (filtered[0]) {
            //     const a = filtered[0].isBrand(brand)
            // }
            const r = this.getAttrOfList(filtered, attr)
            resultList[idx] = r
        })
        return resultList
    }

    /**
     * 카테고리 리스트에서 브랜드를 찾아서, 지정한 속성값을 얻는다
     * @param cats 
     * @param brands 
     * @param attr 
     * @returns 
     */
    public getAttrsOfCategoriesAndBrands(cats: string[], brands: InquiryPropsBrands[], attr: CONTENT_ATTR): ATTR_TYPE[] {
        const resultList = brands.map(() => 0)
        for (const cat of cats) {
            const list = this.getAttrsOfBrands(cat, brands, attr)
            brands.forEach((brand, idx) => {
                resultList[idx] += list[idx]
            })
        }
        return resultList
    }

    public getAttrObjectOfBrands(cat: string | null | undefined, brands: InquiryPropsBrands[], attr: CONTENT_ATTR): any {
        const attrs = this.getAttrsOfBrands(cat, brands, attr);
        return brands.reduce((acc: any, brand, idx: number) => {
            acc[brand.id] = attrs[idx]
            return acc
        }, {})
    }

    public getAttrObjectOfCategoriesAndBrands(cats: string[], brands: InquiryPropsBrands[], attr: CONTENT_ATTR): any {
        const result = {}
        brands.forEach((brand) => {
            result[brand.id] = 0
        })
        for (const cat of cats) {
            const res = this.getAttrObjectOfBrands(cat, brands, attr);
            brands.forEach((brand, idx) => {
                result[brand.id] += res[brand.id]
            })
        }
        return result
    }

    /**
     * 카테고리에 맞는 콘텐츠 목록을 반환한다
     * @param cat 
     * @returns 
     */
    public getListByCategory(cat: string | null | undefined) {
        return cat
            ? this.contents.filter(content => content.getCat().name === cat)
            : this.contents
    }

    /**
     * 카테고리와 브랜드에 맞는 콘텐츠 목록을 반환한다
     * @param cat 
     * @param brands 
     * @returns 
     */
    public getListByCategoryAndBrands(cat: string | null | undefined, brands: InquiryPropsBrands[]) {
        const catList = this.getListByCategory(cat)
        if (brands.length > 0) {
            return catList.filter(content => brands.some(brand => content.isBrand(brand)))
        }
        return catList
    }

    public listSQL(query: string) {
        // const query0 = "SELECT name, age WHERE age > 25 ORDER BY age ASC";
        // const result0 = lq.sqlQuery(contentList.contents, query0);
        return this.listSQLInst.sqlQuery(this.contents, query, false);
    }

    public listSQLEx(list: any[], query: string) {
        // const query0 = "SELECT name, age WHERE age > 25 ORDER BY age ASC";
        // const result0 = lq.sqlQuery(contentList.contents, query0);
        return this.listSQLInst.sqlQuery(list, query, false);
    }

    /**
     * 브랜드별 속성 평균값을 비교하여 반환한다
     * @param myBrand 
     * @param otherBrand 
     * @param attr 
     * @returns 
     */
    // public getMyBrandAttrAverageCompare(allBrand: InquiryPropsBrands[], attr: CONTENT_ATTR) {
    public getMyBrandAttrAverageCompare(myBrand: InquiryPropsBrands, otherBrands: InquiryPropsBrands[], attr: CONTENT_ATTR) {
        // const myBrand = otherBrand[0]
        // const ohterBrands = otherBrand.slice(1)
        const myResult = this.getAttrsOfBrands(null, [myBrand], attr);
        const otherResult = this.getAttrsOfBrands(null, otherBrands, attr);
        const otherAverage = otherResult.reduce((acc, curr) => acc + curr, 0) / otherResult.length;
        return {
            myCount: myResult[0],
            otherAverage,
            myBrand,
            otherBrands
        }
    }

    /**
     * 브랜드별 속성 평균값을 한 메시지를 작성하여 반환한다
     * @param contents 
     * @param brands 
     * @param attrs 
     * @returns 
     */
    public getBrandCompareMessage(contents: ContentList, myBrand: InquiryPropsBrands, otherBrands: InquiryPropsBrands[], attrs: CONTENT_ATTR[]) {
        const attrText = {
            [CONTENT_ATTR.TOTAL_COUNT]: { post: "는", count: "개", isDiff: ["적고", "많고"], isDiffEnd: ["적어요", "많아요"] },
            [CONTENT_ATTR.TOTAL_VIEW_COUNT]: { post: "는", count: "회", isDiff: ["적고", "많고"], isDiffEnd: ["적어요", "많아요"] },
            [CONTENT_ATTR.TOTAL_LIKE_COUNT]: { post: "는", count: "개", isDiff: ["적고", "많고"], isDiffEnd: ["적어요", "많아요"] },
            [CONTENT_ATTR.TOTAL_SUBSCRIBERS]: { post: "는", count: "명", isDiff: ["적고", "많고"], isDiffEnd: ["적어요", "많아요"] },
            [CONTENT_ATTR.TOTAL_REPLAY_COUNT]: { post: "는", count: "개", isDiff: ["적고", "많고"], isDiffEnd: ["적어요", "많아요"] },
            [CONTENT_ATTR.TOTAL_VIDEO_COUNT]: { post: "는", count: "개", isDiff: ["적고", "많고"], isDiffEnd: ["적어요", "많아요"] },
        }
        const len = attrs.length
        const fix = 0
        const messages = attrs.map((attr, idx) => {
            const countTextItem = attrText[attr]
            const compareResult = contents.getMyBrandAttrAverageCompare(myBrand, otherBrands, attr)
            const { myCount, otherAverage } = compareResult
            // const diff = (Math.abs(myCount - otherAverage)).toLocaleString()
            const diff1 = Number((Math.abs(myCount - otherAverage)).toFixed(fix))
            const diff = diff1.toLocaleString()
            let isDiff = ""
            if (idx === len - 1) {
                isDiff = myCount < otherAverage ? countTextItem.isDiffEnd[0] : countTextItem.isDiffEnd[1]
            } else {
                isDiff = myCount < otherAverage ? countTextItem.isDiff[0] : countTextItem.isDiff[1]
            }
            const name = Content.getAttrName(attr)
            const m = `${name}${countTextItem.post} ${diff}${countTextItem.count} ${isDiff}`
            return m
        })
        return messages.join(", ")
    }

    ////////////////////////////////////
    // 체이닝 리스팅 방식 함수들

    // 필터링된 콘텐츠 리스트 반환
    getFiltered(): Content[] {
        return this.filteredContents
    }

    // 필터를 초기화하고 원본 리스트로 재설정
    resetFilters(): this {
        this.filteredContents = [...this.contents]; // 원본 리스트로 초기화
        return this;
    }

    // 체이닝 가능한 메서드: 조건에 맞는 콘텐츠 필터링
    filterBy(predicate: (content: Content) => boolean): this {
        this.filteredContents = this.filteredContents.filter(predicate);
        return this;
    }

    // 체이닝 가능한 메서드: 조건에 맞는 콘텐츠 필터링
    map(predicate: (content: Content) => any): this {
        this.filteredContents = this.filteredContents.map(predicate);
        return this;
    }

    reduce(predicate: (content: Content) => any, initialValue: any): this {
        this.filteredContents = this.filteredContents.reduce(predicate, initialValue);
        return this;
    }


    // 데이터 타입으로 필터링 (
    filterByDatatype(datatype: string | null | undefined): this {
        if (!datatype) return;  // 데이터 타입 없으면 filteredContents 유지
        return this.filterBy(content => content.getDatetype().name === datatype)
    }

    // 브랜드로 필터링
    filterByBrand(brand: InquiryPropsBrands): this {
        return this.filterBy(content => content.isBrand(brand))
    }

    // 브랜드 명 배열로 필터링
    filterByBrandArray(brands: InquiryPropsBrands[]): this {
        return this.filterBy(content => brands.some(brand => content.isBrand(brand)))
    }

    ////////////////////////////////////////////////////////////
    // 연산값 반환 계열
    count(): number {
        return this.filteredContents.length
    }

    average(): number {
        return this.filteredContents.reduce((acc, item: any) => (acc + item), 0) / this.filteredContents.length
    }

    // uniqueBy(prop: keyof Content): any[] {
    uniqueBy(prop: string): any[] {
        const uniqueValues = new Set(
            this.filteredContents.map(content => content[prop])
        );
        return Array.from(uniqueValues);
    }





    


}

