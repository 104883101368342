// import {usePaginationState} from "@core2/index";
// import {useSafeSetState} from "@core2/index";
import {useEffect} from "react";
import {useSafeSetState} from "@core2/hooks";
// @ts-ignore
import {usePaginationState} from "@core2/index";
import React from "react";

const defaultFilter = {};


export const useList = (props: any) => {
    const {
        data,
        error,
        filter = defaultFilter,
        isFetching = false,
        isLoading = false,
        page: initialPage = 1,
        perPage: initialPerPage = 1000,
        sort: initialSort,
        filterCallback = (record: any) => Boolean(record),
        resource,
    } = props;

    const [fetchingState, setFetchingState] = useSafeSetState<boolean>(
        isFetching
    ) as [boolean, (isFetching: boolean) => void];

    const [loadingState, setLoadingState] = useSafeSetState<boolean>(
        isLoading
    ) as [boolean, (isFetching: boolean) => void];

    const [finalItems, setFinalItems] = useSafeSetState<{
        data?: any[];
        total?: number;
    }>(() => ({
        data,
        total: data ? data.length : undefined,
    }));

    const { page, setPage, perPage, setPerPage } = usePaginationState({
        page: initialPage,
        perPage: initialPerPage,
    });


    // useEffect(() => {
    //     if (!isEqual(filter, filterRef.current)) {
    //         filterRef.current = filter;
    //         setFilterValues(filter);
    //     }
    // });

    // We do all the data processing (filtering, sorting, paginating) client-side
    useEffect(
        () => {
            if (isLoading || !data) return;
            let tempData = data;

            // 1. filter
            // if (filterValues) {
            //     const flattenFilterValues = flattenObject(filterValues);
            //     tempData = data
            //         .filter(record =>
            //             Object.entries(flattenFilterValues).every(
            //                 ([filterName, filterValue]) => {
            //                     const recordValue = get(record, filterName);
            //                     const result = Array.isArray(recordValue)
            //                         ? Array.isArray(filterValue)
            //                             ? recordValue.some(item =>
            //                                 filterValue.includes(item)
            //                             )
            //                             : recordValue.includes(filterValue)
            //                         : Array.isArray(filterValue)
            //                             ? filterValue.includes(recordValue)
            //                             : filterValue == recordValue; // eslint-disable-line eqeqeq
            //                     return result;
            //                 }
            //             )
            //         )
            //         .filter(filterCallback);
            // }
            const filteredLength = tempData.length;

            // 2. sort
            // if (sort.field) {
            //     tempData = tempData.sort((a, b) => {
            //         if (get(a, sort.field) > get(b, sort.field)) {
            //             return sort.order === 'ASC' ? 1 : -1;
            //         }
            //         if (get(a, sort.field) < get(b, sort.field)) {
            //             return sort.order === 'ASC' ? -1 : 1;
            //         }
            //         return 0;
            //     });
            // }

            // 3. paginate
            tempData = tempData.slice((page - 1) * perPage, page * perPage);

            setFinalItems({
                data: tempData,
                total: filteredLength,
            });
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            // eslint-disable-next-line react-hooks/exhaustive-deps
            JSON.stringify(data),
            // React.isValidElement(data) ? data : JSON.stringify(data),
            // filterValues,
            isLoading,
            page,
            perPage,
            setFinalItems,
            // sort.field,
            // sort.order,
        ]
    );

    useEffect(() => {
        if (isFetching !== fetchingState) {
            setFetchingState(isFetching);
        }
    }, [isFetching, fetchingState, setFetchingState]);

    useEffect(() => {
        if (isLoading !== loadingState) {
            setLoadingState(isLoading);
        }
    }, [isLoading, loadingState, setLoadingState]);

    return {
        // sort,
        data: finalItems?.data,
        // data,
        defaultTitle: '',
        error,
        // displayedFilters,
        // filterValues,
        hasNextPage:
            finalItems?.total == null
                ? false
                : page * perPage < finalItems.total,
        hasPreviousPage: page > 1,
        // hideFilter,
        isFetching: fetchingState,
        isLoading: loadingState,
        // onSelect: selectionModifiers.select,
        // onToggleItem: selectionModifiers.toggle,
        // onUnselectItems: selectionModifiers.clearSelection,
        page,
        perPage,
        resource,
        // refetch,
        // selectedIds,
        // setFilters,
        setPage,
        setPerPage,
        // setSort,
        // showFilter,
        total: finalItems?.total,
    };
}
