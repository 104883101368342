/**
 * Author : Bruce.Decent, the Sunbed Nomad
 * Date: 2021/07/13
 */


import { createContext } from 'react';

// import { DataProvider } from "@core2/index";

// const DataProviderContext = createContext<DataProvider>(null);
export const DataProviderContext = createContext<any>(null);

DataProviderContext.displayName = 'DataProviderContext';

// export default DataProviderContext;
