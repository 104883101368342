import { lazy, Suspense } from 'react';
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import { AuthorizedPage } from "./components/View";
import { FooterContainer, HeaderContainer } from '@templates/common';
import { Box } from '@mui/material';
import { COLOR_INDEX } from '@interfaces/color-index.interface';
import { ClientContainer } from '@containers/common';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Error404 } from '@templates/error/404';



const Home = lazy(() => import('@templates/home').then(module => ({ default: module.Home })));
const ViralAnalysisPage = lazy(() => import('@templates/viral-anlaysis').then(module => ({ default: module.ViralAnalysisPage })));
const ContentAnalysisPage = lazy(() => import('@templates/content-analysis').then(module => ({ default: module.ContentAnalysisPage })));
const ContentCreationPage = lazy(() => import('@templates/content-creation').then(module => ({ default: module.ContentCreationPage })));


const MembershipPage = lazy(() => import('@templates/membership').then(module => ({ default: module.MembershipPage })));
const MyPage = lazy(() => import('@templates/my-page').then(module => ({ default: module.MyPage })));
const SubscribePage = lazy(() => import('@templates/membership').then(module => ({ default: module.SubscribePage })));
const SubscribeDonePage = lazy(() => import('@templates/membership').then(module => ({ default: module.SubscribeDonePage })));
const RegisterPage = lazy(() => import('@templates/login/').then(module => ({ default: module.RegisterPage })));
const LoginPage = lazy(() => import('@templates/login/login-page').then(module => ({ default: module.LoginPage })));
const LoginHelpPage = lazy(() => import('@templates/login/login-help-page').then(module => ({ default: module.LoginHelpPage })));
const WelcomePage = lazy(() => import('@templates/login/welcome-page').then(module => ({ default: module.WelcomePage })));
const TermsPage = lazy(() => import('@templates/common/terms').then(module => ({ default: module.TermsPage })));
const PrivacyPage = lazy(() => import('@templates/common/privacy').then(module => ({ default: module.PrivacyPage })));
const MarketingPage = lazy(() => import('@templates/common/marketing').then(module => ({ default: module.MarketingPage })));

const TestPage = lazy(() => import('@templates/test').then(module => ({ default: module.TestPage })));
const IsAuth = lazy(() => import('@templates/login/is-auth').then(module => ({ default: module.IsAuth })));
const UnregisterPage = lazy(() => import('@templates/login/').then(module => ({ default: module.UnregisterPage })));
const SuspendedPage = lazy(() => import('@templates/login/').then(module => ({ default: module.SuspendedPage })));
const MembershipCancelPage = lazy(() => import('@templates/membership/').then(module => ({ default: module.MembershipCancelPage })));
const MembershipCanceledPage = lazy(() => import('@templates/membership/').then(module => ({ default: module.MembershipCanceledPage })));
////////////////////////////////
// 마이그레이션 참조
// https://www.jaeme.dev/react-router-v6/
//////////////////////////////////////

const HomeLayout = () => {
    return (
        <>
            <HeaderContainer />
            <Box sx={{ height: "100px" }} />
            <Outlet /> {/* 자식 라우트를 렌더링 */}
            <Box sx={{ height: "120px" }} />
            <FooterContainer />
        </>
    );
};


const MainLayout = () => {
    return (
        <>
            <HeaderContainer />
            <ClientContainer sx={{
                // backgroundColor: COLOR_INDEX.BACKGROUND_COLOR,
                mt: "100px",
                mb: "120px",
            }}>
                <Outlet />
            </ClientContainer>
            <FooterContainer />
        </>
    );
};

const MinimalLayout = () => {
    return (
        <>
            <ClientContainer sx={{
                // backgroundColor: COLOR_INDEX.BACKGROUND_COLOR,
                mt: "100px",
                mb: "120px",
            }}>
                <Outlet />
            </ClientContainer>
        </>
    );
};

export const AppRoutes = (props: any) => {
    const location = useLocation();
    return (
        // <TransitionGroup>
        // <CSSTransition key={location.key} classNames="fade" timeout={300} >
        <Suspense fallback={<div>Loading...</div>}>
            <Routes location={location}>
                <Route element={<HomeLayout />}>
                    <Route path='/' element={<Home />} />
                </Route>
                <Route element={<MainLayout />}>
                    
                    <Route path='/login' element={<LoginPage />} />
                    <Route path='/login/help' element={<LoginHelpPage />} />
                    <Route path='/register' element={<RegisterPage />} />
                    <Route path='/welcome' element={<WelcomePage />} />
                    <Route path='/membership' element={<MembershipPage />} />
                    <Route path='/membership/cancel' element={<MembershipCancelPage />} />
                    <Route path='/membership/canceled' element={<MembershipCanceledPage />} />
                    <Route path='/subscribe' element={<SubscribePage />} />
                    <Route path='/subscribed' element={<SubscribeDonePage />} />
                    <Route path='/test' element={<TestPage />} />
                    <Route path='/unregister' element={<UnregisterPage />} />
                    <Route path='/suspended' element={<SuspendedPage />} />
                    
                    <Route path="/trend/*" element={
                        // <AuthorizedPage redirect={'/'} redirectEmailVerify={'/verify'}>
                            <Routes>
                                <Route path='' element={<ViralAnalysisPage />} />
                            </Routes>
                        // </AuthorizedPage>
                    } />

                    <Route path="/content/*" element={
                        // <AuthorizedPage redirect={'/'} redirectEmailVerify={'/verify'}>
                            <Routes>
                                <Route path='' element={<ContentAnalysisPage />} />
                            </Routes>
                        // </AuthorizedPage>
                    } />


                    <Route path="/generate/*" element={
                        // <AuthorizedPage redirect={'/'} redirectEmailVerify={'/verify'}>
                            <Routes>
                                <Route path='' element={<ContentCreationPage />} />
                            </Routes>
                        // </AuthorizedPage>
                    } />

                    <Route path="/my/*" element={
                        <AuthorizedPage redirect={'/'} redirectEmailVerify={'/verify'}>
                            <Routes>
                                <Route path='' element={<MyPage />} />
                            </Routes>
                        </AuthorizedPage>
                    } />

                </Route>

                <Route element={<MinimalLayout />}>
                    <Route path='/doc/terms' element={<TermsPage />} />
                    <Route path='/doc/privacy' element={<PrivacyPage />} />
                    <Route path='/doc/marketing' element={<MarketingPage />} />
                </Route>

                <Route element={<MinimalLayout />}>
                    <Route path='/auth/me' element={<IsAuth />} />
                </Route>


                <Route path="*" element={<Error404/>}/>
            </Routes>
        </Suspense>
        // </CSSTransition>    
        // </TransitionGroup>
    )
}


