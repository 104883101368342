import { NetworkError } from '@interfaces/network-error.interface';
import axios, { AxiosHeaders } from 'axios';
import { getQueryClient } from './QueryClient';
import { AuthProvider, IDENTITY_QUERY_KEY } from './frontAuthProvider';


export interface FetchOptions {
    headers?: Record<string, string>;
    method?: string;
    body?: any;
}

export interface ServerError {
    code: number;
    message: string;
}

export interface AxiosResponse {
    config: any;
    data: {
        error: ServerError
        data: any;
    }
    headers: AxiosHeaders
    request: any
    status: number;
    statusText: string;
}

export interface FetchResult {
    status: number;
    // headers: AxiosHeaders
    headers: any
    json: {
        error: ServerError
        data: any;
    }
}

function convertQueryString(query: any) {
    let str = ''
    for (const [key, value] of Object.entries(query)) {
        if (value === undefined) continue
        if (str) {
            str += '&'
        }
        if (typeof value === 'object') {
            const jsonString = encodeURIComponent(JSON.stringify(value))
            // const jsonString = JSON.stringify(value)
            str += `${key}=${jsonString}`
        } else {
            const encodedValue = encodeURIComponent(value as string);
            str += `${key}=${encodedValue}`
        }
    }
    return str;
}

function errorProcess(errorMessage: string) {
    switch (errorMessage) {
        case 'token expired': {
            // 토큰 만료
            // todo: 에러 처리 필요. 왜 토큰이 만료되지? 로그아웃 시켜야 하나?
            // 강제로 캐시 invalidate
            AuthProvider.refreshIdentity()
            getQueryClient().invalidateQueries({ queryKey: [IDENTITY_QUERY_KEY] })
            // 강제 새로고침
            window.location.reload();
            break
        }
        case 'Suspended': {
            // 중지된 사용자
            AuthProvider.refreshIdentity()
            getQueryClient().invalidateQueries({ queryKey: [IDENTITY_QUERY_KEY] })
            // 강제 새로고침
            window.location.reload();
            break
        }
        case 'Unauthorized': {
            break
        }
    }
}


export async function axiosFetchJson(url: string, options: FetchOptions = {}): Promise<FetchResult> {
    const { headers, method = 'GET', body } = options;

    try {
        let URL = url
        let bodyData = body
        const fixedMethod = method.toUpperCase()
        switch (fixedMethod) {
            case 'GET':
            case 'DELETE': {
                const query = convertQueryString(body)
                URL = query ? url + "?" + query : url
                bodyData = undefined
                break
            }
            case 'POST':
            case 'PUT': {
                // post, put 은 body 를 object 로 그대로 사용
                // bodyData = body ? JSON.stringify(body) : undefined
                bodyData = body
                break
            }
        }

        const response: AxiosResponse = await axios({
            url: URL,
            method,
            headers,
            data: bodyData, // axios는 body 대신 data 사용
        });

        if (response.data.error) {
            // 정상 응답이지만 에러인 경우. 에외 발생 시킬것
            const err: any = response?.data?.error || {}
            throw new NetworkError(
                err.message, 
                err.code, 
                err.errorDetails, 
                response.status,
                response.headers,
            )
        }

        return {
            status: response.status,
            headers: response.headers,
            json: {
                // data: response.data,
                data: response.data.data,
                error: response.data?.error,
            }
        };
    } catch (error) {
        
        if (error.response) {
            const errorMessage: string = 
                typeof error.response.data?.error === "object" 
                    ? error.response.data?.error?.message
                    : error.response.data?.error

            errorProcess(errorMessage)
            
            throw new NetworkError(
                errorMessage, 
                0, 
                error.response.errorDetails, 
                error.response.status,
                error.response.headers,
            )
        } else {
            errorProcess(error.message)
            // 네트워크 오류 또는 다른 이유
            // throw new NetworkError(error.message, error.code, {});
            throw error
        }
    }
};