/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    ChartData,
    ChartOptions
} from 'chart.js';
import {DoughnutChartHandle} from "./doughnut-chart.interface";
import { Box } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
    handle: DoughnutChartHandle;
    width?: any;
    height?: any;
    title?: string;
    unit?: string;
    sx?: any;
    legend?: any;
    formatter?: (value: number) => string;
    labelOffset?: number;
}

export const DoughnutChart: React.FC<DoughnutChartProps> = (props: DoughnutChartProps) => {
    const {handle, title, legend, unit="", sx, formatter, labelOffset=5} = props
    const chartData: ChartData<'doughnut'> = {
        labels: handle.getLabels(),
        datasets: [{
            data: handle.getPercentages(),
            backgroundColor: handle.getColors(),
            borderColor: 'white',
            borderWidth: 2,
            hoverOffset: 4
        }]
    };

    const options: ChartOptions<'doughnut'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: !!title,
                text: title || '',
                font: {
                    size: 16,
                    weight: 'bold'
                },
                padding: {
                    bottom: 20
                }
            },
            legend: legend ?? {
                display: true,
                position: 'right',
                labels: {
                    usePointStyle: true,
                    padding: 20,
                    font: {
                        size: 12
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.label || '';
                        const value = context.parsed || 0;
                        return `${label}: ${value}%`;
                    }
                }
            },
            datalabels: {
                color: '#000',
                formatter,
                font: {
                    size: 14,
                    weight: 'bold'
                },
                anchor: 'end',
                align: 'start',
                offset: labelOffset
            }
        },
        cutout: '45%'
    };

    return (
        <Box sx={sx} >
            <Doughnut data={chartData} options={options} />
        </Box>
    );
};
