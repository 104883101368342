import * as yup from "yup";


export const defaultValidationSchema = {
    // email: yup
    //     .string()
    //     .email('유효한 이메일 주소를 입력해 주세요.')
    //     .required('이메일은 필수 항목입니다.'),
    email: yup
          .string()
          .email('올바른 이메일 형식이 아닙니다.')
          .required('이메일(아이디)을 입력해주세요.'),
    
    password: yup
        .string()
        .required('비밀번호는 필수 항목입니다.')
        .min(8, '비밀번호는 최소 8자 이상이어야 합니다.')
        .max(20, '비밀번호는 최대 20자 이하여야 합니다.'),
        // .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/, '비밀번호는 문자와 숫자를 포함해야 합니다.')
        

    passwordConfirm: yup
        .string()
        .required('비밀번호 확인은 필수 항목입니다.')
        .oneOf([yup.ref('password'), null], '비밀번호가 일치하지 않습니다.'),
        

    name: yup
        .string()
        .required('이름을 입력해주세요.')
        .min(2, '이름은 최소 2자 이상이어야 합니다.')
        .max(50, '이름은 최대 32자 이하여야 합니다.'),          


    phone: yup
        .string()
        .required('휴대폰 번호를 입력해주세요.')
        .min(11, '휴대폰 번호를 정확히 입력해주세요.') // 최소 11자리
        .max(11, '휴대폰 번호는 11자리까지만 입력 가능합니다.'), // 최대 11자리

    code: yup
        .string()
        .required('인증 코드는 필수 항목입니다.')
        .matches(/^\d{6}$/, '인증 코드는 6자리 숫자여야 합니다.'),
        
}