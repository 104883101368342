import React, { createContext, useState, ReactNode, useContext, FC } from 'react';

// 다이얼로그의 내용을 정의하는 인터페이스
interface ModalDialogContent {
    title?: string;
    content: ReactNode;
    actions?: ReactNode;
}

// 다이얼로그 컨텍스트의 상태 및 함수 정의
interface ModalDialogContextProps {
    isOpen: boolean;
    dialogContent: ModalDialogContent | null;
    openModal: (content: ModalDialogContent) => void;
    closeModal: () => void;
}

// 초기 컨텍스트 값
const ModalDialogContext = createContext<ModalDialogContextProps | undefined>(undefined);

// 제공자 컴포넌트
export const ModalDialogProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<ModalDialogContent | null>(null);

    const openModal = (content: ModalDialogContent) => {
        setDialogContent(content);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setDialogContent(null);
    };

    return (
        <ModalDialogContext.Provider value={{ isOpen, dialogContent, openModal, closeModal }}>
            {children}
        </ModalDialogContext.Provider>
    );
};

// 커스텀 훅을 통해 컨텍스트를 쉽게 사용할 수 있게 함
export const useModalDialogContext = (): ModalDialogContextProps => {
    const context = useContext(ModalDialogContext);
    if (!context) {
        throw new Error('useModalDialogContext must be used within a ModalDialogProvider');
    }
    return context;
};