


import {createContext, useMemo} from "react";
import {pick} from "lodash-es";

export const ListPaginationContext = createContext({
    isLoading: null,
    page: null,
    perPage: null,
    setPage: null,
    setPerPage: null,
    hasPreviousPage: null,
    hasNextPage: null,
    total: undefined,
    resource: null,
});


ListPaginationContext.displayName = 'ListPaginationContext'


export const usePickPaginationContext = (
    context: any
) : any =>
    useMemo(
        () =>
            pick(context, [
                'isLoading',
                'hasPreviousPage',
                'hasNextPage',
                'page',
                'perPage',
                'setPage',
                'setPerPage',
                'total',
                'resource',
            ]),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            context.isLoading,
            context.hasPreviousPage,
            context.hasNextPage,
            context.page,
            context.perPage,
            context.setPage,
            context.setPerPage,
            context.total,
        ]
    );