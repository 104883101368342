/**
 * Author : Bruce.Decent, the Sunbed Nomad
 * Date: 2021/05/01
 */


import { pascalCase } from "change-case";
import { simpleApiQuery } from "./simpleApiQuery";
import stringify from 'query-string';
import { FetchResult } from "./axiosFetch";

function removeParamsProperty(params, props) {
    for (const prop of props) {
        if (params.data[prop]) {
            delete params.data[prop]
        }
    }
}

function getRangeByPagination(pagination) {
    const { page, perPage } = pagination;
    return [(page - 1) * perPage, perPage]
}



function parseJSONIfValid(jsonString) {
    try {
        const parsed = JSON.parse(jsonString);
        // JSON.parse가 성공하면 JSON 문자열이므로 객체를 반환
        return parsed;
    } catch (error) {
        // JSON.parse가 실패하면 문자열이 JSON이 아님
        return jsonString;
    }
}

/**
 * 전달받은 object 모든 prop 가 정상적인 json (if json-string) 값을 가지도록 한다
 * @param data
 */
function makeValidJSONResponse(data) {
    for (const key of Object.keys(data)) {
        data[key] = parseJSONIfValid(data[key])
    }
    return data
}

function makeValidJSONResponseList(list) {
    for (const item of list) {
        makeValidJSONResponse(item)
    }
    return list
}

function listFilterToSQLQuery2(params) {
    const sqlQuery = {
        select: undefined,
        filter: params.filter,
        range: getRangeByPagination(params.pagination),
        sort: undefined,
    }
    const { field, order } = params.sort;
    if (field && order) {
        sqlQuery.sort = [[field, order]]
    }
    return sqlQuery
}

function dataToQueryString(data: any) {
    // 각 prop 을 stringify 한다
    for (const key of Object.keys(data)) {
        if (data[key]) {
            data[key] = JSON.stringify(data[key])
        }
    }
    // 전체를 쿼리 스트링으로 변경한다(JSON.stringify 쓰면 안됨. stringify.stringify 는 filter=1&range=10 로 변환함)
    return stringify.stringify(data)
}


export const dataProvider = {


    getList: (resource: string, params: any) => {
        const resourceParam = pascalCase(resource)

        //////////////////////////////////////////////////////////////////////////
        const sqlQuery = listFilterToSQLQuery2(params)
        // const stringQuery = dataToQueryString(sqlQuery)        
        const url = `user-query/${resourceParam}`;
        return simpleApiQuery("get", url, sqlQuery).then((result : FetchResult) => {
            const {json} = result
            if (json.error) {
                // something wrong
                return {
                    data: [],
                    total: 0,
                }
            }
            const list = makeValidJSONResponseList(json.data.list)
            return {
                data: list,
                total: json.data.total,
            }
        })
    },
    getOne: (resource: string, params: any) => {
        const resourceParam = pascalCase(resource)
        if (!params.id) {
            // id 가 없으면 쿼리 결과 없음으로 처리해야 함
            return Promise.resolve(null)
        }
        const url = `user-query/${resourceParam}/${params.id}`;
        return simpleApiQuery('get', url, {}).then((r: FetchResult) => {
            const { json } = r
            if (json.error) {
                // something wrong
                return null;
            }
            if (!json.data) {
                // null 일수 있음!
                return {}
            }
            const data = makeValidJSONResponse(json.data)
            return data
        })

    },
    getMany: (resource, params) => {
        const queryData = {
            // sql: JSON.stringify({
            //     where: `where id = (${params.ids.map(d => `'${d}'`).join(',')})`
            // })
            filter: JSON.stringify({
                id: params.ids,
            })
        }
        const query = stringify.stringify(queryData)            
        const resourceParam = pascalCase(resource)
        const url = `user-query/${resourceParam}`;
        return simpleApiQuery('get', url, queryData).then(({ json }: FetchResult) => {
            // const a = params
            if (json.error) {
                return {
                    data: []
                }
            }
            const list = json.data.list
            return {
                data: list
            }
        });
    },
    getManyReference: (resource, params) => {
        //////////////////////////////////////////////////////////////////
        // params.filter = params.filter || {}
        // params.filter[params.target] = params.id
        // const query = makeDefaultQuery(resource, params)
        const resourceParam = pascalCase(resource)
        const queryData = {
            sql: JSON.stringify({
                where: `where ${params.target} = (${params.id})`
            })
        }
        const query = stringify.stringify(queryData)
        // const url = `${serverApiUrl}/${resource}?${query}`;
        // const url = `${serverApiUrl}/user-query/${resourceParam}?${query}`;
        // //////////////////////////////////////////////////////////////////
        // return httpAxiosRequest(url).then(({ headers, json }: FetchResult) => {
        const url = `user-query/${resourceParam}`;
        //////////////////////////////////////////////////////////////////
        return simpleApiQuery("get", url, queryData).then(({ headers, json }: FetchResult) => {
            if (json.error) {
                return {
                    data: [],
                    total: 0,
                }
            }
            return {
                data: makeValidJSONResponseList(json.data.list),
                total: json.data.total,
            }
        });
    },
    update: (resource, params) => {
        removeParamsProperty(params, ['createdAt', 'updatedAt'])
        // resource = virtualResourceBinder(resource)
        const resourceParam = pascalCase(resource)
        //////////////////////////////////////////////////////////////////////////
        // const url = `${serverApiUrl}/user-query/${resourceParam}/${params.id}`;
        // return httpAxiosRequest(url, {
        //     method: 'PUT',
        //     body: JSON.stringify(params.data),
        // }).then(({ json }: FetchResult) => {
        const url = `user-query/${resourceParam}/${params.id}`;
        return simpleApiQuery('put', url, params.data).then(({ json }: FetchResult) => {
            if (json.error) {
                // return returnJsonHttpError(json)
                throw new Error(json.error.message)
            }
            return {
                data: json.data
            }
        })
    },

    updateMany: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${resource}`
        return simpleApiQuery('put', url, params.data).then(({ json }: FetchResult) => {
            if (json.error) {
                // return returnJsonHttpError(json)
                throw new Error(json.error.message)
            }
            const { result, data } = json.data
            return { data }
        });
    },

    create: async (resource: string, params: any) => {
        const resourceParam = pascalCase(resource)
        const url = `user-query/${resourceParam}`;
        return simpleApiQuery('post', url, params.data).then((res: FetchResult) => {
            const { json } = res
            if (json.error) {
                const { error } = json
                throw new Error(error.message)
            }
            return {
                ...json.data,
            }
        })
    },

    delete: (resource: string, params: any) => {
        const resourceParam = pascalCase(resource)
        if (!params.id) {
            // id 가 없으면 쿼리 결과 없음으로 처리해야 함
            // return Promise.reject(null)
            throw new Error("id is required")
        }
        const url = `user-query/${resourceParam}/${params.id}`
        return simpleApiQuery('delete', url, {params}).then((result: FetchResult) => {
            const { json } = result
            if (json.error) {
                const { error } = json
                throw new Error(error.message)
            }
            return {
                ...json.data,
            }
        })
    },


    deleteMany: (resource: string, params) => {
        // resource = virtualResourceBinder(resource)
        const resourceParam = pascalCase(resource)
        // const query = makeDefaultQuery(resource, {
        //     filter: JSON.stringify({ids: params.ids}),
        // })
        const filter = JSON.stringify({ ids: params.ids })
        // return httpRequest(`${serverApiUrl}/${resource}?${JSON.stringify(query)}`, {
        // return httpAxiosRequest(`${serverApiUrl}/user-query/${resourceParam}?filter=${filter}`, {
        //     method: 'DELETE',
        // }).then(({ json }: FetchResult) => {
        const url = `user-query/${resourceParam}`
        return simpleApiQuery('delete', url, {filter}).then((result: FetchResult) => {
            // { data: {mixed[]} } The ids of the deleted records (optional)
            const { json } = result
            if (json.error) {
                const { error } = json
                throw new Error(error.message)
            }
            return {
                ...json.data,
            }
        })
    }
}

