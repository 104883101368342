/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 7. 3.
 */

import {useQuery} from "@tanstack/react-query";
import {useMemo} from "react";
import { QUERY_GET_LIST_PARAMS } from "../interfaces";
import { useLocalDataProvider } from "../providers/local-data-provider";
// import { useDataProvider } from "@core2/data";
// import { QUERY_GET_LIST_PARAMS } from "../providers/data-provider.interface";


export const useQueryGetList = (resource: string, props: QUERY_GET_LIST_PARAMS) => {
    const {
        pagination = {page: 1, perPage: 10},
        sort = {field: 'id', order: 'DESC'},
        filter = {},
        group = '',
        select = '',
        meta,
        doCustomQuery,
        forceClientPaging = false
    } = props
    const dataProvider = useLocalDataProvider()
    const result: any = useQuery({
        queryKey: [resource, 'useQueryGetList', {pagination, sort, filter, select, group, meta}],
        queryFn: doCustomQuery ? doCustomQuery : () => {
            let {page, perPage} = pagination
            const params: any = {
                // cache,
                // subKey: querySubKey,
                pagination: {
                    page,
                    perPage
                },
                // filter: {
                //     sql: {
                //         sort,
                //         where,
                //         select,
                //         group
                //     },
                // },
                filter,
                sort,
                // where,
                select,
                group
            }
            if (pagination) {
                params.pagination = pagination
            }
            if (forceClientPaging) {
                params.pagination = {
                    page: 1,
                    perPage: 500,
                }
            }
            return dataProvider
                .getList(resource, params)
                .then(r => {
                    return r;
                })
        },
        refetchOnWindowFocus: false,
    })
    return useMemo(
        () =>
            result.data
                ? {
                    ...result,
                    data: result.data?.data,
                    total: result.data?.total,
                    pageInfo: result.data?.pageInfo,
                }
                : result,
        [result]
    )
}