

/**
 * Author : Bruce.Decent, the Sunbed Nomad
 * Date: 2021/09/06
 */

import {createContext, useCallback, useContext, useEffect} from "react";

export interface ContentDataType {
    id: string;
    value: string;
    name: string;
    label: string;
}

interface ContentDataTypeContextType {
    dataTypes: ContentDataType[]
}

export const ContentDataTypeContext = createContext<ContentDataTypeContextType>({
    dataTypes: []
})

/**
 * 데이터 타입을 전역적으로 참조할수 있도록 사용하는 context (package 용)
 * @returns
 */
export const useContentDataTypeContext = () => {
    const {dataTypes} = useContext(ContentDataTypeContext)
    const getDataType = useCallback((name: string): ContentDataType => {
        return dataTypes.find(c => c.name === name)
    }, [dataTypes])
    const getDataTypeNameById = useCallback((id: any): string => {
        const f = dataTypes.find(c => c.id === id)
        return f ? f.name : ''
    }, [dataTypes])
    return {
        isLoading: false,
        dataTypes,
        getDataType,
        getDataTypeNameById
    }
}