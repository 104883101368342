import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Line } from 'react-chartjs-2';
import googleTrends from 'google-trends-api';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Chart.js 등록
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface GoogleTrendChartProps {
    keywords: string[];
    timeRange?: string;
    geo?: string;
    property?: 'youtube' | 'news' | '';
    height?: string | number;
}

interface TrendData {
    formattedTime: string;
    value: number[];
}

// 파일 상단에 타입 선언 추가
declare global {
  interface Window {
    trends: {
      embed: {
        renderExploreWidget: (
          type: string,
          options: any,
          config: any
        ) => void;
      };
    };
  }
}

export const GoogleTrendChart: React.FC<GoogleTrendChartProps> = ({
    keywords,
    timeRange = 'today 1-m',
    geo = 'KR',
    property = '',
    height = 400
}) => {
    const [chartData, setChartData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const keywords2 = ['삼성', 'LG']

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                setError(null);

                // 시간 범위 파싱
                const [period, duration] = timeRange.split(' ');
                const endTime = new Date();
                const startTime = new Date();
                
                if (duration.includes('m')) {
                    startTime.setMonth(startTime.getMonth() - parseInt(duration));
                } else if (duration.includes('y')) {
                    startTime.setFullYear(startTime.getFullYear() - parseInt(duration));
                }

                // 각 키워드에 대한 트렌드 데이터 가져오기
                const promises = keywords2.map(keyword =>
                    googleTrends.interestOverTime({
                        keyword,
                        startTime,
                        endTime,
                        geo,
                        property
                    })
                );

                const results = await Promise.all(promises);
                const parsedResults = results.map(result => JSON.parse(result));

                // 데이터 포맷팅
                const timelineData = parsedResults[0].default.timelineData;
                const labels = timelineData.map((item: TrendData) => item.formattedTime);
                
                const datasets = keywords.map((keyword, index) => ({
                    label: keyword,
                    data: parsedResults[index].default.timelineData.map((item: TrendData) => item.value[0]),
                    borderColor: getRandomColor(),
                    backgroundColor: getRandomColor(0.2),
                    tension: 0.4
                }));

                setChartData({
                    labels,
                    datasets
                });
                setIsLoading(false);
            } catch (err) {
                console.error('Error fetching trend data:', err);
                setError('트렌드 데이터를 가져오는 중 오류가 발생했습니다.');
                setIsLoading(false);
            }
        };

        if (keywords.length > 0) {
            fetchData();
        }
    }, [keywords, timeRange, geo, property]);

    const getRandomColor = (alpha = 1) => {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: '브랜드 트렌드 분석',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: '검색 관심도'
                }
            },
            x: {
                title: {
                    display: true,
                    text: '날짜'
                }
            }
        }
    };

    if (error) {
        return (
            <Box sx={{ 
                height, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                color: 'error.main' 
            }}>
                {error}
            </Box>
        );
    }

    if (isLoading) {
        return (
            <Box sx={{ 
                height, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' 
            }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ height }}>
            {chartData && <Line data={chartData} options={options} />}
        </Box>
    );
};



export const GoogleTrendsWidget = ({ brands }) => {
    useEffect(() => {
      // 외부 스크립트 로드 확인
      if (!window.trends) {
        const script = document.createElement('script');
        script.src =
          'https://ssl.gstatic.com/trends_nrtr/3826_RC01/embed_loader.js';
        script.async = true;
        script.onload = () => {
          renderWidget();
        };
        document.body.appendChild(script);
      } else {
        renderWidget();
      }
  
      function renderWidget() {
        if (window.trends && window.trends.embed) {
          const comparisonItem = brands.map((brand) => ({
            keyword: brand,
            geo: 'KR',
            time: 'today 1-m',
          }));
          const exploreQuery = `date=today 1-m&geo=KR&gprop=youtube&q=${brands.join(
            ','
          )}&hl=ko`;
  
          // 위젯이 삽입될 컨테이너 생성
          const widgetDiv = document.createElement('div');
          widgetDiv.id = 'google-trends-widget';
          document.body.appendChild(widgetDiv);
  
          // 위젯 렌더링
          window.trends.embed.renderExploreWidget(
            'TIMESERIES',
            {
              comparisonItem,
              category: 0,
              property: 'youtube',
            },
            {
              exploreQuery,
              guestPath: 'https://trends.google.co.kr:443/trends/embed/',
            }
          );
  
          // 위젯 컨테이너 스타일 조정
          widgetDiv.style.position = 'relative';
          widgetDiv.style.width = '100%';
          widgetDiv.style.height = '500px'; // 필요에 따라 높이 조정
  
          // 원하는 위치에 위젯 표시
          const targetContainer = document.getElementById('widget-container');
          if (targetContainer) {
            targetContainer.appendChild(widgetDiv);
          }
        }
      }
  
      // 클린업: 컴포넌트 언마운트 시 처리
      return () => {
        const widgetDiv = document.getElementById('google-trends-widget');
        if (widgetDiv) {
          widgetDiv.remove();
        }
      };
    }, [brands]);
  
    return (
      <div>
        <h1>Google 트렌드 비교</h1>
        <div id="widget-container"></div>
      </div>
    );
  };