/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */
import {ATTR_TYPE, Content, CONTENT_ATTR, CONTENT_ATTR2, ContentBuildType} from "./content.interface";
import { InquiryPropsBrands } from "./inquiry.interface";

export interface YouTubeThumbnail {
    url: string;
    width: number;
    height: number;
}

export interface YouTubeStatistics {
    likeCount: string;
    viewCount: string;
    commentCount: string;
    favoriteCount: string;
}

export class YouTubeContent extends Content {
    public id: string
    public etag: string
    public kind: string
    public snippet: {
        tags: string[]
        title: string
        channelId: string
        channelTitle: string
        description: string
        publishedAt: string
        thumbnails: {
            default: YouTubeThumbnail
            medium: YouTubeThumbnail
            high: YouTubeThumbnail
            standard?: YouTubeThumbnail
            maxres?: YouTubeThumbnail
        }
        localized?: {
            title: string
            description: string
        }
        categoryId?: string
        defaultAudioLanguage?: string
        liveBroadcastContent?: string
    }
    public statistics: YouTubeStatistics

    constructor(t: ContentBuildType) {
        super(t)
        this.id = ""
        this.etag = ""
        this.kind = ""
        this.snippet = {
            tags: [],
            title: "",
            channelId: "",
            channelTitle: "",
            description: "",
            publishedAt: "",
            thumbnails: {
                default: {url: "", width: 0, height: 0},
                medium: {url: "", width: 0, height: 0},
                high: {url: "", width: 0, height: 0}
            }
        }
        this.statistics = {
            likeCount: "0",
            viewCount: "0",
            commentCount: "0",
            favoriteCount: "0"
        }
    }

    getAttr(attr: CONTENT_ATTR): ATTR_TYPE {
        switch (attr) {
            case CONTENT_ATTR.TOTAL_COUNT:
                return 1;
            case CONTENT_ATTR.TOTAL_LIKE_COUNT:
                return Number(this.statistics.likeCount) || 0;
            case CONTENT_ATTR.TOTAL_VIEW_COUNT:
                return Number(this.statistics.viewCount) || 0;
            case CONTENT_ATTR.TOTAL_REPLAY_COUNT:
                return Number(this.statistics.commentCount) || 0;
            case CONTENT_ATTR.TOTAL_SUBSCRIBERS:
                return 0;
            default:
                return 0;
        }
    }

    isBrand(brand: InquiryPropsBrands): boolean {
        const source = this.getBrandName(brand)
        const tags = this.snippet.tags
        return this.isIncludeTextNoCase(source, [this.title, this.description, ...tags])
    }

    get title(): string {
        return this.snippet.title;
    }

    get description(): string {
        return this.snippet.description;
    }

    get contentUrl(): string {
        return `https://www.youtube.com/watch?v=${this.id}`
    }

    getAttrs(): any {
        return {
            [CONTENT_ATTR2.TITLE]: this.snippet.title,
            [CONTENT_ATTR2.DESCRIPTION]: this.snippet.description,
            [CONTENT_ATTR2.URL]: `https://www.youtube.com/watch?v=${this.id}`
        };
    }

    fromJSON(data: any) {
        this.id = data.id
        this.etag = data.etag
        this.kind = data.kind
        this.snippet = {
            tags: data.snippet.tags || [],
            title: this.removeHtmlTags(data.snippet.title),
            channelId: data.snippet.channelId,
            channelTitle: data.snippet.channelTitle,
            description: this.removeHtmlTags(data.snippet.description),
            publishedAt: data.snippet.publishedAt,
            thumbnails: data.snippet.thumbnails,
            localized: data.snippet.localized,
            categoryId: data.snippet.categoryId,
            defaultAudioLanguage: data.snippet.defaultAudioLanguage,
            liveBroadcastContent: data.snippet.liveBroadcastContent
        }
        this.statistics = data.statistics
    }

    static getSample(): any {
        return {
            "id": "rVczj6shyjo",
            "etag": "R-FQhSinA7KDqyIxSnLB2e3HLnU",
            "kind": "youtube#video",
            "snippet": {
                "tags": [
                    "머드스콘",
                    "머라클",
                    "다이어트",
                    "프로틴"
                ],
                "title": "편의점 단백질 음료는 이 영상으로 끝냅니다 2탄🔥가격&맛&원재료&영양성분 상세리뷰💫",
                "channelId": "UCfmYdGYK5yZkXehmxKsaLRw",
                "channelTitle": "머라클 MURACLE",
                "description": "#다이어트추천템 #diet #프로틴\n\n안녕하세요 동지 님들💚\n드디어 단백질 음료 2탄으로 인사드립니다❤️❤️",
                "publishedAt": "2024-05-22T11:00:44Z",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/rVczj6shyjo/default.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/rVczj6shyjo/mqdefault.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/rVczj6shyjo/hqdefault.jpg",
                        "width": 480,
                        "height": 360
                    }
                },
                "categoryId": "26",
                "defaultAudioLanguage": "ko",
                "liveBroadcastContent": "none"
            },
            "statistics": {
                "likeCount": "349",
                "viewCount": "27348",
                "commentCount": "35",
                "favoriteCount": "0"
            }
        };
    }
}
