
// import {CONTENT_ATTR} from "../../interfaces";
import { NumberField, PercentFieldWithUnit, StyleTable, StyleTableTemplate, TextField } from "@components/custom-core-ui";
import { FunctionField } from "@components/custom-core-ui/function-field";
import { TabGroup, TabItem } from "@components/custom-ui";
import { ListContextProvider } from "@core2/list";
import { useList } from "@core2/list";
import { Stack } from "@mui/material";
import { Content, CONTENT_ATTR } from "@shared/interfaces";
import { TabDataItem } from "./brand-content-container";

// 탭 데이터 정의
const youtubeAttrTabData: TabDataItem[] = [
    { label: '동영상수', value: CONTENT_ATTR.TOTAL_VIDEO_COUNT },
    { label: '조회수', value: CONTENT_ATTR.TOTAL_VIEW_COUNT },
    { label: '좋아요수', value: CONTENT_ATTR.TOTAL_LIKE_COUNT },
    { label: '댓글수', value: CONTENT_ATTR.TOTAL_REPLAY_COUNT },
];



export const YoutubeShareDetailContent = (props: any) => {
    const {contents, attr, brands = [], myBrand} = props
    // const totalArray = contents.getAttrsOfBrands("Youtube_Videos", brands, CONTENT_ATTR.TOTAL_COUNT);
    const totalArray = contents.getAttrsOfBrands("Youtube_Videos", brands, attr);
    const attrLabel = Content.getAttrLabel(attr)
    let totalContent = 0
    const list = brands.reduce((acc: any, brand, idx: number) => {
        totalContent += totalArray[idx]
        acc.push({
            id: idx + 1,
            name: brand.id,
            total: totalArray[idx],
        })
        return acc
    }, [])
    const sorted = list.sort((a, b) => b.total - a.total)
    const leftList = sorted.slice(0, 10).map((item, idx) => ({ ...item, rank: idx + 1 }))
    const rightList = sorted.slice(10, 20).map((item, idx) => ({ ...item, rank: idx + 11 }))
    const listContext1 = useList({ data: leftList })
    const listContext2 = useList({ data: rightList })
    const myBrandIndex1 = leftList.findIndex(item => item.name === myBrand.id)
    const myBrandIndex2 = rightList.findIndex(item => item.name === myBrand.id)

    // const listContext = useList({data: list})
    return <>
    {/* <ListContextProvider value={listContext1}>
        <Stack direction="row" spacing={2}>
        <StyleTable gridStyle={StyleTableTemplate.normalStyle}>
            <TextField source="id" label="순위" />
            <TextField source="name" label="브랜드명" />
            <NumberField source="total" label={attrLabel.label} footer={attrLabel.footer}/>
            <PercentFieldWithUnit source="total" label="점유율" total={totalContent} footer="%" />
        </StyleTable>
        <StyleTable gridStyle={StyleTableTemplate.normalStyle}>
            <TextField source="id" label="순위" />
            <TextField source="name" label="브랜드명" />
            <NumberField source="total" label={attrLabel.label} footer={attrLabel.footer} />
            <PercentFieldWithUnit source="total" label="점유율" total={totalContent} footer="%" />
        </StyleTable>
        </Stack>
    </ListContextProvider> */}

<Stack direction="row" spacing={2}>
    <ListContextProvider value={listContext1}>
        
        <StyleTable gridStyle={StyleTableTemplate.normalStyle} highlightRow={[myBrandIndex1]}>
            <TextField source="rank" label="순위" />
            <TextField source="name" label="브랜드명" />
            <NumberField source="total" label={attrLabel.label} footer={attrLabel.footer}/>
            <PercentFieldWithUnit source="total" label="점유율" total={totalContent} footer="%" />
        </StyleTable>
        </ListContextProvider>
        <ListContextProvider value={listContext2}>
        <StyleTable gridStyle={StyleTableTemplate.normalStyle} highlightRow={[myBrandIndex2]}>
            <TextField source="rank" label="순위" />
            <TextField source="name" label="브랜드명" />
            <NumberField source="total" label={attrLabel.label} footer={attrLabel.footer} />
                <PercentFieldWithUnit source="total" label="점유율" total={totalContent} footer="%" />
            </StyleTable>
        </ListContextProvider>
    </Stack>
    </>
}


export const YoutubeShareDetailContainer = (props: any) => {
    const {contents, channel, brands = [], myBrand} = props
    return <>
        {/* <TabGroup variant="buttons" filter={<SearchFilter/>}> */}
        <TabGroup variant="buttons" >
        {youtubeAttrTabData.map((tab, idx) => {
            // const list = contents.getListByCategory(tab.value)
            // const channel = tab.value
            return <TabItem key={idx} label={tab.label}>
                <YoutubeShareDetailContent contents={contents} attr={tab.value} brands={brands} myBrand={myBrand}/>
            </TabItem>
        })}
    </TabGroup>
    

    </>
}
