export default {
    "translation": {
        "title": "TrendLink",
        "inquiry-viral-analysis": {
            "keyword": "키워드",
            "props": {
                "myBrand": "내 브랜드",
            },
            "analyze": "분석하기"
        },
        "inquiry-blog-analysis": {
            "keyword": "검색어",
            "props": {
                "myBrand": "내 브랜드",
            },
            "analyze": "분석하기"
        },
        "inquiry-meta-ad-analysis": {
            "keyword": "검색어",
            "props": {
            },
        },
        "inquiry-blog-creation": {
            "keyword": "검색어",
            "props": {
                "brand": "브랜드명 또는 상품명",
                "relatedKeywords": "연관 키워드",
                "toneStyle": "톤앤매너",
                "coreContent": "핵심 내용",
                "chars": "글자 수",
                "input": {
                    "keyword": "키워드",
                    "brand": "브랜드명 또는 상품명",
                    "relatedKeywords": "연관 키워드",
                    "toneStyle": "톤앤매너",
                }
            },
            "analyze": "분석하기"
        },
        "inquiry-meta-ad-creation": {
            "keyword": "검색어",
            "props": {
                "brand": "브랜드명 또는 상품명",
                "coreContent": "핵심 내용",
                "chars": "글자 수",
            },
        },
        "member-subscribe": {
            "createdAt": "날짜",
            "gradeId": "멤버십",
            "autoRenewal": "자동갱신",
            "status": "구독 상태",
            "props": {
                "duration": "기간",
                "payment": {
                    "status": "결제 상태",
                    "number": "결제번호",
                    "amount": "결제금액",
                }
            }
        },
        "subscription": {
            "createdAt": "날짜",
            "subId": "구독",
            "activeStatus": "구독 상태",
            "payStatus": "결제 상태",
            "props": {
                "currentGrade": {
                    "id": "멤버십",
                    "displayName": "멤버십",
                },
                "duration": "기간",
                "payment": {
                    "card": {
                        "number": "결제 수단",
                        "numberStr": "결제 수단",
                    },
                    "status": "결제 상태",
                    // "number": "결제번호",
                    "amount": "결제금액",
                }
            }
        },
        "login": {
            "email": "이메일",
            "password": "비밀번호",
            "passwordConfirm": "비밀번호 확인",
            "nickname": "닉네임",
            "keepLogin": "로그인 유지",
            "submit": "로그인",
            "help": "비밀번호를 잊으셨나요?",
        },
        "join": {
            "agreementAll": "모두 동의합니다",
            "agreement": {
                "age": "만 14세 이상입니다",
                "privacy": "개인정보 수집 및 이용",
                "terms": "이용약관",
                "marketing": "마케팅 동의",
            },
            "email": "이메일",
            "password": "비밀번호",
            "passwordConfirm": "비밀번호 확인",
            "name": "이름",
            "submit": "회원가입",
            "phoneAuth": {
                "phone": "휴대폰 번호",
                "auth": "인증번호 발송",
                "authCode": "인증번호",
            }
        },
        "user-profile": {
            "email": "이메일",
            "name": "이름",
            "phone": "연락처",
            "newPhone": "변경할 휴대폰 번호",
            "empty": "  ",
            "password": "비밀번호",
            "currentPassword": "현재 비밀번호",
            "newPassword": "새 비밀번호",
            "newPasswordConfirm": "새 비밀번호 확인",
            "marketing": "마케팅 동의",
        },
        "membership": {
            "free": "무료",
            "basic": "베이직",
            "standard": "스탠다드",
            "premium": "프리미엄",
            "agreeMonthly": "정기 결제되는 것에 동의합니다.",
        },
        "question": {
            "title": "서비스 문의하기",
            "company": "업체명",
            "email": "이메일 주소",
            "name": "담당자명",
            "phone": "연락처",
            "content": "문의 내용",
        },
        "credit-card": {
            "type": "이름",
            "name": "카드사",
            "number": "카드번호",
            "validDate": "카드 유효기간(MM/YY)",
            "passCode": "카드 비밀번호(앞 2자리만)",
            "birthDate": "생년월일(6자리)",
            "businessId": "사업자등록번호(10자리)",
            props: {
                "type": "구분",
                "company": "카드사",
                "number": "카드번호",
                "validDate": "카드 유효기간(MM/YY)",
                "passCode": "카드 비밀번호(앞 2자리만)",
                "birthDate": "생년월일(6자리)",
                "businessId": "사업자등록번호(10자리)",
            }
        },
        "find-user": {
            "email": "아이디",
            "join": "가입일",
        },
        "account-cancel": {
            "reason": "탈퇴 사유",
        },
        "validation": {
            "required": "필수입력입니다.",
            "required-company": "회사명을 입력해 주세요.",
            "required-email": "이메일을 입력해 주세요.",
            "required-name": "이름을 입력해 주세요.",
            "required-phone": "전화번호를 입력해 주세요.",
            "required-content": "필수입력입니다.",
            "requiredText": "필수 입력입니다.",
            "email": "올바른 이메일 형식이 아닙니다.",
            "phone": "올바른 연락처를 작성해 주세요.",
            "agree": "필수 동의 항목 입니다.",
            "url": "올바른 URL을 입력하세요.",
            "uniqueId": "이미 존재하는 아이디입니다.",
            "uniqueNickname": "사용할 수 없는 닉네임입니다.",
            "userId": "4자 이상 25자 이하의 영문 소문자와 숫자 조합이어야 합니다.",
            "password": "영문/숫자 포함 6자 이상으로 사용하세요.",
            "passwordConfirm": "비밀번호가 일치하지 않습니다.",
            "nickname": "2자 이상 25자 이하의 숫자, 밑줄(_), 영문 대소문자 및 한글 조합이어야 합니다.",
            "maxLines": "{{max}}줄 이하의 내용을 입력하세요.",
            "numberMin": "{{min}} 이상의 값을 입력하세요.",
            "numberMax": "{{max}} 이하의 값을 입력하세요.",
            "company": "기업명을 입력해 주세요",
            "manager": "담당자명을 입력해 주세요",
            
        },
        "error": {
            "unknownError": "알 수 없는 에러가 발생했습니다.",
            "invalidParameter": "잘못된 인수가 전달되었습니다.",
            "tokenRevoked": "현재 세션이 종료되었습니다. 다시 로그인해주세요.",
            "notAuthorized": "접속 권한이 없습니다.",
            "noFirebaseUser": "파이어베이스와 연동되지 않았습니다.",
            "invalidToken": "이용정지된 계정입니다.",
            "authCheck": "로그인이 필요합니다.",
            "userNotFound": "없는 사용자입니다.",
            "agencyNotFound": "에이전시가 존재하지 않습니다.",
            "credentialRequired": "이메일과 비밀번호가 필요합니다.",
            "alreadyBlockedUser": "이미 차단된 사용자입니다.",
            "alreadyCertifiedUser": "이미 회원 가입한 계정이 있는 사용자입니다. 가입한 계정으로 로그인 해주세요!",
            "firebase": {
                "auth/email-already-in-use": "이미 사용하고 있는 이메일 아이디입니다.",
                "auth/requires-recent-login": "재 로그인이 필요합니다.",
                "auth/invalid-email": "잘못된 이메일입니다.",
                "auth/weak-password": "더 복잡한 비밀번호를 설정해주세요.",
                "auth/user-not-found": "없는 사용자 입니다.",
                "auth/user-disabled": "탈퇴하였거나 사용중지된 사용자입니다.",
                "auth/wrong-password": "아이디나 비밀번호를 다시 확인해주세요.",
                "auth/popup-blocked": "팝업을 열 수 없습니다.",
                "auth/popup-closed-by-user": "팝업이 닫혔습니다.",
                "auth/too-many-requests": "너무 많은 요청으로 접속이 막혀있습니다. 나중에 다시 시도해주세요."
            }
        },
        "footer": {
            "about": "회사소개",
            "term": "이용약관",
            "privacy": "개인정보처리방침",
            "protection": "청소년보호정책",
            "noEmail": "이메일 주소 무단수집거부",
            "contact": "제휴 안내",
            "customer": "고객센터",
            "legal": "(주)스토어링크는 블로그링크 내 서비스 및 관련 영역의 서비스를 제공하고 있습니다.",
            "companyInfo": "상호 (주)스토어링크 | 대표 정용은 | 주소 서울특별시 중구 중림로 50-1 | 연락처 대표전화 070-4786-2112 | 사업자등록번호 439-85-01354",
            "copyright": "Copyright (C) 2024 bloglink all rights reserved"
        }
    }
}
