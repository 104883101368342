
import { useState } from "react";
import { useCrawlContentContext } from "@shared/components/context";
import { TabGroup, TabItem } from "@components/custom-ui/tab-group";
import { BrandContentDetail, ContentListSection } from "./brand-content-detail-container";
import { SearchTextInput } from "@components/custom-core-ui";
import { FormHandle } from "@core2/form";
import { SimpleForm } from "@core2/form";
import { SecondaryButton } from "@components/custom-ui";
import { Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { Content } from "@shared/interfaces/content.interface";
import { useLocalDataContext } from "@shared/hooks/useLocalDataContext";
import { InquiryRecordData } from "@shared/interfaces";
import { useModalDialogContext } from "@shared/components";






// 탭 데이터 정의
export interface TabDataItem {
    label: string
    value: string | number
}


interface BrandContentPanelProps {
    tabData: TabDataItem[]
    section?: ContentListSection
    hideController?: boolean
}

const SearchFilter = () => {
    return (
        <Stack direction="row" spacing={1} alignItems="center" >
            <SearchTextInput source="search" size="mini" defaultValue={"달바"} />
            <SecondaryButton size="small">검색하기</SecondaryButton>
        </Stack>
        
    )
}

const BrandContentDetaliWithFilter = (props: any) => {
    const { channel, label, section, ...rest } = props
    const formContext = useFormContext()
    const { search } = formContext.watch()
    const totalList = props.list || []
    const list = totalList.filter((item: Content) => item.isInclude(search))
    return <>
        <BrandContentDetail {...rest} list={list} totalList={totalList} channel={channel} section={section} label={label} />
    </>
}



export const BrandContentContainer = (props: BrandContentPanelProps) => {
    const inquiryRecord: InquiryRecordData = useLocalDataContext()
    const { isLoading } = useCrawlContentContext()

    const { tabData, section, hideController } = props

    if (isLoading) return <div>Loading...</div>;

    const { myBrand } = inquiryRecord
    const contents = inquiryRecord.getContentList()

    return (
        <>
            <FormHandle
                resource={"contents"}
            >
                <SimpleForm defaultValues={{ search: myBrand?.id || "" }}>
                    <TabGroup variant="buttons" filter={hideController ? null : <SearchFilter />} paramKey="t3">
                        {tabData.map((tab, idx) => {
                            const list = contents.getListByCategory(tab.value as string)
                            return <TabItem key={idx} label={tab.label} path={tab.value as string}>
                                <BrandContentDetaliWithFilter list={list} myBrand={myBrand} label={tab.label} channel={tab.value} section={section} hideController={hideController} />
                            </TabItem>
                        })}
                    </TabGroup>
                </SimpleForm>
            </FormHandle>
        </>
    );
};
