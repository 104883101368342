import { createTheme } from "@mui/material/styles";

export const COLOR_INDEX = {
    BACKGROUND_COLOR: "#fafafa",
    PRIMARY_COLOR: "#0264FB",
    PRIMARY_COLOR_LIGHT: "#287BFC",
    PRIMARY_COLOR_DARK: "#0264FB",
    RED: "#FB3E02",
    CARD: {
        BACKGROUND: "#F7F7F8",
        BORDER: "#F5F6F8",
    },
}

export const theme = createTheme({
    palette: {
        primary: {
            main: "#0264FB",
        },
        secondary: {
            main: "#8B8C8F",
        },
    },
    typography: {
        fontFamily: "'Pretendard', 'Roboto', 'Helvetica', 'Arial', sans-serif",
        h1: {
            fontSize: 48,
            fontWeight: 700,
        },
        h2: {
            fontSize: 40,
            fontWeight: 700,
        },
        h3: {
            fontSize: 32,
            fontWeight: 500,
        },
        h4: {
            fontSize: 24,
            fontWeight: 500,
        },
        h5: {
            fontSize: 20,
            fontWeight: 500,
        },
        h6: {
            fontSize: 18,
            fontWeight: 500,
        },
        body1: {
            fontSize: 16,
        },
        body2: {
            fontSize: 14,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: (theme) => ({
                '.pagination': {
                    display: 'flex',
                    listStyle: 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    padding: 0,
                    margin: '20px 0',
                },
                '.pagination li': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
                '.pagination li a': {
                    textDecoration: 'none',
                    color: theme.palette.text.primary, // 텍스트 색상을 테마에 맞춤
                    fontSize: theme.typography.body1.fontSize, // MUI 타이포그래피 사용
                    fontWeight: theme.typography.fontWeightMedium, // MUI의 Medium 폰트
                    padding: theme.spacing(1, 1.5), // MUI 간격 시스템 사용
                    borderRadius: theme.shape.borderRadius, // MUI 기본 테두리 반경
                    transition: 'background-color 0.3s, color 0.3s',
                },
                '.pagination li a:hover': {
                    backgroundColor: theme.palette.action.hover, // 테마의 hover 색상 사용
                    color: theme.palette.primary.main, // primary 색상
                },
                '.pagination li.selected a': {
                    backgroundColor: theme.palette.primary.main, // primary 배경색
                    color: theme.palette.primary.contrastText, // contrast 텍스트 색상
                    fontWeight: theme.typography.fontWeightBold, // 굵은 글꼴
                },
                '.pagination li.disabled a': {
                    color: theme.palette.text.disabled, // 비활성 텍스트 색상
                    pointerEvents: 'none',
                    cursor: 'not-allowed',
                },
                '.pagination li.previous a, .pagination li.next a': {
                    fontWeight: theme.typography.fontWeightBold, // 굵은 글꼴
                    color: theme.palette.primary.main, // primary 색상
                },
            }),
        },
    },
});
  