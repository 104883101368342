// RecentDataContext.js

import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { set, get } from 'lodash-es';

/**
 * 1) Context 생성
 */
const RecentDataContext = createContext(null);

/**
 * 2) Provider 컴포넌트
 */
// export const RecentDataProviderOld = ({ children }) => {
//     const [recentData, setRecentData] = useState({});

//     // 마운트 시 localStorage 에서 데이터 복원
//     useEffect(() => {
//         const storedData = localStorage.getItem('recentData');
//         if (storedData) {
//             try {
//                 const parsed = JSON.parse(storedData);
//                 setRecentData(parsed);
//             } catch (error) {
//                 console.error('Failed to parse recentData:', error);
//             }
//         }
//     }, []);

//     /**
//      * 전체 recentData 를 새로운 객체로 바꾼 뒤 localStorage 에 저장
//      */
//     const updateRecentData = (newData) => {
//         setRecentData(newData);
//         localStorage.setItem('recentData', JSON.stringify(newData));
//     };

//     /**
//      * recentData 중 특정 필드만 업데이트
//      * 예: updateRecentDataField('name', 'NewName')
//      */
//     // const updateRecentDataField = (key, value) => {
//     //     setRecentData((prev) => {
//     //         const updated = { ...prev, [key]: value };
//     //         localStorage.setItem('recentData', JSON.stringify(updated));
//     //         return updated;
//     //     });
//     // };

//     const updateRecentDataFieldOld = (key: string, value: any) => {
//         setRecentData((prev) => {
//             // 중첩된 키를 처리하는 헬퍼 함수
//             const setNestedValue = (obj, path, value) => {
//                 const keys = path.split('.'); // 점을 기준으로 키 분리
//                 const lastKey = keys.pop(); // 마지막 키 추출
//                 const nestedObj = keys.reduce((acc, key) => {
//                     if (!acc[key]) acc[key] = {}; // 키가 없으면 객체 생성
//                     return acc[key];
//                 }, obj); // 중첩된 객체 접근
//                 nestedObj[lastKey] = value; // 값 설정
//                 return obj;
//             };
    
//             const updated = { ...recentData }; // 이전 상태 복사
//             set(updated, key, value); // 중첩된 키를 처리하여 값 업데이트
//             localStorage.setItem('recentData', JSON.stringify(updated)); // 로컬 스토리지에 저장
//             return updated; // 상태 업데이트
//         });
//     };

//     const updateRecentDataField = (key: string, value: any) => {
//         const updated = { ...recentData }; // 이전 상태 복사
//         set(updated, key, value); // 중첩된 키를 처리하여 값 업데이트
//         setRecentData(updated);
//         localStorage.setItem('recentData', JSON.stringify(updated)); // 로컬 스토리지에 저장
//     };

//     // Context 로 전달할 value
//     const value = {
//         recentData,
//         updateRecentData,
//         updateRecentDataField,
//     };

//     return (
//         <RecentDataContext.Provider value={value}>
//             {children}
//         </RecentDataContext.Provider>
//     );
// };


export const RecentDataProvider = ({ children }) => {
    const [recentData, setRecentData] = useState({});
    

    // 마운트 시 localStorage 에서 데이터 복원
    useEffect(() => {
        const storedData = localStorage.getItem('recentData');
        if (storedData) {
            try {
                const parsed = JSON.parse(storedData);
                setRecentData(parsed);
            } catch (error) {
                console.error('Failed to parse recentData:', error);
            }
        }
    }, []);

    // const setRecentDataField = useCallback((key: string, value: any) => {
    //     const prevData = JSON.parse(localStorage.getItem('recentData') || "{}");
    //     const updated = { ...prevData }; // 이전 상태 복사
    //     set(updated, key, value); // 중첩된 키를 처리하여 값 업데이트
    //     setRecentData(updated);
    //     localStorage.setItem('recentData', JSON.stringify(updated)); // 로컬 스토리지에 저장
    // }, []);

    function getKey(uid: string, key: string) {
        return `${uid || 'none'}.${key}`
    }

    const setValue = useCallback((uid: string, key: string, value: any) => {
        const prevData = JSON.parse(localStorage.getItem('recentData') || "{}");
        const updated = { ...prevData }; // 이전 상태 복사
        const uidKey = getKey(uid, key)
        // set(updated, key, value); // 중첩된 키를 처리하여 값 업데이트
        set(updated, uidKey, value); // 중첩된 키를 처리하여 값 업데이트
        setRecentData(updated);
        localStorage.setItem('recentData', JSON.stringify(updated)); // 로컬 스토리지에 저장
    }, []);

    const getValue = useCallback((uid: string, key: string) => {
        const uidKey = getKey(uid, key)
        return get(recentData, uidKey)
    }, []);

    // Context 로 전달할 value
    const value = {
        recentData,
        // setRecentDataField,
        setValue,
        getValue,
    };

    return (
        <RecentDataContext.Provider value={value}>
            {children}
        </RecentDataContext.Provider>
    );
};

/**
 * 3) 커스텀 훅
 */
export const useRecentData = () => {
    const context = useContext(RecentDataContext);
    if (!context) {
        throw new Error('useRecentData must be used within a <RecentDataProvider>.');
    }
    return context;
};
