/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */
import dayjs from "dayjs";
import {Content, CONTENT_ATTR, CONTENT_ATTR2, ContentBuildType} from "./content.interface";
import { InquiryPropsBrands } from "./inquiry.interface";

export class NaverKinContent extends Content {
    public link: string           
    private _title: string          
    private _description: string    
    public postdate: string       
    public category: string       

    constructor(t: ContentBuildType) {
        super(t)
        this.link = ""
        this._title = ""
        this._description = ""
        this.postdate = ""
        this.category = ""
    }

    get title(): string {
        return this._title;
    }

    get description(): string {
        return this._description;
    }

    get contentUrl(): string {
        return this.link;
    }

    getAttr(attr: CONTENT_ATTR): any {
        return {
            postdate: this.postdate
        };
    }

    getAttrs(): any {
        return {
            [CONTENT_ATTR2.TITLE]: this._title,
            [CONTENT_ATTR2.DESCRIPTION]: this._description,
            [CONTENT_ATTR2.URL]: this.link,
            [CONTENT_ATTR2.POST_DATE]: this.postdate,
        };
    }

    isBrand(brand: InquiryPropsBrands): boolean {
        const source = this.getBrandName(brand)
        return this.isIncludeTextNoCase(source, [this.title, this.description])
    }   

    fromJSON(data: any) {
        this.link = data.link
        this._title = this.removeHtmlTags(data.title)
        this._description = this.removeHtmlTags(data.description)
        this.postdate = dayjs(data.postdate).format("YYYY-MM-DD")
        this.category = data.category
    }

    static getSample(): any {
        return {
            "link": "https://kin.naver.com/qna/detail.naver?d1id=7&dirId=70403&docId=283100259",
            "title": "18개월 유아영양제 추천 부탁드립니다.",
            "description": "... 그래서 지금 유산균이랑 비타민D랑은 꾸준하게 먹이고 있는데 닥터멀티비*리퀴드키즈 제품이구요. 한포씩 되어있어서 짜서 엄마가 일일이 ml수 맞춰서 먹이는게 아니라서 너무 좋아요.",
            "url": "https://kin.naver.com/qna/detail.naver?d1id=7&dirId=70403&docId=283100259"
        };
    }
}
