
import IconLikeSvg from './like.svg?react';
import IconVideosSvg from './videos.svg?react';
import IconUserGroupSvg from './user-group.svg?react';
import IconEyeSvg from './eye.svg?react';
import IconCoffeeSvg from './coffee.svg?react';
import IconStorageSvg from './storage.svg?react';
import IconKeyboardSvg from './keyboard.svg?react';
import IconFireSvg from './fire.svg?react';
import IconNoteSvg from './note.svg?react';
import IconInformationSvg from './information.svg?react';
import IconCircledCancelSvg from './circled-cancel.svg?react';
import IconBoxResetSvg from './box-reset.svg?react';
import IconRedExclamationSvg from './red-exclamation.svg?react';
import IconViralLinkSvg from './viral-link.svg?react';
import IconGreenCheckSvg from './green-circle-check.svg?react';
import IconCalendarSvg from './calendar.svg?react';
import IconLockerSvg from './locker.svg?react';
import IconRectQuestionSvg from './rect-question.svg?react';

export const IconRectQuestion = (props: any) => {
    // const {  } = props;
    return <IconRectQuestionSvg {...props}/>
}

export const IconLocker = (props: any) => {
    const { size = 20 } = props;
    return <IconLockerSvg />
}


export const IconCalendar = (props: any) => {
    const { size = 20 } = props;
    return <IconCalendarSvg />
}


export const IconLike = (props: any) => {
    const { size = 20 } = props;
    return <IconLikeSvg />
}


export const IconEye = (props: any) => {
    const { size = 20 } = props;
    return <IconEyeSvg />

}

export const IconUserGroup = (props: any) => {
    const { size = 20 } = props;
    return <IconUserGroupSvg />
}



export const IconVideos = (props: any) => {
    const { size = 20 } = props;
    return <IconVideosSvg />
}


export const IconCoffee = (props: any) => {
    const { size = 20 } = props;
    return <IconCoffeeSvg />
}

export const IconStorage = (props: any) => {
    const { size = 20 } = props;
    return <IconStorageSvg />
}

export const IconKeyboard = (props: any) => {
    const { size = 20 } = props;
    return <IconKeyboardSvg />
}

export const IconFire = (props: any) => {
    const { size = 20 } = props;
    return <IconFireSvg />
}

export const IconNote = (props: any) => {
    const { size = 20 } = props;
    return <IconNoteSvg />
}   

export const IconInformation = (props: any) => {
    const { size = 20 } = props;
    return <IconInformationSvg />
}

export const IconCircledCancel = (props: any) => {
    const { size = 20, ...rest} = props;
    return <IconCircledCancelSvg />
}

export const IconBoxReset = (props: any) => {
    const { size = 20, ...rest} = props;
    return <IconBoxResetSvg />
}

export const IconRedExclamation = (props: any) => {
    const { size = 20 } = props;
    return <IconRedExclamationSvg />
}

export const IconGreenCheck = (props: any) => {
    const { size = 20 } = props;
    return <IconGreenCheckSvg />
}


export const IconViralLink = (props) => {
    const { size = 20 } = props;
    return <IconViralLinkSvg />
}