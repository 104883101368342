
import { Box, Typography } from "@mui/material";

interface MultilineTypographyProps {
  text: string; // 전체 텍스트 (문장을 newline으로 구분)
  variant?: string; // Typography variant
  lineHeight?: string | number; // 줄 간격
}

export const MultilineTypography = ({
  text,
  variant,
  lineHeight = "normal",
  ...rest
}) => {
  const lines = (text ?? "").split("\n"); // newline으로 텍스트 분리

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {lines.map((line, index) => (
        <Typography key={index} variant={variant} lineHeight={lineHeight} {...rest}>
          {line}
        </Typography>
      ))}
    </Box>
  );
};