

import { TopBarContainer } from "./top-bar-container";


export const HeaderContainer = props => {
    return (
        // 여기다 Container 를 넣으면 top-bar 가 고정되지 않음
        <TopBarContainer/>
    )
}