import { Link, Stack, Typography } from "@mui/material";
import { IconArrowRight } from "@components/icons";

export const LinkArrow = (props: any) => {
    const { id, url, variant = "h6", size = 10, children } = props;
    return <Link component="a" href={url} underline="none" data-testid={id}>
        <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant={variant} >
                {children}
            </Typography>
            <IconArrowRight size={size} />
        </Stack>
    </Link>
}