import { useContext } from 'react';
import { ResourceContext } from './ResourceContext';


export const useResourceContext = (
    props?
) => {
    // Can't find a way to specify the RecordType when CreateContext is declared
    // @ts-ignore
    const context = useContext<RecordType>(ResourceContext);
    return (props && props.record) || context;
};

