// CustomTabs.tsx

// CustomTabs.tsx

import React, { ReactNode, useEffect } from 'react';
import { Tabs, Tab, Box, Button, ButtonGroup, Stack } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';


/**
 * TabItemProps
 * - label: 탭의 라벨 (선택)
 * - path: 쿼리 파라미터로 사용할 값 (선택)
 * - children: 탭의 실제 콘텐츠
 */
export interface TabItemProps {
    id?: string;
    label?: string;
    path?: string;
    disabled?: boolean;
    children?: ReactNode;
}

/**
 * TabItem
 * - 스스로는 렌더링하지 않고, label, path, children을 보관
 */
export const TabItem: React.FC<TabItemProps> = (props) => {
    const { children } = props;
    return <>{children}</>;
};

/**
 * TabGroupProps
 * - children: 여러 TabItem
 * - initialPath: 기본 활성화 탭의 path (선택, 쿼리 파라미터 't'가 없을 때 사용)
 * - onTabChange: 탭이 변경될 때 호출되는 콜백
 * - variant: 'tabs' | 'buttons' (기본값은 'tabs')
 */
interface TabGroupProps {
    children: ReactNode;
    initialPath?: string;
    onTabChange?: (path?: string) => void;
    variant?: 'tabs' | 'buttons';
    filter?: any
    sx?: any
    size?: "small" | "medium" | "large"
    paramKey?: string
    justifyContent?: "flex-start" | "center" | "flex-end" | "space-between" | "space-around" | "space-evenly"
}

/**
 * TabGroup
 * - 여러 TabItem을 받아
 *   1) variant="tabs": MUI <Tabs>/<Tab> 로 렌더링
 *   2) variant="buttons": MUI <ButtonGroup>/<Button> 으로 렌더링
 * - state(currentTab) 로 현재 탭 인덱스 관리
 * - initialPath 있으면 해당 path 탭을 초기 활성화
 * - 탭 변경 시 onTabChange 콜백에 path 전달
 * - URL의 쿼리 파라미터 't'가 tabItems의 path와 일치하면 해당 탭 활성화
 */
export const TabGroup: React.FC<TabGroupProps> = ({
    children,
    initialPath,
    onTabChange,
    variant = 'tabs',
    filter,
    size = "medium",
    paramKey = "t",
    justifyContent = "space-between",
    ...rest
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // children 을 배열로 변환
    const arrayChildren = React.Children.toArray(children) as React.ReactElement<TabItemProps>[];
    const [currentTab, setCurrentTab] = React.useState<number>(0);

    // 각 TabItem 의 path 목록
    const tabPaths = arrayChildren.map((child) => child.props.path ?? null);

    // 초기 탭 설정: 쿼리 파라미터 't' 또는 initialPath 또는 기본값
    useEffect(() => {
        // 쿼리 파라미터 't' 가져오기
        const queryParam = searchParams.get(paramKey);

        // pathToMatch를 설정
        let pathToMatch = queryParam;

        // 't' 파라미터가 없을 경우 initialPath 사용
        if (!pathToMatch && initialPath) {
            pathToMatch = initialPath;
        }

        // 일치하는 탭 인덱스 찾기
        const index = tabPaths.findIndex((p) => p === pathToMatch);

        if (index >= 0) {
            setCurrentTab(index);
            onTabChange?.(tabPaths[index] ?? undefined);
        } else {
            setCurrentTab(0);
            onTabChange?.(tabPaths[0] ?? undefined);

            // 't' 파라미터가 없거나 유효하지 않으면 초기 탭의 path로 설정
            if (initialPath && !queryParam) {
                setSearchParams({ ...Object.fromEntries(searchParams), t: tabPaths[0] || '' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialPath, searchParams]);

    // 탭(또는 버튼) 변경 시
    const handleTabChange = (newIndex: number) => {
        setCurrentTab(newIndex);
        const path = tabPaths[newIndex] ?? undefined;
        onTabChange?.(path);
        if (path) {
            // 기존의 searchParams를 유지하면서 't' 파라미터만 업데이트
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set(paramKey, path);
            navigate({ pathname: location.pathname, search: newSearchParams.toString() }, { replace: true });
        }
    };

    // variant === 'tabs' 인 경우의 헤더
    const renderTabHeader = () => (
        <Tabs value={currentTab} sx={{ borderBottom: "1px solid #DBDCDF" }} onChange={(e, newValue) => handleTabChange(newValue)}>
            {arrayChildren.map((child, index) => {
                const label = child.props.label ?? `Tab ${index + 1}`;
                return <Tab key={index} label={label} {...rest} />;
            })}
        </Tabs>
    );

    const renderButtonHeader = () => (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent={justifyContent}>
                <Box color="secondary" sx={{ backgroundColor: '#F5F6F8', padding: '5px' }}>
                    {arrayChildren.map((child, index) => {
                        const label = child.props.label ?? `Tab ${index + 1}`;
                        const disabled = child.props.disabled ?? false;
                        const isActive = currentTab === index;

                        const activeStyle = {
                            backgroundColor: "#ffffff",
                            color: "#000000",
                            // border: '1px solid #DBDCDF',
                            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)', // 그림자 추가
                            borderRadius: '5px',
                        }

                        const inactiveStyle = {
                            backgroundColor: "#F5F6F8",
                            color: "#878A93",
                            border: 'none',
                        }

                        return (
                            <Button
                                disabled={disabled}
                                key={index}
                                size={size}
                                onClick={() => handleTabChange(index)}
                                sx={isActive ? activeStyle : inactiveStyle}
                            >
                                {label}
                            </Button>
                        );
                    })}
                </Box>
                {filter}
            </Stack>
        </>
    )

    return (
        <Box>
            {/* 상단 영역: variant 에 따라 Tabs 또는 ButtonGroup */}
            {variant === 'tabs' ? renderTabHeader() : renderButtonHeader()}
            {/* 탭(또는 버튼) 콘텐츠 영역 */}
            <Box sx={{ mt: 2 }}>{arrayChildren[currentTab]}</Box>
        </Box>
    );
};