import { useContext, useMemo } from 'react';

import defaultDataProvider from './defaultDataProvider';
import { LocalDataProviderContext } from './local-data-provider-context';
import { LocalDataProviderType } from './local-data-types';


const arrayReturnTypes = ['getList', 'getMany', 'getManyReference'];

// export const useLocalDataProvider = <
//     TDataProvider extends DataProviderType = DataProviderType
// >(): TDataProvider => {
    export const useLocalDataProvider = <
    TDataProvider extends LocalDataProviderType = LocalDataProviderType
>(): TDataProvider => {
    const dataProvider = ((useContext(LocalDataProviderContext) ||
        defaultDataProvider) as unknown) as TDataProvider;

    // const logoutIfAccessDenied = useLogoutIfAccessDenied();

    const dataProviderProxy = useMemo(() => {
        return new Proxy(dataProvider, {
            get: (target, name) => {
                if (typeof name === 'symbol' || name === 'then') {
                    return;
                }
                return (...args) => {
                    const type = name.toString();

                    if (typeof dataProvider[type] !== 'function') {
                        throw new Error(
                            `Unknown dataProvider function: ${type}`
                        );
                    }

                    try {
                        return dataProvider[type]
                            .apply(dataProvider, args)
                            .then(response => {
                                return response;
                            })
                            .catch(error => {
                                if (process.env.NODE_ENV !== 'production') {
                                    console.error(error);
                                }
                                throw error;
                            });
                    } catch (e) {
                        if (process.env.NODE_ENV !== 'production') {
                            console.error(e);
                        }
                        throw new Error(
                            'The dataProvider threw an error. It should return a rejected Promise instead.'
                        );
                    }
                };
            },
        });
    // }, [dataProvider, logoutIfAccessDenied]);
    }, [dataProvider]);

    return dataProviderProxy;
};
