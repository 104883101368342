
import { PrimaryButton } from "@components/custom-ui/buttons"
import { MultilineTypography } from "@components/custom-ui/typo"
import { Button, Typography } from "@mui/material"
import { DialogActions } from "@mui/material"
import { DialogContent } from "@mui/material"
import { Box, DialogTitle } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { ValidateDialogProps } from "."


interface MembershipOnlyDialogProps extends ValidateDialogProps {
    title: string
    message: string | React.ReactNode
}

export const MembershipOnlyDialog = (props: MembershipOnlyDialogProps) => {
    const {handles, title, message} = props
    const navigate = useNavigate()
    function handleMembershipClick() {
        navigate('/membership')
        handles.handleCloseSuccess()
    }
    return <>
        <Box sx={handles.dialogSx}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
            <Typography variant={"body2"} lineHeight="22px">{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handles.handleCloseError}>닫기</Button>
                <PrimaryButton onClick={handleMembershipClick}>멤버십 알아보기</PrimaryButton>
            </DialogActions>
        </Box>
    </>
}