import {ListContext} from "./ListContext";
import {ListPaginationContext} from "./ListPaginationContext";
import {
    ListFilterContext,
    usePickFilterContext
} from "./ListFilterContextProvider";
import { ResourceContextProvider } from "@core2/resource";


export const ListContextProvider = (props) => {
    const {value, children} = props
    return <ListContext.Provider value={value}>
        {/*<ListFilterContext.Provider value={usePickFilterContext(value)}>*/}
        {/*    <ListSortContext.Provider value={usePickSortContext(value)}>*/}
        {/*        <ListPaginationContext.Provider*/}
        {/*            value={usePickPaginationContext(value)}*/}
        {/*        >*/}
        {children}
        {/*</ListPaginationContext.Provider>*/}
        {/*</ListSortContext.Provider>*/}
        {/*</ListFilterContext.Provider>*/}
    </ListContext.Provider>
}

export const ListContextProvider2 = (props) => {
    const {value, children} = props
    return <ListContext.Provider value={value}>
        <ListFilterContext.Provider value={usePickFilterContext(value)}>
            {/*    <ListSortContext.Provider value={usePickSortContext(value)}>*/}
            {/*<ListPaginationContext.Provider value={usePickPaginationContext(value)}>*/}
            <ListPaginationContext.Provider value={value}>
                <ResourceContextProvider value={value.resource}>
                {children}
                </ResourceContextProvider>
            </ListPaginationContext.Provider>
            {/*</ListSortContext.Provider>*/}
        </ListFilterContext.Provider>
    </ListContext.Provider>
}