import { cloneElement, useMemo, useState } from "react";
import { useListContext, useResourceContext } from "@core2/index";
import { RecordContextProvider } from "@core2/record";
import { useListPaginationContext } from "@core2/index";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StyleTableTemplate } from "./style-table-template";

function translateLabel(t: Function, resource: string, source: string, label: string) {
    if (!label) {
        if (resource) {
            label = t(`${resource}.${source}`);
        } else {
            label = source;
        }
    }
    return label
}

export const StyleTable = (props) => {
    const {
        children,
        disabled,
        tailRow,
        query = { page: 1, size: 10 },
        click,
        headless = false,
        hiddenHead = false,
        resource,
        emptyMessage = "항목이 없습니다.",
        gridStyle = StyleTableTemplate.normalStyle, // gridStyle prop for custom styling, including row and column styles
        highlightRow = null,
    } = props;

    const listContext = useListContext();
    const { t } = useTranslation();
    const fixResource = resource ? resource : useResourceContext()
    const navigate = useNavigate();
    const paginationContext = useListPaginationContext(props);
    const { data, isLoading } = listContext;
    const records = data || [];

    const [hoveredRow, setHoveredRow] = useState(null); // 호버 중인 행의 인덱스

    const resultRecord = useMemo(
        () => (tailRow ? tailRow(records) : null),
        [records, tailRow]
    );

    const childrenArray = Array.isArray(children) ? children : [children];

    const header = useMemo(
        () =>
            childrenArray.map((child, index) => {
                let { label, source, align, width } = child.props || {};
                // label = label !== undefined ? label : source
                return {
                    label: translateLabel(t, fixResource, source, label),
                    source,
                    style: {
                        position: 'relative', // 세로선 절대 위치를 위해 relative 설정
                        textAlign: align || "left",
                        // width: width ? `${width}px` : "auto",
                        width: width || "auto",
                        ...gridStyle.headerCell,
                    },
                    child,
                    isLast: index === childrenArray.length - 1, // 마지막 컬럼 여부
                };
            }),
        [childrenArray, gridStyle]
    );

    const handleClick = (record) => {
        if (click === "show" && listContext.resource) {
            navigate(`/${listContext.resource}/${record.id}/show`);
        } else if (typeof click === "function") {
            click(record);
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (records.length === 0) {
        return emptyMessage ? emptyMessage : <div>항목이 없습니다.</div>
    }

    // columnDivider 객체에서 각 속성을 추출하고 기본값을 설정
    const {
        topGap = '10%',
        bottomGap = '10%',
        width: dividerWidth = '1px',
        color: dividerColor = '#e0e0e0',
    } = gridStyle.columnDivider || {};

    return (
        <table
            style={{
                width: "100%",
                borderCollapse: "collapse", // 테이블 컬럼 경계선 처리
                ...gridStyle.table,
            }}
        >
            {!headless && (
                <thead style={{ ...gridStyle.header, ...(hiddenHead ? { visibility: 'hidden' } : {}) }}>
                    <tr>
                        {header.map((t, index) => (
                            <th key={index} style={t.style}>
                                {t.label}
                                {/* 마지막 컬럼이 아니면 세로선 추가 */}
                                {!t.isLast && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: topGap, // 세로선 위쪽 공백
                                            bottom: bottomGap, // 세로선 아래쪽 공백
                                            right: 0,
                                            width: dividerWidth, // 세로선 두께
                                            backgroundColor: dividerColor, // 세로선 색상
                                        }}
                                    />
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
            )}
            <tbody>
                {records.map((record: any, rowIndex: number) => {
                    const color = highlightRow && highlightRow.indexOf(rowIndex) >= 0 ? gridStyle.highlightRow.color : undefined
                    return <tr
                        key={rowIndex}
                        style={{
                            backgroundColor:
                                hoveredRow === rowIndex
                                    ? gridStyle.rowHoverBackgroundColor || "#f0f0f0" // 호버 중인 행의 배경색
                                    : rowIndex % 2 === 0
                                        ? gridStyle.rowEven || "#fafafa" // 짝수 행 색상
                                        : gridStyle.rowOdd || "#ffffff", // 홀수 행 색상
                            borderBottom: gridStyle.rowBorderBottom || "1px solid #e0e0e0", // 행 사이 가로선
                            transition: gridStyle.rowTransition || "background-color 0.3s ease", // 호버 효과 부드럽게
                            color,
                            ...gridStyle.row,
                        }}
                        data-index={rowIndex}
                        onClick={() => handleClick(record)}
                        onMouseEnter={() => setHoveredRow(rowIndex)} // 마우스가 행 위로 올라갔을 때
                        onMouseLeave={() => setHoveredRow(null)} // 마우스가 행을 벗어났을 때
                    >
                        {header.map((item, index) => {
                            const { child, source, isLast, style, ...rest } = item;
                            return (
                                <RecordContextProvider key={index} value={record}>
                                    <td
                                        style={{
                                            position: 'relative', // 세로선 절대 위치를 위해 relative 설정
                                            padding: "10px",
                                            ...gridStyle.cell,
                                        }}
                                    >
                                        {cloneElement(child, {
                                            record,
                                            source,
                                            recordIndex: rowIndex + (query.page - 1) * query.size,
                                            recordIndex2:
                                                rowIndex +
                                                (paginationContext.page - 1) * paginationContext.perPage,
                                            disabled,
                                            style: {
                                                ...style,
                                                color,
                                            },
                                            ...rest,
                                        })}
                                        {/* 마지막 컬럼이 아니면 세로선 추가 */}
                                        {!item.isLast && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: topGap, // 세로선 위쪽 공백
                                                    bottom: bottomGap, // 세로선 아래쪽 공백
                                                    right: 0,
                                                    width: dividerWidth, // 세로선 두께
                                                    backgroundColor: dividerColor, // 세로선 색상
                                                }}
                                            />
                                        )}
                                    </td>
                                </RecordContextProvider>
                            );
                        })}
                    </tr>
                })}
                {resultRecord && (
                    <tr
                        style={{
                            backgroundColor: gridStyle.resultRowBackground || "#e0e0e0",
                            borderBottom: gridStyle.rowBorderBottom || "1px solid #e0e0e0",
                            ...gridStyle.resultRow,
                        }}
                        data-index={-1}
                    >
                        {resultRecord.map((item, index) => {
                            const headerItem = header[index];
                            return (
                                <td
                                    key={index}
                                    style={{
                                        fontWeight: "bold",
                                        ...gridStyle.resultCell,
                                    }}
                                >
                                    <RecordContextProvider value={item}>{item}</RecordContextProvider>
                                    {/* 마지막 컬럼이 아니면 세로선 추가 */}
                                    {!headerItem.isLast && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: topGap, // 세로선 위쪽 공백
                                                bottom: bottomGap, // 세로선 아래쪽 공백
                                                right: 0,
                                                width: dividerWidth, // 세로선 두께
                                                backgroundColor: dividerColor, // 세로선 색상
                                            }}
                                        />
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                )}
            </tbody>
        </table>
    );
};