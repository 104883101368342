import { DialogHandles } from "@components/Alert"
import { PrimaryButton } from "@components/custom-ui/buttons"
import { MultilineTypography } from "@components/custom-ui/typo"
import { Button } from "@mui/material"
import { DialogActions } from "@mui/material"
import { DialogContent } from "@mui/material"
import { Box, DialogTitle } from "@mui/material"
import { replace, useLocation, useNavigate } from "react-router-dom"
import { ValidateDialogProps } from "."
import { useModalDialogContext } from "@components/dialog"



export const NeedLoginDialog = (props: ValidateDialogProps) => {
    const {handles} = props
    const navigate = useNavigate()
    const location = useLocation(); // 현재 경로 정보
    function handleLoginClick() {
        navigate('/login', {state: {from: location}, replace: true})
        if (handles.handleCloseSuccess) {
            handles.handleCloseSuccess()
        }
    }
    function handleRegisterClick() {
        navigate('/register', {state: {from: location}, replace: true})
        if (handles.handleCloseSuccess) {
            handles.handleCloseSuccess()
        }
    }
    function handleClose() {
        if (handles.handleCloseError) {
            handles.handleCloseError()
        }
    }
    return <>
        <Box sx={handles.dialogSx}>
            <DialogTitle>로그인이 필요한 서비스</DialogTitle>
            <DialogContent>
            <MultilineTypography text={
                        `로그인하고 내 브랜드 트렌드 현황을 분석해보세요!
                        회원이 아니라면?`
                    } variant={"body2"} lineHeight="22px"/>
                    <PrimaryButton size="small" onClick={handleRegisterClick} sx={{mt: '10px'}}>회원가입 바로가기</PrimaryButton>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>닫기</Button>
                <PrimaryButton onClick={() => handleLoginClick()}>로그인</PrimaryButton>
            </DialogActions>
        </Box>
    </>
}