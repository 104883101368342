import React from 'react';
import { useRecordContext } from '@core2/record';
import { get } from "lodash-es";


export const CoreRichTextField = (props: any) => {
    const { source, emptyText = "", record: propsRecord} = props;

    // record를 useRecordContext에서 가져오거나 props로 전달된 record를 사용
    let record = useRecordContext();
    record = propsRecord || record;

    // source 경로의 값을 가져오고, 비어 있으면 emptyText 사용
    let text = get(record, source, emptyText) || emptyText

    return <div dangerouslySetInnerHTML={{ __html: text }} />;

};