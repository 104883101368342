

/**
 * Author : Bruce.Decent, the Sunbed Nomad
 * Date: 2021/09/06
 */


import { ContentDataType } from "@shared/providers/content-datatype-context";
import { useQuery } from "@tanstack/react-query";
import {createContext, useCallback, useContext, useEffect, useMemo} from "react";
import { simpleApiQueryWithoutAuth } from "./simpleApiQuery";
import { pascalCase } from "change-case";


interface AppDataContextType {
}

export const AppDataContext = createContext<AppDataContextType>({})
export const AppDataDispatchContext = createContext<AppDataContextType>({})


export interface AppContentDataType extends ContentDataType  {
}

export const useAppDataDispatchContext = () => {
    const dispatch = useContext(AppDataDispatchContext)

    return dispatch
}


const useAppDataQuery = (resource: string, params: any = {}) => {
    
    const {error, isLoading, data} = useQuery({
        queryKey: ['appData', resource],
        queryFn: async () => {
            const resourceParam = pascalCase(resource)
            const res = await simpleApiQueryWithoutAuth('get', `user-query/${resourceParam}`, params)
            return res.json.data
        },
    })
    return {error, isLoading, data}
}

export const useAppDataContext = () => {
    const typeContext = useAppDataQuery('data-type')
    const featureContext = useAppDataQuery('feature')
    const membershipGradeContext = useAppDataQuery('member-grade')
    // const sampleInquiryContext = useAppDataQuery('sample-inquiry')
    
    const isLoading = typeContext.isLoading || featureContext.isLoading || membershipGradeContext.isLoading
    const typesData = typeContext?.data?.list || []
    const featuresData = featureContext?.data?.list || []
    const membershipGradesData = membershipGradeContext?.data?.list || []
    // const sampleInquiries = sampleInquiryContext?.data?.list || []

    const dataTypes: AppContentDataType[] = typesData.map(c => ({
        id: c.id,
        value: c.id,
        name: c.name,
        label: c.label,
    }))
    const features: AppContentDataType[] = featuresData.map(c => ({
        id: c.id,
        name: c.name,
        nameId: c.nameId,
    }))
    const membershipGrades: AppContentDataType[] = membershipGradesData.map(c => ({
        id: c.id,
        name: c.name,
        props: c.props,
    }))
    
    const getMembershipGrade = useCallback((name: string): any => {
        return membershipGrades.find(c => c.name === name)
    }, [membershipGrades])
    const getDataType = useCallback((name: string): AppContentDataType => {
        return dataTypes.find(c => c.name === name)
    }, [dataTypes])
    const getDataTypeNameById = useCallback((id: any): string => {
        const f = dataTypes.find(c => c.id === id)
        return f ? f.name : ''
    }, [dataTypes])
    const getFeatureId = useCallback((name: string): any => {
        return features.find((c: any) => c.nameId === name)?.id
    }, [features])    


    // const getSampleInquiry = useCallback((type: string): any => {
    //     const entity = getDataType(type)
    //     return sampleInquiries.find((c: any) => c.dataTypeId === entity.id)
    // }, [sampleInquiries])    

    return {
        isLoading,
        dataTypes,
        getDataType,
        getDataTypeNameById,
        features,
        getFeatureId,
        getMembershipGrade,
        // getSampleInquiry
    }   
}