/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 13.
 */



import { axiosFetchJson, FetchResult } from "./axiosFetch";
import { AuthProvider } from "./frontAuthProvider";

export const serverApiUrl = import.meta.env.VITE_APP_SERVER_URI;

export async function simpleApiQuery(method: string, path: string, params: any): Promise<FetchResult> {
    // 유저 정보를 캐시에서 가져온다.(캐시 무효시 자동 갱신됨)
    const identity: any = await AuthProvider.getIdentity2()
    const url = `${serverApiUrl}/${path}`
    const config: any = {}
    if (identity) {
        config['headers'] = { Authorization: `Bearer ${identity.token}` }
    }

    const response = await axiosFetchJson(url, { method, body: params, headers: config.headers })
    return {
        status: response.status,
        headers: response.headers as any,
        json: {
            data: response.json.data,
            error: response.json.error,
        }
    }
}


export async function simpleApiQueryWithoutAuth(method: string, path: string, params: any, token: string = null): Promise<FetchResult> {
    const url = `${serverApiUrl}/${path}`
    const config: any = {
        headers: {}
    }
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    const response = await axiosFetchJson(url, { method, body: params, headers: config.headers })
    return {
        status: response.status,
        headers: response.headers as any,
        json: {
            data: response.json.data,
            error: response.json.error,
        }
    }
}

