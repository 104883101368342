export enum SUBSCRIPTION_STATUS {
	active = 'active',
	deactivate = 'deactivate',
	willActivate = 'willActivate',
	willDeactivate = 'willDeactivate',
	willIgnore = 'willIgnore',
	failBilling = 'failBilling',
}
export enum SUBSCRIPTION_PAYMENT_STATUS {
	paid = 'paid',
	failed = 'failed',
	refunded = 'refunded',
	pending = 'pending',
}


export interface BillingTx {
    paymentId: string              // paymentId
    pgTxId: string              // transactionId
    paidAt: string          // 결제일
}

export interface PaymentMethod {
    method: string
    amount: number
    currency: string
    billingTx: BillingTx,
    card: {
        id: number|string
        numberStr: string
        company: string
    }
}


export interface Subs {
    id: number
    name: string
    displayName: string
    payStatus: SUBSCRIPTION_PAYMENT_STATUS
    activeStatus: SUBSCRIPTION_STATUS
    // nextPaymentDate: Date | null
}

export interface NextSubs {
    id: number
    name: string
    displayName: string
    pendingStatus: SUBSCRIPTION_STATUS
    // nextPaymentDate: Date | null
    paymentTriedAt: Date | null
}


export interface SubscriptionPeriodManifest {
    duration: number
    start: Date
    end: Date | null
    nextPaymentDate: Date | null
}

export interface SubscriptionEntityProps {
    manifest: SubscriptionPeriodManifest
    currentGrade: Subs
    nextGrade: NextSubs
    payment: PaymentMethod
    refund: {
        amount: number
        reason: string
        date: Date
    }
    cancel: {
        requestedAt: Date|null
        canceledAt: Date|null
    }
    // canceledAt: Date
}

export class Membership {
    id: number
    activeStatus: SUBSCRIPTION_STATUS
    props: SubscriptionEntityProps
    constructor(record: any) {
        Object.assign(this, record)
    }
    isValid() {
        // 멤버십이 없는 경우
        return !!this.id
    }
    currentGrade(defaultGrade: string = "FREE") {
        return this.props?.currentGrade?.name || defaultGrade
    }
    currentDisplayName() {
        return this.props?.currentGrade?.displayName || "프리"
    }
    nextDisplayName() {
        return this.props?.nextGrade?.displayName || "프리"
    }
    getProps(): SubscriptionEntityProps {
        return this.props || {} as SubscriptionEntityProps
    }
    getNextBillingDate() {
        return this.props?.manifest?.nextPaymentDate
    }
}

export class Feature {
    constructor(record: any) {
        Object.assign(this, record)
    }
}


export class MembershipStatus {
    membership: Membership
    features: Feature[]
    constructor(record: any) {
        this.membership = new Membership(record.membership)
        this.features = record.features.map((feature: any) => new Feature(feature))
    }
}