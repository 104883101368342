/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2023/05/13
 */
import {createContext} from "react";


export const ListContext = createContext({
    sort: null,
    data: null,
    defaultTitle: null,
    displayedFilters: null,
    exporter: null,
    filterValues: null,
    hasNextPage: null,
    hasPreviousPage: null,
    hideFilter: null,
    isFetching: null,
    isLoading: null,
    onSelect: null,
    onToggleItem: null,
    onUnselectItems: null,
    page: null,
    perPage: null,
    refetch: null,
    resource: null,
    selectedIds: undefined,
    setFilters: null,
    setPage: null,
    setPerPage: null,
    setSort: null,
    showFilter: null,
    total: null,
});

ListContext.displayName = 'ListContext';