/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */
import { ContentList } from "../interfaces/content-list.interface";
import { useContentDataTypeContext } from "../providers";
import { useQueryGetList } from "./useQueryGetList";
import { useMemo } from "react";




export const useGetContentList = (props) => {
    const { table, keyword, inquiryId } = props
    const tables = Array.isArray(table) ? table : [table]
    const { getDataTypeNameById } = useContentDataTypeContext();

    const promises = tables.map(tableName =>
        useQueryGetList(tableName, {
            filter: {
                // uid: isSample ? '@sample' : undefined,
                queryId: inquiryId,
                queryKey: keyword
            },
            pagination: { page: 1, perPage: 100 },
            // sort: {field: 'id', order: 'DESC'},
        })
    )

    const { data: combinedData, ...rest } = promises[0] // 첫번째 promise의 나머지 값들 사용

    const result = useMemo(() => {
        const list = new ContentList()
        if (!combinedData) return list

        // 모든 테이블의 데이터를 하나의 리스트로 병합
        promises.forEach(promise => {
            if (promise.data) {
                list.build(promise.data, getDataTypeNameById)
            }
        })
        return list
    }, [combinedData, promises])

    return {
        ...rest,
        data: result
    }
}
