/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */
import {Content, CONTENT_ATTR, CONTENT_ATTR2, ContentBuildType} from "./content.interface";
import { InquiryPropsBrands } from "./inquiry.interface";

const NewsChannelFromUrl = {
    "mail": "매일경제",
    "hankyung": "한국경제",
    "chosun": "조선일보",
    "donga": "동아일보",
}

export class NaverNewsContent extends Content {

    public link: string           
    private _title: string          
    private _description: string    
    public pubDate: string        
    public originallink: string   
    public source: string         

    constructor(t: ContentBuildType) {
        super(t)
        this.link = ""
        this._title = ""
        this._description = ""
        this.pubDate = ""
        this.originallink = ""
        this.source = ""
    }

    get title(): string {
        return this._title;
    }

    get description(): string {
        return this._description;
    }

    get contentUrl(): string {
        return this.link;
    }

    getAttr(attr: CONTENT_ATTR): any {
        return {
            pubDate: this.pubDate,
            originallink: this.originallink
        };
    }

    getAttrs(): any {
        return {
            [CONTENT_ATTR2.TITLE]: this._title,
            [CONTENT_ATTR2.DESCRIPTION]: this._description,
            [CONTENT_ATTR2.URL]: this.link,
            [CONTENT_ATTR2.POST_DATE]: this.pubDate,
        };
    }

    isBrand(brand: InquiryPropsBrands): boolean {
        const source = this.getBrandName(brand)
        return this.isIncludeTextNoCase(source, [this.title, this.description])
    }

    findChannelFromUrl(url: string): string {
        const match = url.match(/news\.naver\.com\/main\/read\.naver\?.*sid1=100&.*sid2=(\w+)/)
        return match ? NewsChannelFromUrl[match[1]] : ""
    }

    fromJSON(data: any) {
        this.link = data.link
        this._title = this.removeHtmlTags(data.title)
        this._description = this.removeHtmlTags(data.description)
        this.pubDate = data.pubDate
        this.originallink = data.originallink
        // this.source = data.source ? data.source : this.findChannelFromUrl(data.url)
        this.source = data.source || "(알수없음)"
    }

    static getSample(): any {
        return {
            "link": "https://n.news.naver.com/article/123/1234567",
            "title": "얼티브, 신제품 출시",
            "description": "건강기능식품 브랜드 얼티브가 신제품을 출시했다...",
            "pubDate": "20240426",
            "originallink": "https://original-news.com/article/123",
            "source": "매일경제",
            "url": "https://news.naver.com/article/123/1234567"
        };
    }
}
