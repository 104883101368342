/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 13.
 */
import { initializeApp } from 'firebase/app';

async function getFirebaseConfig() {
    console.log('getFirebaseConfig');
    const app_env = import.meta.env.VITE_APP_ENV

    let firebaseConfig;
    switch (app_env) {
        case 'production':
            console.log('firebase=production');
            firebaseConfig = await import('./firebaseConfig.production.json');
            break
        case 'development':
            console.log('firebase=development');
            firebaseConfig = await import('./firebaseConfig.dev.json');
            break
        default:
            console.log('firebase=local');
            firebaseConfig = await import('./firebaseConfig.local.json');
            break
    }
    return firebaseConfig.default;
}

const firebaseConfig: Record<string, any> = await getFirebaseConfig();
export const firebaseApp = initializeApp(firebaseConfig);
