

import { Box, Typography } from "@mui/material";
import { ListContextProvider2, useGetIdentity2, useList, useListContext, useRecordContext } from "@core2/index";
import { DateField, RichTextField, StyleTable, StyleTableTemplate } from "@components/custom-core-ui";
import { NumberField, TextField } from "@components/custom-core-ui";
import { StylePagination } from "@components/custom-core-ui";
import { InquiryPropsBrands, XlsResourceSheet } from "@shared/interfaces";
import { get } from "lodash-es";
import { SecondaryButton } from "@components/custom-ui";
import dayjs from "dayjs";
import { MembershipOnlyDialog, NeedLoginDialog } from "@containers/dialogs";
import { useModalDialogContext } from "@components/dialog";
import { useMembershipStatus } from "@hooks/useMembership";


function getComponentResult(component: any, record: any) {
    const componentName = component.type.name
    const props = component.props
    let value = {}
    switch (componentName) {
        case "RichTextField": 
        case "TextField": {
            const label = props.label || props.source
            value[label] = get(record, props.source)
            break;
        }
        case "NumberField": {
            const label = props.label || props.source
            value[label] = Number(get(record, props.source)) || 0
            break;
        }
        case "DateField": {
            const label = props.label || props.source
            value[label] = dayjs(get(record, props.source)).format("YYYY-MM-DD")
            break;
        }
        case "PostField": {
            const label1 = props.source1
            value[label1] = get(record, props.source1)
            const label2 = props.source2
            value[label2] = get(record, props.source2)
            break
        }
        case "YoutuberField": {
            const label = props.label || props.name
            value[label] = get(record, props.name)
            break
        }
        default: {
            console.error("Unknown component: ", componentName)
            value[props.label] = get(record, props.source)
            break;
        }
    }
    return value
}


const ContentDownloadButton = (props: any) => {
    const { data, columns, label } = props
    const {data: identity, isLoading: identityLoading} = useGetIdentity2()
    const { membership, isLoading: membershipLoading } = useMembershipStatus()
    const { openModal, closeModal } = useModalDialogContext();
    function handleClose() {
        closeModal()
    }
    function handleDownload() {
        // const grade = get(membership, "props.currentGrade.name") || "FREE"
        const grade = membership.currentGrade()
        if (!identity) {
            
            openModal({
                // title: "회원 목록 다운로드",
                content: <NeedLoginDialog handles={{handleCloseError: handleClose, handleCloseSuccess: handleClose}} />
            })
            return
        }
        if (grade === "FREE") {
            openModal({
                content: <MembershipOnlyDialog title="유료 멤버십 필요" message="멤버십 회원만 이용 가능합니다." handles={{handleCloseError: handleClose, handleCloseSuccess: handleClose}} />
            })
            return
        }
        const cnvData = []
        for (const record of data) {
            const columnValue = columns.reduce((acc, column) => {
                const res = getComponentResult(column, record)
                acc = {
                    ...acc,
                    ...res
                }
                return acc
            }, {})
            cnvData.push(columnValue)
        }
        const sheet = new XlsResourceSheet();        
        sheet.create(label);
        sheet.setTable(cnvData)
        sheet.writeFile(label)
    }
    
    return <SecondaryButton onClick={handleDownload} size="small" disabled={identityLoading} >데이터 다운로드</SecondaryButton>
}



const PostField = (props: any) => {
    const { source1, source2 } = props
    const record = useRecordContext()
    const title = get(record, source1)
    const content = get(record, source2)
    return <>
        <Box dangerouslySetInnerHTML={{ __html: title }} />
        <Box sx={{ mt: 0.2, color: "gray", fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: content }} />
    </>
}

const YoutuberField = (props: any) => {
    const { name, subscriberCount } = props
    const record = useRecordContext()
    const nameText = get(record, name)
    const subscriberCountText = get(record, subscriberCount) || "unknown"
    // return <Typography>{nameText} / {subscriberCountText}</Typography>
    return <Typography>{nameText}</Typography>
}

export type ContentListSection = "naver" | "youtube" | "content"



export interface BrandContentDetailProps {
    list: any
    totalList: any
    // selectedContent: string
    myBrand: InquiryPropsBrands
    channel: string
    section?: ContentListSection
    label?: string
    hideController?: boolean
}

/**
 * 콘텐츠 목록을 지정 카테고리에 맞추어 테이블로 출력한다
 * @param props 
 * @returns 
 */
export const BrandContentDetail = (props: BrandContentDetailProps) => {
    const { list, myBrand, totalList, channel, section = "naver", label: tabLabel, hideController } = props;
    const listContext = useList({
        data: list,
        perPage: 10,
        sort: { field: 'rank', order: 'ASC' },
        page: 1
    });

    function handleRowClick(record: any): any {
        const url = record.contentUrl;
        if (url) {
            window.open(url, '_blank');
        }
    };

    const template = `${section}_${channel}`
    const getColumns = () => {
        switch (template) {
            case 'naver_Naver_Blog':
                return (
                    <>
                        <NumberField source="index" label="순위" width="50px" />
                        <TextField source="bloggername" label="작성자" />
                        <PostField source1="title" source2="description" label="게시물" />
                        <DateField source="postdate" label="발행일" width="100px" fromNow={true} />
                    </>
                );
            case 'content_Naver_Blog':
                return (
                    <>
                        <NumberField source="index" label="순위" width="50px" />
                        <TextField source="bloggername" label="작성자" />
                        <PostField source1="title" source2="description" label="게시물" />
                        <TextField source="inquiryBrand.id" label="브랜드" width="100px" />
                        <DateField source="postdate" label="발행일" width="100px" fromNow={true} />
                    </>
                );
            case 'naver_Naver_Cafe':
                return (
                    <>
                        <NumberField source="index" label="순위" width="50px" />
                        <TextField source="cafename" label="카페명" />
                        <PostField source1="title" source2="description" label="게시물" />
                    </>
                );
            case 'content_Naver_Cafe':
                return (
                    <>
                        <NumberField source="index" label="순위" width="50px" />
                        <TextField source="cafename" label="카페명" />
                        <PostField source1="title" source2="description" label="게시물" />
                        <TextField source="inquiryBrand.id" label="브랜드" width="100px" />
                    </>
                );
            case 'naver_Naver_Kin':
                return (
                    <>
                        <NumberField source="index" label="순위" width="50px" />
                        <RichTextField source="title" label="제목" />
                        <RichTextField source="description" label="내용" />
                    </>
                );
            case 'content_Naver_Kin':
                return (
                    <>
                        <NumberField source="index" label="순위" width="50px" />
                        <RichTextField source="title" label="제목" />
                        <RichTextField source="description" label="내용" />
                        <TextField source="inquiryBrand.id" label="브랜드" width="100px" />
                    </>
                );
            case 'naver_Naver_News':
                return (
                    <>
                        <NumberField source="index" label="순위" width="50px" />
                        <RichTextField source="title" label="제목" />
                        <RichTextField source="description" label="내용" />
                        {/* <TextField source="inquiryBrand.id" label="브랜드" width="100px" /> */}
                        <DateField source="pubDate" label="발행일" fromNow={true} width="100px" />
                    </>
                );
            case 'content_Naver_News':
                    return (
                        <>
                            <NumberField source="index" label="순위" width="50px" />
                            <RichTextField source="title" label="제목" />
                            <RichTextField source="description" label="내용" />
                            <TextField source="inquiryBrand.id" label="브랜드" width="100px" />
                            <DateField source="pubDate" label="발행일" fromNow={true} width="100px" />
                        </>
                    );
            case 'youtube_Youtube_Videos':
                return (
                    <>
                        <NumberField source="index" label="순위" />
                        <YoutuberField name="snippet.channelTitle" subscriberCount="statistics.subscriberCount" label="유튜버" />
                        {/* <RichTextField source="statistics.subscriberCount" label="구독자수" /> */}
                        <RichTextField source="title" label="내용" />
                        {/* <PostField source1="title" source2="description" label="내용" /> */}
                        <NumberField source="statistics.viewCount" label="조회수" humanize footer="회" width="90px" />
                        <NumberField source="statistics.likeCount" label="좋아요수" humanize footer="개" width="90px" />
                        <NumberField source="statistics.commentCount" label="댓글수" humanize footer="개" width="90px" />
                        {/* <TextField source="inquiryBrand.id" label="브랜드" width="100px"/> */}
                    </>
                );
            case 'content_Youtube_Videos':
                return (
                    <>
                        <NumberField source="index" label="순위" />
                        <YoutuberField name="snippet.channelTitle" subscriberCount="statistics.subscriberCount" label="유튜버" />
                        {/* <RichTextField source="statistics.subscriberCount" label="구독자수" /> */}
                        <RichTextField source="title" label="내용" />
                        {/* <PostField source1="title" source2="description" label="내용" /> */}
                        <NumberField source="statistics.viewCount" label="조회수" humanize footer="회" width="90px" />
                        <NumberField source="statistics.likeCount" label="좋아요수" humanize footer="개" width="90px" />
                        <NumberField source="statistics.commentCount" label="댓글수" humanize footer="개" width="90px" />
                        <TextField source="inquiryBrand.id" label="브랜드" width="100px"/>
                    </>
                );
            default:
                return (
                    <>
                        <NumberField source="index" label="순위" />
                        <TextField source="title" label="게시물" />
                    </>
                );
        }
    };

    return <>

        <ListContextProvider2 value={listContext}>
            {!hideController && <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <ContentDownloadButton data={totalList} columns={getColumns().props.children} label={tabLabel}/>
            </Box>}
            <StyleTable
                data={listContext.data}
                click={handleRowClick}
                bulkActionButtons={false}
            >
                {getColumns().props.children}
            </StyleTable>
            <StylePagination />
        </ListContextProvider2>
    </>
};
