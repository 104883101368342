/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */

import { ATTR_TYPE, Content, CONTENT_ATTR, CONTENT_ATTR2, ContentBuildType } from "./content.interface";
import { InquiryPropsBrands } from "./inquiry.interface";


export class BlogCreationContent extends Content {
    public link: string
    private _title: string
    private _description: string
    public role: string
    public content: string[]
    public refusal: string

    constructor(t: ContentBuildType) {
        super(t)
        this.link = ""
        this._title = ""
        this._description = ""
        this.role = ""
        this.content = []
        this.refusal = ""
    }

    get title(): string {
        return this._title;
    }

    get description(): string {
        return this._description;
    }

    get contentUrl(): string {
        return this.link;
    }

    getAttr(attr: CONTENT_ATTR): ATTR_TYPE {
        return 0;
    }

    getAttrs(): any {
        return {
            [CONTENT_ATTR2.TITLE]: this._title,
            [CONTENT_ATTR2.DESCRIPTION]: this._description,
            [CONTENT_ATTR2.URL]: this.link,
        };
    }

    isBrand(brand: InquiryPropsBrands): boolean {
        const source = this.getBrandName(brand)
        return this.isIncludeTextNoCase(source, [this.title, this.description])
    }

    fromJSON(data: any) {
        this.link = data.link
        this._title = "블로그 생성 결과"
        this._description = "블로그 생성 결과 입니다."
        this.role = data.role
        this.content = data.content.split("\n\n")
        this.refusal = data.refusal
    }

    static getSample(): any {
        return {
            "role": "assistant",
            "content": "콘텐츠 내용   \n이번 블로그 콘텐츠는 유산....",
            "refusal": null
        };
    }
}
