/**
 * Author : Bruce.Decent, the Sunbed Nomad
 * Date: 2021/07/13
 */


import { UserIdentity } from "@core2/index";
import {useAuthProvider} from "@core2/auth/useAuthProvider";
import { useQuery } from '@tanstack/react-query';
import { IDENTITY_QUERY_KEY } from '@providers/frontAuthProvider';



interface State {
    isLoading: boolean;
    identity?: UserIdentity;
    error?: any;
}


// export const useGetIdentity = () => {
//     const [state, setState] = useState({
//         identity: null,
//         isLoading: true,
//         error: null
//     } as State)
//     const authProvider = useAuthProvider();
//     const location = useLocation()
//     useEffect(() => {
//         authProvider.getIdentity().then((identity: UserIdentity) => {
//             setState({
//                 identity,
//                 isLoading: false,
//                 error: null
//             })
//         }).catch((error: any) => {
//             setState({
//                 identity: null,
//                 isLoading: false,
//                 error: error
//             })
//         })
//     }, [location, state, authProvider])
//     return state
// }


export const useGetIdentity2 = () => {
    const authProvider = useAuthProvider();
    return useQuery({
        queryKey: [IDENTITY_QUERY_KEY],
        queryFn: () => {
            return authProvider.getIdentity2()
        }
    })
}



