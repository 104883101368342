import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import "./index.css"


ReactDOM.createRoot(document.getElementById('root')!).render(
    
    <React.StrictMode>
        <BrowserRouter basename="/">
            <App />
        </BrowserRouter>
    </React.StrictMode>

);
