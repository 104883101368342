/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */
import {ATTR_TYPE, Content, CONTENT_ATTR, CONTENT_ATTR2, ContentBuildType} from "./content.interface";
import { InquiryPropsBrands } from "./inquiry.interface";

export class NaverCafeContent extends Content {
    public link: string           
    private _title: string         
    private _description: string    
    public cafeurl: string        
    public cafename: string       

    constructor(t: ContentBuildType) {
        super(t)
        this.link = ""
        this._title = ""
        this._description = ""
        this.cafeurl = ""
        this.cafename = ""
    }

    get title(): string {
        return this._title;
    }

    get description(): string {
        return this._description;
    }

    get contentUrl(): string {
        return this.link;
    }

    getAttr(attr: CONTENT_ATTR): ATTR_TYPE {
        return 0;
    }

    getAttrs(): any {
        return {
            [CONTENT_ATTR2.TITLE]: this._title,
            [CONTENT_ATTR2.DESCRIPTION]: this._description,
            [CONTENT_ATTR2.URL]: this.link,
        };
    }

    isBrand(brand: InquiryPropsBrands): boolean {
        const source = this.getBrandName(brand)
        return this.isIncludeTextNoCase(source, [this.title, this.description, this.cafename])
    }

    fromJSON(data: any) {
        this.link = data.link
        this._title = this.removeHtmlTags(data.title)
        this._description = this.removeHtmlTags(data.description)
        this.cafeurl = data.cafeurl
        this.cafename = data.cafename
    }

    static getSample(): any {
        return {
            "link": "http://cafe.naver.com/genshin/227022",
            "title": "모바일 최적화 됐네요",
            "cafeurl": "https://cafe.naver.com/genshin",
            "cafename": "원신 공식카페",
            "description": "원래 <b>멀티비</b>경하거나 나선가면 화면 계속 멈췄는데 이제 렉도 없고 안 멈추네요 기종은 s10",
            "url": "http://cafe.naver.com/genshin/227022"
        };
    }
}
