import React, { createContext, useContext, useState } from 'react';
import { Alert, AlertColor, AlertTitle, Snackbar } from '@mui/material';
import { getErrorMessage } from '../../locale';

// Create a context
const AlertContext = createContext({
    success: (message: string, title?: string) => {
        console.log(message, title)
    },
    error: (message: string|object, title?: string) => {
      console.log(message, title)
    },
    info: (message: string, title?: string) => {
        console.log(message, title)
    },
    warning: (message: string, title?: string) => {
        console.log(message, title)
    }
});

// Custom hook to use the Alert context
export const useAlert = () => {
  return useContext(AlertContext);
};

// Provider component
export const AlertProvider = ({ children }) => {
  const [alertState, setAlertState] = useState({
    open: false,
    message: '',
    severity: 'info', // 'success', 'warning', 'error', 'info'
    title: '',
  });

  const showAlert = (message, severity = 'info', title = '') => {
    setAlertState({ open: true, message, severity, title });
  };

  const closeAlert = () => {
    setAlertState((prev) => ({ ...prev, open: false }));
  };

  const success = (message: string, title: string = "성공") => {
    showAlert(message, 'success', title)
  }

  const error = (message: string|any, title: string = "오류") => {
    // showAlert(message, 'error', title)
    if (message instanceof Object) {
      const textMessage = getErrorMessage(message)
      showAlert(textMessage, 'error', title)
    } else {
      showAlert(message, 'error', title)
    }
  }

  const info = (message: string, title: string = "안내") => {
    showAlert(message, 'info', title)
  }

  const warning = (message: string, title: string = "경고") => {
    showAlert(message, 'warning', title)
  }

  return (
    <AlertContext.Provider value={{ success, error, info, warning }}>
      {children}
      <Snackbar
        open={alertState.open}
        autoHideDuration={5000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={closeAlert}
          severity={alertState.severity as AlertColor}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alertState.title && <AlertTitle>{alertState.title}</AlertTitle>}
          {alertState.message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};