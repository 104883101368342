/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */
import dayjs from "dayjs";
import {ATTR_TYPE, Content, CONTENT_ATTR, CONTENT_ATTR2, ContentBuildType} from "./content.interface";
import { InquiryPropsBrands } from "./inquiry.interface";

export class NaverBlogContent extends Content {
    public link: string           
    private _title: string
    private _description: string  
    public postdate: string      
    public bloggerlink: string   
    public bloggername: string    

    constructor(t: ContentBuildType) {
        super(t)
        this.link = ""
        this._title = ""
        this._description = ""
        this.postdate = ""
        this.bloggerlink = ""
        this.bloggername = ""
    }

    get title(): string {
        return this._title;
    }

    get description(): string {
        return this._description;
    }

    get contentUrl(): string {
        return this.link;
    }

    getAttr(attr: CONTENT_ATTR): ATTR_TYPE {
        return 0;
    }

    getAttrs(): any {
        return {
            [CONTENT_ATTR2.TITLE]: this._title,
            [CONTENT_ATTR2.DESCRIPTION]: this._description,
            [CONTENT_ATTR2.POST_DATE]: this.postdate,
            [CONTENT_ATTR2.AUTHOR]: this.bloggername,
            [CONTENT_ATTR2.URL]: this.link
        };
    }

    isBrand(brand: InquiryPropsBrands): boolean {
        const source = this.getBrandName(brand)
        return this.isIncludeTextNoCase(source, [this.title, this.description])
    }

    fromJSON(data: any) {
        this.link = data.link
        this._title = this.removeHtmlTags(data.title)
        this._description = this.removeHtmlTags(data.description)
        this.postdate = dayjs(data.postdate).format("YYYY-MM-DD")   
        this.bloggerlink = data.bloggerlink
        this.bloggername = data.bloggername
    }

    static getSample(): any {
        return {
            "link": "https://blog.naver.com/skstbvjcjqj/223428176512",
            "title": "당류제로 프로틴 추천 맛까지 챙긴 얼티브 밤맛 쌀밥맛",
            "description": "얼티브 프로틴 안녕하세요, 수댕입니다! 운동과 식단 관리를 병행하고 계신 분들이시라면 빠질 수 없는 게 바로 프로틴이죠. 누구나 한 번 마셔보면 틀림없이 프로틴 추천하게 될 얼티브 밤맛 쌀밥맛! 바로 이 둘이 그 주인공인데...",
            "postdate": "20240426",
            "bloggerlink": "blog.naver.com/skstbvjcjqj",
            "bloggername": "수댕쓰"
        };
    }

}

