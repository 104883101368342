
import { useRecordContext } from "@core2/record";
import { Typography } from "@mui/material";
import { useMemo } from "react";

// export const FunctionField = (props) => {
//     let { render } = props
//     const record = useRecordContext()
//     const result = render(record)
//     return <Typography >
//         {result}
//     </Typography>
// }

// export const FunctionField = (
//     props: any
// ) => {
//     const { source = '', render, sx, ...rest } = props;
//     const record = useRecordContext(props);
//     return useMemo(
//         () =>
//             record ? (
//                 <Typography
//                     component="span"
//                     variant="body2"
//                     sx={sx}
//                 >
//                     {render(record, source)}
//                 </Typography>
//             ) : null,
//         [record, source, render, rest, sx]
//     );
// };


export const FunctionField = (props: any) => {
    const { source = '', render, ...rest } = props;
    const record = useRecordContext(props);
    return (
        <Typography
            component="span"
            variant="body2"
            {...rest}
        >
            {render(record, source)}
        </Typography>
    )
};