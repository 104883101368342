
import { AppStyle } from "./app-style";
import { useFormContext } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from "react-router-dom";

export const PrimaryButton = (props: any) => {
    const { id, children, redirect, onClick, variant="contained", checkIsValid = false, submitLoading = true, ...rest } = props;
    const formContext = useFormContext();
    const navigate = useNavigate();
    let isSubmitting = false
    let isValid = true
    if (formContext) {
        if (submitLoading) {
            isSubmitting = formContext.formState?.isSubmitting
        }
        if (checkIsValid) {
            isValid = formContext.formState?.isValid
        }
    }
    function handleClick() {
        if (redirect) {
            navigate(redirect);
        }
        if (onClick) {
            onClick();
        }
    }
    return <>
        <LoadingButton
            variant={variant}
            loading={isSubmitting}
            disabled={!isValid}
            sx={AppStyle.primaryButton}
            onClick={handleClick}
            {...rest}
            data-testid={id}
        >
            {children}
        </LoadingButton>
    </>
}