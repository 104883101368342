import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Button,
    Menu,
    MenuItem,
    IconButton,
    Container,
    Divider,
    Skeleton,
    Stack,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { COLOR_INDEX } from '@interfaces/color-index.interface';
import { IconUser, IconTrendLink } from '@components/icons';
import { get } from 'lodash-es';
import { useMembershipStatus } from '@hooks/useMembership';
import { useLogout } from '@core2/auth';
import { useGetIdentity2 } from '@core2/auth/useGetIdentity';
import { UserIdentity } from '@core2/index';
import { ClientContainer } from '@containers/common/view-container';

interface MenuConfig {
    label: string;
    path?: string;
    items: {
        label: string;
        path: string;
    }[] | null;
}


const menus: MenuConfig[] = [
    {
        label: '트렌드 분석',
        items: null,
        path: '/trend'
    },
    {
        label: '콘텐츠 분석',
        items: [
            { label: '블로그 콘텐츠', path: '/content?t=blog' },
            { label: '메타 광고', path: '/content?t=meta' },  // path 수정 예시
        ],
    },
    {
        label: '콘텐츠 생성',
        items: [
            { label: '블로그 콘텐츠', path: '/generate?t=blog' },
            { label: '메타 광고', path: '/generate?t=meta' },  // path 수정 예시
        ],
    },
    {
        label: '멤버십 소개',
        items: null,
        path: '/membership'
    },
];


const UserInfo = (props: any) => {
    const { identity, membership, membershipLoading, isLoading } = props
    // const grade = get(membership, "props.currentGrade.displayName", "")
    const grade = membership?.currentDisplayName() || ""
    const userName = get(identity, "name", "")
    return <>
        {membershipLoading && <Skeleton variant="text" width={40} height={20} />}
        {grade && <Typography id="user-grade" component="span" color="primary" fontSize={12}>[{grade}]</Typography>}
        {isLoading 
            ? <Skeleton variant="text" width={40} height={20} />
            :<Typography id="user-name"component="span" color="dark-grey" fontSize={12}>{userName}</Typography>
        }
    </>
}


const LoginJoinMenu = () => {
    const navigate = useNavigate()
    const handleNavigate = (path?: string) => {
        if (path) {
            navigate(path);
        }
    };
    return (
        <Box sx={{ display: 'flex', gap: 0.5, backgroundColor: "white", p: 0.5 }}>
            <Button
                variant="text"
                color="secondary"
                // size="small"
                onClick={() => handleNavigate('/login')}
            >
                로그인
            </Button>
            <Divider orientation="vertical" flexItem variant="middle" />
            <Button
                variant="text"
                color="secondary"
                // size="small"
                onClick={() => handleNavigate('/register')}
            >
                회원가입
            </Button>
        </Box>
    )
}

const UserMenuSection = (props: any) => {
    const { data: identity, isLoading } = useGetIdentity2()
    const { membership, isLoading: membershipLoading } = useMembershipStatus()
    const logout = useLogout()
    const isLoggedIn = !!identity
    const user = (identity || {}) as UserIdentity
    const navigate = useNavigate();
    const { handleUserMenuOpen, handleUserMenuClose, userMenuAnchor, handleNavigate } = props
    const handleLogout = () => {
        logout()
        navigate("/"); // React Router로 URL 변경 (메인 페이지로 이동)
        window.location.reload(); // 페이지 리로드 (토큰 및 기타 정보 갱신)
    }
    if (isLoading && membershipLoading) {
        return <Stack direction="row" spacing={1} alignItems="center">
            <Skeleton variant="text" width={40} height={20} />
            <Skeleton variant="text" width={40} height={20} />
            <Skeleton variant="circular" animation="wave" width={20} height={20} />
            <Skeleton variant="text" width={10} height={10} />
        </Stack>
    }
    return (
        <Box sx={{ flexGrow: 0 }}>
            {isLoggedIn ?
                <Box sx={{ flexGrow: 1, display: 'flex', gap: 1, alignItems: 'center' }}>
                    <UserInfo identity={user} isLoading={isLoading} membership={membership} membershipLoading={membershipLoading} />
                    <IconButton onClick={handleUserMenuOpen} sx={{ p: 0 }}>
                        <IconUser size={19} />
                        <KeyboardArrowDownIcon />
                    </IconButton>
                    <Menu
                        anchorEl={userMenuAnchor}
                        open={Boolean(userMenuAnchor)}
                        onClose={handleUserMenuClose}
                    >
                        <Box sx={{ px: 2, py: 1 }}>
                            <Typography variant="subtitle1">
                                {user.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {user.email}
                            </Typography>
                        </Box>
                        <MenuItem
                            onClick={() => {
                                handleUserMenuClose();
                                handleNavigate('/my');
                            }}
                        >
                            마이페이지
                        </MenuItem>
                        <MenuItem onClick={() => handleLogout()}>로그아웃</MenuItem>
                    </Menu>
                </Box> : <LoginJoinMenu />}
        </Box>
    )
}


export const TopBarContainer = () => {
    const navigate = useNavigate();

    // 각 메뉴(상위)마다 anchor를 저장 (null이면 닫혀 있음)
    const [menuAnchors, setMenuAnchors] = React.useState<(HTMLElement | null)[]>(
        new Array(menus.length).fill(null)
    );
    // 유저 메뉴 anchor
    const [userMenuAnchor, setUserMenuAnchor] = React.useState<null | HTMLElement>(null);

    // 상위 메뉴 열기
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, index: number) => {
        // 클릭한 메뉴만 열고, 나머지는 닫음(원한다면 나머지 닫기 로직 생략 가능)
        setMenuAnchors((prev) => {
            const newAnchors = [...prev].map(() => null);
            newAnchors[index] = event.currentTarget;
            return newAnchors;
        });
    };

    // 상위 메뉴 닫기
    const handleMenuClose = (index: number) => {
        setMenuAnchors((prev) => {
            const newAnchors = [...prev];
            newAnchors[index] = null;
            return newAnchors;
        });
    };

    // 유저 메뉴 열기/닫기
    const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setUserMenuAnchor(event.currentTarget);
    };
    const handleUserMenuClose = () => {
        setUserMenuAnchor(null);
    };

    // 페이지 이동
    const handleNavigate = (path?: string) => {
        if (path) {
            navigate(path);
        }
    };



    return (
        <AppBar
            component={"nav"}
            position="sticky"
            color="transparent"
            elevation={0}
            // sx={{ backgroundColor: 'transparent' }}
            sx={{ backgroundColor: COLOR_INDEX.BACKGROUND_COLOR, padding: 0 }}
        >
            <ClientContainer>
            {/* <Container sx={SIZE_INDEX.MAIN_CONTAINER_SX, padding: 0}> */}
            
                <Toolbar disableGutters>
                    {/* Logo */}
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            flexGrow: 0,
                            cursor: 'pointer',
                            marginRight: 4,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onClick={() => navigate('/')}
                    >
                        <IconTrendLink size={16} />
                    </Typography>

                    {/* Navigation Menus */}
                    <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }}>
                        {menus.map((menu, index) => {
                            const hasSub = !!menu.items;
                            return (
                                <React.Fragment key={index}>
                                    <Button
                                        color="inherit"
                                        onClick={(e) => {
                                            if (hasSub) {
                                                handleMenuOpen(e, index);
                                            } else {
                                                handleNavigate(menu.path);
                                            }
                                        }}
                                        endIcon={hasSub ? <KeyboardArrowDownIcon /> : null}
                                    >
                                        {menu.label}
                                    </Button>

                                    {/* 서브 메뉴 */}
                                    {hasSub && (
                                        <Menu
                                            anchorEl={menuAnchors[index]}
                                            open={Boolean(menuAnchors[index])}
                                            onClose={() => handleMenuClose(index)}
                                            MenuListProps={{
                                                'aria-labelledby': `${menu.label}-button`,
                                            }}
                                        >
                                            {menu.items?.map((item, idx) => (
                                                <MenuItem
                                                    key={idx}
                                                    onClick={() => {
                                                        // 1) 서브 메뉴 닫기
                                                        handleMenuClose(index);
                                                        // 2) 이동
                                                        handleNavigate(item.path);
                                                    }}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Box>
                    <UserMenuSection
                        handleUserMenuOpen={handleUserMenuOpen}
                        handleUserMenuClose={handleUserMenuClose}
                        userMenuAnchor={userMenuAnchor}
                        handleNavigate={handleNavigate}
                    />
                </Toolbar>
                </ClientContainer>
        </AppBar>
    );
};


