/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2023/05/13
 */
import {FormHandleContext} from './FormHandleContext';
import {RecordContextProvider, usePickSaveContext, SaveContextProvider} from "@core2/index";


export const FormHandleContextProvider = (props) => {
    const {children, value} = props
    return <FormHandleContext.Provider value={value}>
        <SaveContextProvider value={usePickSaveContext(value)}>
            <RecordContextProvider value={value && value.record}>
                {children}
            </RecordContextProvider>
        </SaveContextProvider>
    </FormHandleContext.Provider>
}
