
import { createContext } from 'react';

export const ResourceContext = createContext(
    undefined
);

ResourceContext.displayName = 'RecordContext';


