


export const IconPickTicket = (props) => {
    const { size = 20 } = props;
    return <img src="/img/pick-ticket.png" alt="Pick Ticket" height={size} />
}



export const IconBook = (props) => {
    const { size = 20 } = props;
    return <img src="/icon/book.svg" alt="Book" height={size} />
}


export const IconTools = (props: any) => {
    const { size = 20 } = props;
    return <img src="/icon/tools.svg" alt="Tools" height={size} />
}

export const IconPalette = (props) => {
    const { size = 20 } = props;
    return <img src="/icon/palette.svg" alt="Palette" height={size} />
}

export const IconGraph = (props) => {
    const { size = 20 } = props;
    return <img src="/icon/graph.svg" alt="Graph" height={size} />
}

export const IconShineHeart = (props) => {
    const { size = 20 } = props;
    return <img src="/icon/heart-shine.svg" alt="Heart Shine" height={size} />
}

export const IconUser = (props) => {
    const { size = 20 } = props;
    return <img src="/icon/user.svg" alt="User" height={size} />
}

export const IconTrendLink = (props) => {
    const { size = 20 } = props;
    return <img src="/icon/trend-link.svg" alt="Trend Link" height={size} />
}


export const IconSearch = (props: any) => {
    const { size = 20 } = props;
    return <img src="/icon/search.svg" alt="Search" height={size} />
}


export const IconArrowRight = (props: any) => {
    const { size = 20 } = props;
    return <img src="/icon/arrow-right.svg" alt="Arrow Right" height={size} />
}


