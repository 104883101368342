import { Container, Box } from '@mui/material';
import { SimpleForm } from '@core2/form';
import { SIZE_INDEX } from '@interfaces/size-index.interface';

export const ViewContainer = (props: any) => {
    const { children } = props
    return (
        <Container>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {children}
            </Box>
        </Container>
    )
}


export const ClientContainer = (props: any) => {
    const { children, sx, ...rest } = props
    return (
        <Container disableGutters sx={{maxWidth: SIZE_INDEX.MAIN_CLIENT_CONTAINER_MAX_WIDTH, ...sx}} {...rest}>
            {children}
        </Container>
    )
}