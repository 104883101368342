import React, { useState, useRef, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import {
    Box,
    Button,
    Typography,
    Paper,
    TextField as MuiTextField,
    Grid2,
    Stack,
    InputAdornment,
} from "@mui/material";
import { ResponsivePaper } from "./responsive-paper";
import { IconCalendar } from "../icons/shared-icons";
// dayjs 플러그인 추가
dayjs.extend(isBetween);


interface DayIteType {
    day: number
    date: string
    sx?: object | null
}

class Calendar {

    static generateCalendarCoordinates(year: number, month: number) {

        // 1. 해당 월의 첫 번째 날과 마지막 날 계산
        const firstDay = dayjs(`${year}-${month}-01`)
        const lastDay = firstDay.endOf("month");

        // 2. 첫 번째 날의 요일과 총 날짜 수
        const startDay = (firstDay.day() + 6) % 7; // 월요일 시작으로 변환 (일요일: 0 → 월요일: 0)
        const daysInMonth = lastDay.date();

        // 3. 달력 좌표를 생성
        const matrix = []
        let rows = []
        let currentWeek = 0;

        // 앞 빈칸 추가
        for (let i = 0; i < startDay; i++) {
            rows.push({
                day: -1,
                date: ""
            })
        }
        for (let date = 1; date <= daysInMonth; date++) {
            const weekday = (startDay + date - 1) % 7; // 현재 날짜의 요일(0~6)
            rows.push({
                day: date,
                date: firstDay.add(date - 1, "day").format("YYYY-MM-DD")
            })
            if (weekday === 6) {
                // 새로운 주로 넘어가는 경우 y 증가. rows 를 calendar(column) 에 추가 새 rows 준비
                currentWeek++;
                matrix.push(rows)
                rows = []
            }
        }
        if (rows.length > 0) {
            matrix.push(rows)
        }
        return matrix
    }
}


const HeaderItem = (props) => {
    const { label } = props
    return <Typography variant="body2" textAlign="center">{label}</Typography>
}





const DayItem = (props) => {
    const { dayItem } = props
    return <>
        <Typography variant="body2"
            sx={{
                // height: "30px", // 높이 지정
                display: "flex", // Flexbox 적용
                alignItems: "center", // 세로 중앙 정렬
                justifyContent: "center", // 가로 중앙 정렬
                textAlign: "center", // 텍스트 가로 중앙 정렬
                lineHeight: "30px", // 높이와 동일한 line-height로 세로 정렬
            }}>
            {dayItem.day}
        </Typography>
    </>
}


const CalendarHeader = (props) => {
    const { date, onPrev, onNext } = props
    const isPrev = !!onPrev
    const isNext = !!onNext
    return <Stack direction="row" justifyContent="space-between">
        <Button disabled={!isPrev} onClick={onPrev}>
            <Typography>{isPrev && '<'}</Typography>
        </Button>
        <Typography align="center" sx={{ mb: 1 }}>
            {date
                ? dayjs(date).format("MMMM YYYY")
                : dayjs().format("MMMM YYYY")}
        </Typography>
        <Button disabled={!isNext} onClick={onNext}>
            <Typography>{isNext && '>'}</Typography>
        </Button>
    </Stack>
}

const DateRangePickerTextField = (props) => {
    const { startDate, endDate, togglePicker, sx } = props
    return <>
        <MuiTextField
            // label="Date Range"
            variant="outlined"
            size="small"
            fullWidth
            value={`${startDate ? startDate.format("YYYY-MM-DD") : ""} - ${endDate ? endDate.format("YYYY-MM-DD") : ""}`}
            onClick={togglePicker}
            slotProps={{
                input: {
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                          <IconCalendar/>
                        </InputAdornment>
                      ),
                },
            }}
            sx={sx}
        />
    </>
}

///////////////////////
export interface DateRangePickerProps {
    initialStartDate?: string | null
    initialEndDate?: string | null
    minDate?: string | null
    maxDate?: string | null
    buttons?: boolean
    onApply: (date: { startDate: string, endDate: string }) => void
    sx?: any
    rightAlign?: boolean
}


export const DateRangePicker = (props: DateRangePickerProps) => {
    const { initialStartDate, initialEndDate, minDate, maxDate, buttons, onApply, sx, rightAlign=false } = props
    const [startDate, setStartDate] = useState(initialStartDate ? dayjs(initialStartDate) : null);
    const [endDate, setEndDate] = useState(initialEndDate ? dayjs(initialEndDate) : null);
    const [hoveredDate, setHoveredDate] = useState(null);
    const [isPickerVisible, setIsPickerVisible] = useState(false);
    const [firstCalendarDate, setFirstCalendarDate] = useState(null);
    const [secondCalendarDate, setSecondCalendarDate] = useState(null);
    const pickerRef = useRef(null);

    // Picker 가시성 토글
    const togglePicker = () => {
        setIsPickerVisible(!isPickerVisible);
    };

    // 날짜 클릭 시 로직
    const handleDateClick = (dayItem: DayIteType) => {
        const date = dayjs(dayItem.date)
        if (!startDate || (startDate && endDate)) {
            // 시작날 없거나 둘다 있을 때 -> 첫 번째 날짜 선택
            setStartDate(date);
            setEndDate(null);
        } else if (!endDate) {
            // 종료날 없을 떄 -> 두 번째 날짜 선택
            // setEndDate(date);
            let startItem = startDate
            let endItem = date
            if (date.isBefore(startDate)) {
                // end 가 start 이전 날짜 일때 -> 날짜 교환
                const temp = startItem
                startItem = date
                endItem = temp
            }
            setStartDate(startItem);
            setEndDate(endItem);
            if (!buttons) {
                // 버튼이 없으면 즉시 apply 
                applyRange(startItem.format("YYYY-MM-DD"), endItem.format("YYYY-MM-DD"))
            }
        } else {
            // 둘다 없을 때 -> 첫 번째 날짜 선택
            setStartDate(date);
            setEndDate(null);
        }
    };

    function applyRange(start: string, end: string) {
        if (onApply) {
            onApply({ startDate: start, endDate: end });
        }
        setIsPickerVisible(false);
    }

    // Apply 버튼 클릭 시
    const handleApply = () => {
        applyRange(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"))
    };

    // Cancel 버튼 클릭 시
    const handleCancel = () => {
        setStartDate(initialStartDate ? dayjs(initialStartDate) : null);
        setEndDate(initialEndDate ? dayjs(initialEndDate) : null);
        setIsPickerVisible(false);
    };

    // Picker 외부 클릭 시 닫기
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (pickerRef.current && !pickerRef.current.contains(e.target)) {
                setIsPickerVisible(false);
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const firstMonth = startDate ? dayjs(startDate) : dayjs()
        const secondMonth = firstMonth.add(1, "month")
        const firstDate = firstMonth.format("YYYY-MM-DD")
        const secondDate = secondMonth.format("YYYY-MM-DD")
        setFirstCalendarDate(firstDate)
        setSecondCalendarDate(secondDate)
    }, [startDate])

    const calendar = useMemo(() => {
        const firstMonth = startDate ? dayjs(firstCalendarDate) : dayjs()
        const secondMonth = firstMonth.add(1, "month")
        const first = Calendar.generateCalendarCoordinates(firstMonth.year(), firstMonth.month() + 1) // dayjs는 0~11로 관리하므로 +1
        const second = Calendar.generateCalendarCoordinates(secondMonth.year(), secondMonth.month() + 1)
        return {
            first,
            second,
        }
    }, [firstCalendarDate])

    // 날짜 범위 하이라이트 로직
    // DayItem.sx를 동적으로 할당하기 위한 함수
    const computeDayItemStyle = (dayItem: DayIteType) => {
        if (!dayItem.date) return {}; // 빈 셀

        const dayObj = dayjs(dayItem.date);
        if (!startDate) return {};

        let inRange = false;
        let inHoverRange = false;
        let isStart = false;
        let isEnd = false;

        // 선택중
        if (startDate && hoveredDate) {
            const tempStart = startDate.isBefore(hoveredDate) ? startDate : hoveredDate;
            const tempEnd = startDate.isAfter(hoveredDate) ? startDate : hoveredDate;
            inHoverRange = dayObj.isAfter(tempStart, "day") && dayObj.isBefore(tempEnd, "day");
            isStart = dayObj.isSame(tempStart, "day");
            isEnd = dayObj.isSame(tempEnd, "day");
        }
        // 드래그 완료 후: endDate가 있을 때
        if (startDate && endDate) {
            inRange = dayObj.isBetween(startDate, endDate, "day", "[]")
            isStart = dayObj.isSame(startDate, "day");
            isEnd = dayObj.isSame(endDate, "day");
        } else {
            // startDate만 있는 경우
            isStart = dayObj.isSame(startDate, "day");
        }

        // 스타일 구성
        const baseStyle: any = {
            // boxSizing: 'border-box'를 사용하면 border가 포함된 총 크기를 유지
            boxSizing: 'border-box',
            // 처음부터 투명 보더를 지정해 레이아웃이 움직이지 않도록 함
            // border: '1px solid transparent',
            border: '1px solid transparent',
        };
        if (inRange) {
            baseStyle.backgroundColor = "rgba(33, 150, 243, 0.2)";
        }
        if (inHoverRange) {
            baseStyle.backgroundColor = "rgba(133, 133, 133, 0.1)";
        }
        if (isStart || isEnd) {
            baseStyle.border = "1px solid #2196f3";
        }
        return baseStyle;
    };

    const handleMouseEnter = (itemDay: DayIteType) => {
        setHoveredDate(itemDay.date);
    };

    const handleMouseLeave = () => {
        // 마우스가 버튼 영역을 벗어나면 hoverIndex를 0으로 초기화
        setHoveredDate(null);
    };

    const handlePrev = () => {
        setFirstCalendarDate(dayjs(firstCalendarDate).subtract(1, "month").format("YYYY-MM-DD"))
        setSecondCalendarDate(dayjs(secondCalendarDate).subtract(1, "month").format("YYYY-MM-DD"))
    }

    const handleNext = () => {
        setFirstCalendarDate(dayjs(firstCalendarDate).add(1, "month").format("YYYY-MM-DD"))
        setSecondCalendarDate(dayjs(secondCalendarDate).add(1, "month").format("YYYY-MM-DD"))
    }


    function renderCalendar2(matrix: any[]) {
        return (
            <Grid2
                container
                spacing={0}
                columns={7}
                sx={{
                    minWidth: "200px",
                    margin: "10 auto",
                    textAlign: "center",
                    backgroundColor: "#f9f9f9",
                    padding: "10px",
                    borderRadius: "10px",
                }}
            >
                {/* 요일 헤더 */}
                {/* {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day, index) => (
                    <Grid2 key={index} size={1} sx={{ fontWeight: "bold", color: "#333" }}>
                        <HeaderItem label={day} />
                    </Grid2>
                ))} */}
                {["M", "T", "W", "T", "F", "S", "S"].map((day, index) => (
                    <Grid2 key={index} size={1} sx={{ fontWeight: "bold", color: "#333" }}>
                        <HeaderItem label={day} />
                    </Grid2>
                ))}

                {/* 날짜 셀 */}
                {
                    matrix.map((rows, idx1) => {
                        const row = rows.map((item, idx2) => {
                            if (item.day > 0) {
                                const computedSx = computeDayItemStyle(item);
                                const newDayItem = { ...item, sx: computedSx };
                                return (
                                    <Grid2 key={idx1 * 7 + idx2} size={1} sx={{ fontWeight: "bold", color: "#333", mb: "4px" }} >
                                        <Box sx={newDayItem.sx}
                                            height="100%"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            onMouseEnter={() => handleMouseEnter(newDayItem)}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => handleDateClick(newDayItem)}
                                        >
                                            <DayItem dayItem={item} />
                                        </Box>
                                    </Grid2>
                                )
                            }
                            else {
                                // 빈칸
                                return <Grid2 key={idx1 * 7 + idx2} size={1} sx={{ fontWeight: "bold", color: "#333" }} />

                            }
                        })
                        return row
                    })
                }
            </Grid2>
        );
    }

    return (
        <Box ref={pickerRef} sx={{ position: "relative", ...sx }}>
            <DateRangePickerTextField 
                startDate={startDate}
                endDate={endDate}
                togglePicker={togglePicker}
                // sx={sx}
            />
            {isPickerVisible && (
                <ResponsivePaper rightAlign={rightAlign}>
                    <Grid2 container spacing={2}>
                        {/* 첫 번째 달력 */}
                        <Grid2 size={6}>
                            <CalendarHeader date={firstCalendarDate} onPrev={handlePrev} />
                            <Grid2 container spacing={0.5}>
                                {renderCalendar2(calendar.first)}
                            </Grid2>
                        </Grid2>

                        {/* 두 번째 달력 */}
                        <Grid2 size={6}>
                            <CalendarHeader date={secondCalendarDate} onNext={() => handleNext()} />
                            <Grid2 container spacing={0.5}>
                                {renderCalendar2(calendar.second)}
                            </Grid2>
                        </Grid2>
                    </Grid2>

                    {/* 하단 버튼들 */}
                    {
                        buttons && (
                            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                                <Button variant="outlined" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button variant="contained" onClick={handleApply}>
                                    Apply
                                </Button>
                            </Box>
                        )
                    }
                </ResponsivePaper>
            )}
        </Box>
    );
};