/**
 * Author : Bruce.Decent, the Sunbed Nomad
 * Date: 2022/02/15
 */

import React from "react";
// import ReactPaginate from 'react-paginate';
// import './Pagination.css'
import {useListPaginationContext} from "@core2/index";
import { Pagination, PaginationItem } from "@mui/material";


// export const Pagination2 = props => {
//     // const {perPage, total, onChange, setPage, page} = props
//     const {
//         // isLoading,
//         // hasNextPage,
//         page,
//         perPage,
//         total,
//         setPage,
//         // setPerPage,
//     } = useListPaginationContext(props);
//     const pageCount = Math.ceil(total / perPage)
//     // const handlePageClick = (event) => {
//     //     if (setPage) {
//     //         setPage(event.selected + 1)
//     //         // console.log('set-page', event.selected + 1)
//     //     }
//     //     if (onChange) {
//     //         onChange(event.selected + 1)
//     //     }
//     // }
//     const totalPages = useMemo(() => {
//         return total != null ? Math.ceil(total / perPage) : undefined;
//     }, [perPage, total]);

//     const handlePageChange = (event) => {
//         // event && event.stopPropagation();
//         if (setPage) {
//             setPage(event.selected + 1)
//             // console.log('set-page', event.selected + 1)
//         }
//         // if (onChange) {
//         //     onChange(event.selected + 1)
//         // }
//     }

//     return <>{pageCount > 1 ? <ReactPaginate
//         breakLabel="..."
//         nextLabel="NEXT"
//         onPageChange={handlePageChange}
//         pageRangeDisplayed={perPage}
//         pageCount={pageCount}
//         forcePage={page-1}
//         previousLabel="PREV"
//         renderOnZeroPageCount={null}
//         containerClassName={"pagination-container"}

//         // pageClassName={'page-btn'}
//         pageLinkClassName={'page-link'}

//         // activeClassName={"page-active"}
//         activeLinkClassName={"page-active-link"}

//         // previousClassName={"page-link page-prev"}
//         // nextClassName={"page-link page-next"}

//         previousLinkClassName={"page-link page-prev"}
//         nextLinkClassName={"page-link page-next"}

//         disabledLinkClassName={'page-disabled-link'}
//     /> : ''}</>
// }


export const Pagination3 = props => {
    const {
        // isLoading,
        // hasNextPage,
        page,
        perPage,
        total,
        setPage,
        // setPerPage,
    } = useListPaginationContext(props);
    const pageCount = Math.ceil(total / perPage)
    // const totalPages = useMemo(() => {
    //     return total != null ? Math.ceil(total / perPage) : undefined;
    // }, [perPage, total]);

    const handlePageChange = (event) => {
        // event && event.stopPropagation();
        if (setPage) {
            setPage(event.selected + 1)
            // console.log('set-page', event.selected + 1)
        }
        // if (onChange) {
        //     onChange(event.selected + 1)
        // }
    }

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        // setPage(value);
        if (setPage) {
            setPage(value)
            // console.log('set-page', event.selected + 1)
        }
      };

    return <>{pageCount > 1 && 
        <Pagination 
                count={pageCount}
                page={page} // 현재 페이지
                onChange={handleChange} // 페이지 변경 이벤트
                color="primary" // 색상 설정 (primary, secondary 등)
                size="medium" // 크기 설정 (small, medium, large)
                shape="rounded" // 모양 설정 (rounded, circular 등)
                renderItem={(item) => (
                    <PaginationItem
                        slots={{ 
                            previous: () => <div>{"< 이전"}</div>, 
                            next: () => <div>{"다음 >"}</div> 
                        }}
                      {...item}
                    />
                  )}    
        />
    }</>
}


