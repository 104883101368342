import { CoreTextField } from "@core2/form/fields/CoreTextField";
import { useRecordContext } from "@core2/record";
import { Typography } from "@mui/material";
import { get } from "lodash-es";

function formatNumber(count) {
    if (count < 1000) {
        // 1000 미만: 숫자 그대로 출력
        return `${count}`;
    } else if (count < 10000) {
        // 1000 이상 ~ 10,000 미만: 소숫점 첫째 자리를 반올림하여 n.n천
        return `${(count / 1000).toFixed(1)}천`;
    } else if (count < 1000000) {
        // 10,000 이상 ~ 1,000,000 미만: 소숫점 첫째 자리를 반올림하여 n.n만
        return `${(count / 10000).toFixed(1)}만`;
    } else {
        // 1,000,000 이상: 소숫점 첫째 자리를 반올림하여 n.n백만
        return `${(count / 1000000).toFixed(1)}백만`;
    }
}

export const NumberField = (props) => {
    const { source, footer, humanize, ...rest } = props
    const record = useRecordContext()
    let number = Number(get(record, source)) || 0
    let numberString = number.toLocaleString()
    if (humanize) {
        numberString = formatNumber(number)
    }
    return <Typography {...rest}>
        {numberString} {footer?footer: ""}
    </Typography>
}


export const PercentFieldWithUnit = (props) => {
    const { source, footer, total, ...rest } = props
    const record = useRecordContext()
    const number = get(record, source)
    return <Typography {...rest}>
        {number? `${((number / total) * 100).toFixed(1)}` : 0} {footer?footer: ""}
    </Typography>
}

