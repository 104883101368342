import React from 'react';


interface ViewProps {
    className?: string
    root?: boolean
    vertical?: boolean,
    fill?: boolean,
    scrollable?: boolean,
    // onEndReached: Function
    children: React.ReactNode
}

export const View = React.forwardRef(({
                                                              className,
                                                              root = false,
                                                              vertical = false,
                                                              fill = false,
                                                              scrollable = false,
                                                              children
                                                          }: ViewProps, ref) => {

    return <div
        // @ts-ignore
        ref={ref}
        className={`view ${className} ${root ? 'view--root' : ''} ${vertical ? 'view--vertical' : ''} ${fill ? 'view--fill' : ''} ${scrollable ? 'view--scrollable' : ''}`}
    >
        {children}
    </div>;
});
