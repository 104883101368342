/**
 * Author : Bruce.Decent, the Sunbed Nomad
 * Date: 2021/07/13
 */


import { createContext } from 'react';

export const LocalDataProviderContext = createContext<any>(null);

LocalDataProviderContext.displayName = 'LocalDataProviderContext';
