/**
 * Author : Bruce.Decent, the Sunbed Nomad
 * Date: 2021/09/24
 */

import { LoadingSkeleton } from "@components/custom-ui"
import { useGetIdentity2 } from "@core2/auth"
import { useEffect } from "react"
import { useNavigate } from "react-router"


export const AuthorizedPage = (props) => {
  const { redirect, children } = props;
  const navigate = useNavigate();
  const { data: identity, isLoading } = useGetIdentity2();

  useEffect(() => {
    if (!isLoading && !identity) {
      // 로딩을 완료했으나 인증이 안된 경우
      navigate(redirect); // navigate를 렌더링 이후로 이동
    }
  }, [isLoading, identity, navigate, redirect]);

  if (isLoading) {
    return <LoadingSkeleton />    // 로딩 상태 처리
    // return <></>
  }
  
  if (!identity) {
    return null; // navigate 후 추가 렌더링 방지
  }

  return <>{children}</>;
};
