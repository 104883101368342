import { QueryClient } from "@tanstack/react-query";



const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 30 * 1000, // 30 seconds,
            gcTime: 1000 * 60 * 10, // 캐시는 10분 동안 유지
            refetchOnWindowFocus: false, // 창이 포커스를 얻을 때 재패칭하지 않음
            retry: 0,
        },
    },
});


export function getQueryClient() {
    return queryClient
}