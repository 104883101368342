import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { get } from "lodash-es";
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { COLOR_INDEX } from '@interfaces/color-index.interface';
import { IconBoxReset } from '@shared/components/icons/shared-icons';

const ResetAdornment = (props: any) => {
    const { source } = props
    const { setValue } = useFormContext();
    function handleClick() {
        // if (onClick) onClick()
        setValue(source, '', { shouldValidate: true })
    }
    return (
        <InputAdornment position="start">
            <IconButton
                onClick={handleClick}
                edge="end"
            >
                <IconBoxReset />
            </IconButton>
        </InputAdornment>
    )
}


export const CoreTextInput = (props) => {
    let {
        disabled,
        source,
        validate,
        children,
        postText,
        help,
        fontSize,
        onBlur,
        onFocus,
        onChange,
        inputRef,
        resetButton = false,
        size,
        ignoreError = false,
        ...rest
    } = props;

    const { control, formState: { errors, touchedFields }, clearErrors } = useFormContext();
    let error = get(errors, source);
    if (ignoreError) {
        error = null
    }
    const touched = Boolean(get(touchedFields, source));
    const required = rest.required || rest.requiredpost
    if (size === 'mini') {
        rest = {...rest, sx: { "& .MuiInputBase-input": { fontSize: 16, height: 6, padding: "12px" } }}
    }

    return (
        <div>
            <Controller
                name={source}
                control={control}
                rules={{ validate }}
                render={({ field }) => {
                    const { ref, value, ...fieldRest } = field
                    return <>
                        <TextField
                            slotProps={{
                                formHelperText: {
                                    sx: {
                                        backgroundColor: COLOR_INDEX.BACKGROUND_COLOR,
                                        paddingTop: '2px',            // 적당한 여백
                                        margin: '0px',          // 위쪽 여백
                                    },
                                },
                                input: {
                                    endAdornment: resetButton ? <ResetAdornment source={source} /> : null,
                                }
                            }}

                            size={size}
                            {...rest}
                            {...fieldRest}
                            required={required}
                            disabled={disabled}
                            id={`${source}-input`}
                            error={!!error}
                            helperText={error ? error.message : help}
                            value={value ?? ''}
                            onChange={(ev) => {
                                if (onChange) {
                                    onChange(ev, field)
                                    return
                                }
                                field.onChange(ev)
                            }}
                            onBlur={() => {
                                field.onBlur(); // react-hook-form 으로 연결
                                if (onBlur) onBlur();
                            }}
                            onFocus={() => {
                                if (onFocus) {
                                    onFocus();
                                }
                                clearErrors(source)
                            }}
                            inputRef={inputRef}
                        >
                            {children}
                        </TextField>
                    </>
                }}
            />
        </div>
    );
};



