import {FormProvider, useForm} from "react-hook-form";
import {useRecordContext} from "@core2/index";
import {BaseSyntheticEvent, useCallback, useMemo} from "react";
import {getFormInitialValues} from "./getFormInitialValues";
import {useSaveContext} from "@core2/record/useSaveContext";
import {getSimpleValidationResolver} from "@core2/form/getSimpleValidationResolver";


export const SimpleForm = (props: any) => {
    const {
        children,
        // className,
        defaultValues,
        onSubmit,
        resolver,
        validate,
        // component: Component = DefaultComponent,
        sx,
        // toolbar = DefaultToolbar,
        ...rest
    } = props;
    const record = useRecordContext(props);
    const saveContext = useSaveContext();
    const defaultValuesIncludingRecord = useMemo(
        () => getFormInitialValues(defaultValues, record),
        // eslint-disable-next-line
        [
            // eslint-disable-next-line
            JSON.stringify({
                defaultValues:
                    typeof defaultValues === 'function'
                        ? 'function'
                        : defaultValues,
                record,
            }),
        ]
    );
    const finalResolver = resolver
        ? resolver
        : validate
        ? getSimpleValidationResolver(validate)
        : undefined;
    const form = useForm({
        // criteriaMode,
        values: defaultValuesIncludingRecord,
        // reValidateMode,
        resolver: finalResolver,
        ...rest,
    });
    // submit callbacks
    const handleSubmit = useCallback(
        async (values, event) => {
            let errors;
            // const finalValues = sanitizeEmptyValues
            //     ? sanitizeValues(values, record)
            //     : values;
            const finalValues = values
            if (onSubmit) {
                errors = await onSubmit(finalValues, event, form);
            }
            // 여기서 생성/업데이트 한다
            if (onSubmit == null && saveContext?.save) {
                errors = await saveContext.save(finalValues, event);
            }
            if (errors != null) {
                // fixme: 추가해야 함!
                // setSubmissionErrors(errors, formRef.current.setError);
            }
        },
        // [onSubmit, saveContext, sanitizeEmptyValues, record]
        [onSubmit, record]
    );
    const formHandleSubmit = useCallback(
        (event: BaseSyntheticEvent) => {
            if (!event.defaultPrevented) {
                // Prevent outer forms to receive the event
                event.stopPropagation();
                // 이 라인을 실행하먄 isSubmitting 이 true 가 된다.
                form.handleSubmit(handleSubmit)(event);
            }
            return;
        },
        [form, handleSubmit]
    );
    return (
        <FormProvider {...form}>
            <form onSubmit={formHandleSubmit}>
                    {children}
            </form>
        </FormProvider>
    );
};
