
import { Skeleton } from "@mui/material";


export function LoadingSkeleton() {
  return (
    <div>
      {/* 원하는 만큼 Skeleton 박스를 놓고, 필요한 UI만큼 로딩 플레이스홀더를 표현 */}
      <Skeleton animation="wave" style={{ width: "60%" }} />
      <Skeleton animation="wave" style={{ width: "80%" }} />
      <Skeleton animation="wave" style={{ width: "40%" }} />
      <Skeleton animation="wave" style={{ width: "90%" }} />
    </div>
  );
}