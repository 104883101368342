


export enum CONTENT_TYPE_NAME_INDEX {
    YOUTUBE_SEARCH = "Youtube_Search",
    YOUTUBE_VIDEOS = "Youtube_Videos",
    YOUTUBE_STAT = "Youtube_Stat",
    NAVER_BLOG = "Naver_Blog",
    NAVER_CAFE = "Naver_Cafe",
    NAVER_KIN = "Naver_Kin",
    NAVER_NEWS = "Naver_News",
    NAVER_SHOPPING = "Naver_Shopping",
    BLOG_ANALYSIS = "Blog_Analysis",
    BLOG_CREATION = "Blog_Creation",
    NAVER_SEARCH_API = "NAVER_SEARCH_API",
    META_AD_ANALYSIS = "META_AD",
}