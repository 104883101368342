
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';

export const SecondaryButton = (props: any) => {
    const { children, redirect, onClick, variant="outlined", color="secondary", 
        submitLoading = false, 
        submitDisabled = true, 
        ...rest } = props
    const formContext = useFormContext();
    const navigate = useNavigate();
    let isSubmitting = false
    let disabled = false
    if (formContext) {
        isSubmitting = formContext.formState?.isSubmitting
        if (submitDisabled) {
            disabled = isSubmitting
        }
        if (!submitLoading) {
            isSubmitting = false
        }
    }
    function handleClick() {
        if (redirect) {
            navigate(redirect);
        }
        if (onClick) {
            onClick();
        }
    }
    return <LoadingButton
        variant={variant}
        loading={isSubmitting}
        color={color}
        onClick={handleClick}
        disabled={disabled}
        sx={{
            color: 'black',
        }}
        {...rest}
    >
        {children}
    </LoadingButton>
}