import { simpleApiQuery } from "@providers/simpleApiQuery"
import { useQuery } from "@tanstack/react-query"

  
export const useSimpleApiQuery = (path: string, params: any, options: any={enabled: true}) => {
    const result: any = useQuery({
        enabled: options.enabled,
        queryKey: ['useSimpleApiQuery', path, params],
        queryFn: () => {
            return simpleApiQuery('get', path, params)
            .then(r => {
                return r;
            })
        },
        refetchOnWindowFocus: false,
    })
    return result
}