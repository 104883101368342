/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 11. 19.
 */

import React from 'react';
import { Bubble } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BubbleChartHandle } from './bubble-chart-handle';
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
    ChartData
} from 'chart.js';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend, ChartDataLabels);

interface BubbleChartProps {
    handle: BubbleChartHandle;
    width?: any;
    height?: any;
}


/**
 * 버블차트용 r값들을 적절히 스케일링하는 함수
 * @param {number[]} rValues - 실제 데이터에 기반한 r 값 배열
 * @param {Object} [options] - 스케일 옵션
 * @param {number} [options.minPx=5]  - 스케일 후 최소 픽셀 반지름
 * @param {number} [options.maxPx=15] - 스케일 후 최대 픽셀 반지름
 * @param {boolean} [options.useSqrt=false] - true이면 제곱근 스케일링 적용
 * @returns {number[]} 스케일링된 픽셀 반지름 배열
 */
function scaleBubbleRadius(rValues: number[], options: any = {}) {
    // 옵션 디폴트값 설정
    const {
      minPx = 5,
      maxPx = 15,
      useSqrt = false,
    } = options;
  
    // rValues 중 최소/최대 찾기
    const rMin = Math.min(...rValues);
    const rMax = Math.max(...rValues);
  
    // 모든 값이 동일하다면, 중간값(= (minPx+maxPx)/2)으로 고정
    if (rMin === rMax) {
      const middle = (minPx + maxPx) / 2;
      return rValues.map(() => middle);
    }
  
    // rValues 를 [rMin, rMax] -> [0, 1]로 정규화 후, minPx~maxPx 범위로 매핑
    return rValues.map((val) => {
      let normalized = (val - rMin) / (rMax - rMin); // 0~1 범위
  
      // 옵션에 따라 제곱근 스케일링 적용
      if (useSqrt) {
        normalized = Math.sqrt(normalized);
      }
  
      // 최종적으로 minPx ~ maxPx 사이로 매핑
      return minPx + normalized * (maxPx - minPx);
    });
  }

export const BubbleChart: React.FC<BubbleChartProps> = ({
                                                            handle,
                                                            width = "100%",
                                                            height = "100%"
                                                        }) => {
    const data = handle.getDatasets();
    const options = handle.getOptions();
    const datas = data.datasets[0].data.map(item => ({
        x: item.x,
        y: item.y,
        r: item.r,        // 크기 조정
        label: item.label
    }))

    // const newRadius = scaleBubbleRadius(datas.map(t => t.r))
    // datas.forEach((t, i) => {
    //     t.r = newRadius[i]
    // })

    const chartData: ChartData<'bubble'> = {
        datasets: [{
            label: data.datasets[0].label,
            // data: data.datasets[0].data.map(item => ({
            //     x: item.x,
            //     y: item.y,
            //     r: item.r*10,        // 크기 조정
            //     label: item.label
            // })),
            data: datas,
            backgroundColor: data.datasets[0].backgroundColor
        }]
    };

    return (
        <div style={{ width, height }}>
            <Bubble
                data={chartData}
                // options={{
                    // ...options,
                    // maintainAspectRatio: false,
                    // responsive: true,
                    // layout: {
                    //     padding: 0
                    // }
                // }}
            />
        </div>
    );
};
