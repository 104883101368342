import { Paper } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";



export const ResponsivePaper = ({ children, rightAlign = false }) => {
    const paperRef = useRef(null);
    const [paperStyles, setPaperStyles] = useState({});

    useEffect(() => {
        const adjustPaperPosition = () => {
            if (!paperRef.current) return;

            const parentRect = paperRef.current.parentElement.getBoundingClientRect();
            const paperRect = paperRef.current.getBoundingClientRect();

            const overflowRight = paperRect.right - window.innerWidth; // 넘친 정도 계산
            const adjustedRight = overflowRight > 0 ? overflowRight : 0; // 초과분만큼 조정

            setPaperStyles({
                position: "absolute",
                top: "100%",
                left: rightAlign ? "auto" : 0, // 기본적으로 left를 auto로 설정
                right: rightAlign ? adjustedRight + "px" : "auto", // 오른쪽 맞춤
                mt: 1,
                p: 2,
                zIndex: 10,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "600px", // 페이퍼의 고정 너비
            });
        };

        // 페이지 로드와 리사이즈 이벤트에 반응
        adjustPaperPosition();
        window.addEventListener("resize", adjustPaperPosition);

        return () => {
            window.removeEventListener("resize", adjustPaperPosition);
        };
    }, []);

    return (
        <Paper ref={paperRef} elevation={3} sx={paperStyles}>
            {children}
        </Paper>
    );
};