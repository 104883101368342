/**
 * Author : Bruce.Decent, the Sunbed Nomad
 * Date: 2021/11/12
 */

import {Link, useNavigate} from 'react-router-dom'
import {Box, Stack, Typography} from "@mui/material";
import { SecondaryButton } from '@components/custom-ui';


export const Error404 = props => {
    const navigate = useNavigate()
    function goBack() {
        // history.goBack()
        navigate(-1)
    }
    return (
        <>
        <Stack direction="column" spacing={2} sx={{ marginTop: "40px" }}>
            <Typography variant="h3" align="center">죄송합니다. 현재 찾을 수 없는 페이지를 요청하셨습니다</Typography>
            <Typography variant="h5" align="center">존재하지 않는 주소를 입력하셨거나,</Typography>
            <Typography variant="h5" align="center">요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다</Typography>
            <Typography variant="h5" align="center">문의사항이 있으시면 언제든 고객센터를 통해 문의해 주시기 바랍니다</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "40px" }}>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ marginTop: "40px" }}>
                <Link to={'/'}>메인으로</Link>
                <SecondaryButton onClick={goBack}>이전페이지</SecondaryButton>
            </Stack>
            </Box>
            </Stack>
        </>
    )
}
