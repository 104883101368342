
// import { XLS_TABLE, XlsSheet } from "@shared/components/xls";

import { XlsSheet, XLS_TABLE } from "../components/xls";


export interface XlsResourceSheetColumn {
    name: string;
    width?: number;
    // horizontal: string;
    // vertical: string;
}

export class XlsResourceSheet extends XlsSheet {
    title: string = "none"
    create(resource: string) {
        super.create(resource)
        this.title = resource
    }

    /**
     * 레코드 배열을 받아서, 각 필드별 문자열 길이의 최대값을 구하는 함수
     * @param records 레코드의 배열
     * @returns { [field: string]: number } 각 필드별 최대 문자열 길이
     */
    getMaxStringLengthByField(records: any[]): { [field: string]: number } {
        const maxLengths: { [field: string]: number } = {};
      
        records.forEach((record) => {
          for (const field in record) {
            // 필드 이름의 길이를 먼저 고려
            maxLengths[field] = Math.max(maxLengths[field] ?? 0, field.length);
      
            // 값이 null 또는 undefined인 경우 대비
            const value = record[field] ?? '';
            const length = String(value).length;
      
            // 현재 필드의 기존 최대값보다 크면 업데이트
            maxLengths[field] = Math.max(maxLengths[field], length);
          }
        });
      
        return maxLengths;
      }

    setTable(data: any[], selectedFields?: string[]) {

        const maxLengths = this.getMaxStringLengthByField(data)
        const headers = []
        // 데이터 항목의 모든 prop 를 헤더로 전환
        let col = 1
        for (const field in data[0]) {
            headers.push({
                text: field,
                // width: maxLengths[field]
                width: 1,
                height: 1
            })
            this.setColumnWidth(col, maxLengths[field] + 2)
            col ++
        }

        let row = 1;
        /////////////////////////////////////////////////////////////////////////////////
        const table: XLS_TABLE = {
            layout: {
                header: [1, 1],
                data: [1, 1],
            },
            title: {
                text: this.title,
            },
            headers,
            data: data.map(item => headers.map(column => (item[column.text] ?? "")))
        }
        row = this.setVerticalTable(row, 1, table, { horizontal: 'center', merge: false })
    }
}
