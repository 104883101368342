import { CrawlContentContextProvider } from "@shared/components/context";
import { InquiryRecordData, NAVER_VIRAL_CHANNELS, YOUTUBE_VIRAL_CHANNELS } from "@shared/interfaces";
import { CONTENT_TYPE_NAME_INDEX } from "@shared/interfaces";
import { BrandContentContainer, TabDataItem } from "../common/brand-content-container";
import { useLocalDataContext } from "@shared/hooks/useLocalDataContext";


const naverContentTabData: TabDataItem[] = [
    { label: '블로그', value: CONTENT_TYPE_NAME_INDEX.NAVER_BLOG },
    { label: '카페', value: CONTENT_TYPE_NAME_INDEX.NAVER_CAFE },
    { label: '지식IN', value: CONTENT_TYPE_NAME_INDEX.NAVER_KIN },
    { label: '뉴스', value: CONTENT_TYPE_NAME_INDEX.NAVER_NEWS },
    { label: '유튜브', value: CONTENT_TYPE_NAME_INDEX.YOUTUBE_VIDEOS },
];


export const ViralContentListContainer = () => {
    const inquiryRecord: InquiryRecordData = useLocalDataContext()
    return <>
        <CrawlContentContextProvider
            value={{
                inquiry: inquiryRecord,
                entities: ["NaverApiData", "YoutubeApiData"],
                channels: [...NAVER_VIRAL_CHANNELS, ...YOUTUBE_VIRAL_CHANNELS],
            }}>
            <BrandContentContainer tabData={naverContentTabData} section="content"/>
        </CrawlContentContextProvider>
    </>
}