import { BlogAnalysisContent } from "./blog-analysis.interface";
import { BlogCreationContent } from "./blog-generation.interface";
import { CONTENT_TYPE_NAME_INDEX } from "./content-index";
import { ContentBuildType } from "./content.interface";
import { NaverBlogContent } from "./naver-blog.interface";
import { NaverCafeContent } from "./naver-cafe.interface";
import { NaverKinContent } from "./naver-kin.interface";
import { NaverNewsContent } from "./naver-news.interface";
import { NaverSearchApiContent } from "./naver-search.interface";
import { NaverShoppingContent } from "./naver-shopping.interface";
import { YouTubeContent } from "./youtube.interface";




export const _ContentCategories: ContentBuildType[] = [
    {
        "name": CONTENT_TYPE_NAME_INDEX.YOUTUBE_SEARCH,
        c: null,
    },
    {
        // "id": 2,
        "name": CONTENT_TYPE_NAME_INDEX.YOUTUBE_VIDEOS,
        // c: null,
        c: YouTubeContent,
    },
    {
        // "id": 3,
        "name": CONTENT_TYPE_NAME_INDEX.YOUTUBE_STAT,
        c: NaverBlogContent,
    },
    {
        // "id": 4,
        "name": CONTENT_TYPE_NAME_INDEX.NAVER_BLOG,
        c: NaverBlogContent,
    },
    {
        // "id": 5,
        "name": CONTENT_TYPE_NAME_INDEX.NAVER_CAFE,
        c: NaverCafeContent,
    },
    {
        // "id": 6,
        "name": CONTENT_TYPE_NAME_INDEX.NAVER_KIN,
        c: NaverKinContent,
    },
    {
        // "id": 7,
        "name": CONTENT_TYPE_NAME_INDEX.NAVER_NEWS,
        c: NaverNewsContent,
    },
    {
        // "id": 8,
        "name": CONTENT_TYPE_NAME_INDEX.NAVER_SHOPPING,
        c: NaverShoppingContent,
    },
    {
        "name": CONTENT_TYPE_NAME_INDEX.BLOG_ANALYSIS,
        c: BlogAnalysisContent,
    },
    {
        "name": CONTENT_TYPE_NAME_INDEX.BLOG_CREATION,
        c: BlogCreationContent,
    },
    {
        "name": CONTENT_TYPE_NAME_INDEX.NAVER_SEARCH_API,
        c: NaverSearchApiContent,
    }
]
