/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024. 6. 25.
 */

class Person {
    name: any
    age: any
    city: any
    constructor(name, age, city) {
        this.name = name;
        this.age = age;
        this.city = city;
    }
}

export interface QUERY_GROUP<T> {
    subList: T[];           // sub-list 필드
    [key: string]: any;     // 임의의 다른 필드
}




export class ListSQL {

    transformCondition(conditions: string) {
        const regex = /(["'])(?:(?=(\\?))\2.)*?\1|([a-zA-Z_][a-zA-Z0-9_]*)/g;
        return conditions.replace(regex, (match) => {
            if (match.startsWith('"') || match.startsWith("'")) {
                return match;
            }
            return `item.${match}`;
        });
    }

    parseConditions(conditions: string) {
        const regex = /(?:\s+AND\s+)|(?:\s+OR\s+)/i;
        const tokens = conditions.split(regex).map(cond => cond.trim());
        const operators = conditions.match(regex);

        return tokens.map((condition, index) => {
            const operator = operators ? operators[index - 1] : null;
            return { condition, operator };
        });
    }

    evaluateCondition(item: any, condition: string) {
        const condStr = this.transformCondition(condition);
        const conditionFunc = new Function('item', `return ${condStr}`);
        return conditionFunc(item);
    }

    groupBy(array, keys) {
        return array.reduce((result: any, item: any) => {
            const groupKey = keys.map(key => item[key]).join('|');
            if (!result[groupKey]) {
                result[groupKey] = [];
            }
            result[groupKey].push(item);
            return result;
        }, {});
    }

    getQueryCond(query: string) {
        // ORDER BY 구문을 먼저 잘라냄
        let orderByMatch = query.match(/ORDER BY (.+)/i);
        let orderBy = orderByMatch ? orderByMatch[1].split(',').map(f => f.trim()) : [];

        // ORDER BY 구문을 잘라낸 나머지 쿼리
        if (orderByMatch) {
            query = query.slice(0, orderByMatch.index).trim();
        }

        // GROUP BY 구문을 잘라냄
        let groupByMatch = query.match(/GROUP BY (.+)/i);
        let groupBy = groupByMatch ? groupByMatch[1].split(',').map(f => f.trim()) : [];

        // GROUP BY 구문을 잘라낸 나머지 쿼리
        if (groupByMatch) {
            query = query.slice(0, groupByMatch.index).trim();
        }

        // WHERE 구문을 먼저 처리
        const whereMatch = query.match(/WHERE (.+)/i);
        let conditions = whereMatch ? whereMatch[1] : null;
        if (whereMatch) {
            query = query.slice(0, whereMatch.index).trim();
        }

        // SELECT 구문 처리
        const selectMatch = query.match(/SELECT (.+) FROM/i);
        let fields = selectMatch ? selectMatch[1].split(',').map(f => f.trim()) : [];

        return {
            orderBy,
            groupBy,
            conditions,
            fields
        }
    }

    // SQL 문장을 해석하고 처리하는 함수들
    sqlQuery(data: any[], query: string, flat: boolean =true) {
        if (!data) return []
        // ORDER BY 구문을 먼저 잘라냄
        // let orderByMatch = query.match(/ORDER BY (.+)/i);
        // let orderBy = orderByMatch ? orderByMatch[1].split(',').map(f => f.trim()) : [];
        //
        // // ORDER BY 구문을 잘라낸 나머지 쿼리
        // if (orderByMatch) {
        //     query = query.slice(0, orderByMatch.index).trim();
        // }
        //
        // // GROUP BY 구문을 잘라냄
        // let groupByMatch = query.match(/GROUP BY (.+)/i);
        // let groupBy = groupByMatch ? groupByMatch[1].split(',').map(f => f.trim()) : [];
        //
        // // GROUP BY 구문을 잘라낸 나머지 쿼리
        // if (groupByMatch) {
        //     query = query.slice(0, groupByMatch.index).trim();
        // }
        //
        // // WHERE 구문을 먼저 처리
        // const whereMatch = query.match(/WHERE (.+)/i);
        // let conditions = whereMatch ? whereMatch[1] : null;
        // if (whereMatch) {
        //     query = query.slice(0, whereMatch.index).trim();
        // }
        //
        // // SELECT 구문 처리
        // const selectMatch = query.match(/SELECT (.+) FROM/i);
        // let fields = selectMatch ? selectMatch[1].split(',').map(f => f.trim()) : [];
        const {orderBy, groupBy, conditions, fields} = this.getQueryCond(query)


        // WHERE 조건 처리
        if (conditions) {
            const parsedConditions = this.parseConditions(conditions);
            data = data.filter(item => {
                let result = this.evaluateCondition(item, parsedConditions[0].condition);
                for (let i = 1; i < parsedConditions.length; i++) {
                    const { condition, operator } = parsedConditions[i];
                    const nextConditionResult = this.evaluateCondition(item, condition);
                    if (operator.trim().toUpperCase() === 'AND') {
                        result = result && nextConditionResult;
                    } else if (operator.trim().toUpperCase() === 'OR') {
                        result = result || nextConditionResult;
                    }
                }
                return result;
            });
        }

        // GROUP BY 처리
        // if (groupBy.length) {
        //     data = Object.values(this.groupBy(data, groupBy));
        // }
        // GROUP BY 처리
        let groupedData;
        if (groupBy.length) {
            groupedData = Object.values(this.groupBy(data, groupBy));
        } else {
            groupedData = [data];
        }


        // SELECT 필드 처리
        if (fields.length && fields[0] !== '*') {
            groupedData = groupedData.map((group: any) => group.map((item: any) => {
                let newItem = {};
                fields.forEach(field => {
                    if (item.hasOwnProperty(field)) {
                        newItem[field] = item[field];
                    }
                });
                return newItem;
            }));
        }
        // // ORDER BY 처리
        // if (orderBy.length) {
        //     data = data.map(group => {
        //         return group.sort((a, b) => {
        //             for (let field of orderBy) {
        //                 let [fieldName, order] = field.split(' ').map(f => f.trim());
        //                 order = order ? order.toUpperCase() : 'ASC';
        //                 if (a[fieldName] < b[fieldName]) return order === 'ASC' ? -1 : 1;
        //                 if (a[fieldName] > b[fieldName]) return order === 'ASC' ? 1 : -1;
        //             }
        //             return 0;
        //         });
        //     });
        // }

        // ORDER BY 처리
        if (orderBy.length) {
            groupedData = groupedData.map((group: any) => {
                return group.sort((a, b) => {
                    for (let field of orderBy) {
                        let [fieldName, order] = field.split(' ').map(f => f.trim());
                        order = order ? order.toUpperCase() : 'ASC';
                        if (a[fieldName] < b[fieldName]) return order === 'ASC' ? -1 : 1;
                        if (a[fieldName] > b[fieldName]) return order === 'ASC' ? 1 : -1;
                    }
                    return 0;
                });
            });
        }

        return flat ? groupedData.flat() : groupedData
    }

    sqlQueryToGroup<T>(data: any[], query: string, setFields: string[] = []): QUERY_GROUP<T>[]  {
        const list = this.sqlQuery(data, query, false)

        const conds = this.getQueryCond(query)

        // // ORDER BY 구문을 먼저 잘라냄
        // let orderByMatch = query.match(/ORDER BY (.+)/i);
        // let orderBy = orderByMatch ? orderByMatch[1].split(',').map(f => f.trim()) : [];
        //
        // // ORDER BY 구문을 잘라낸 나머지 쿼리
        // if (orderByMatch) {
        //     query = query.slice(0, orderByMatch.index).trim();
        // }
        //
        // let groupByMatch = query.match(/GROUP BY (.+)/i);
        // let groupBy = groupByMatch ? groupByMatch[1].split(',').map(f => f.trim()) : [];

        return list.map(item => {
            const p = {
                subList: item as T[]
            }
            for (const group of conds.groupBy) {
                p[group] = item[0][group];
            }
            for (const field of setFields) {
                p[field] = item[0][field];
            }
            return p;
        })
    }



    static example() {
        const lq = new ListSQL()
        // 예제 데이터
        const people = [
            new Person('Alice', 30, 'New York'),
            new Person('Bob', 25, 'Los Angeles'),
            new Person('Charlie', 35, 'Chicago'),
            new Person('Dave', 40, 'New York'),
            new Person('Eve', 25, 'Los Angeles')
        ];

        const query0 = "SELECT name, age WHERE age > 25 ORDER BY age ASC";
        const result0 = lq.sqlQuery(people, query0);
        console.log(result0);

        // 예제 사용
        const query1 = "SELECT name, age WHERE age > 25 GROUP BY city ORDER BY age DESC";
        const result1 = lq.sqlQuery(people, query1);
        console.log(result1);

        const query2 = "SELECT * WHERE city === 'New York' GROUP BY city ORDER BY name ASC";
        const result2 = lq.sqlQuery(people, query2);
        console.log(result2);

        const query3 = `SELECT * WHERE city === 'New York' AND name === 'Alice' GROUP BY city ORDER BY age DESC`;
        const result3 = lq.sqlQuery(people, query3);
        console.log(result3);

        const query4 = `SELECT * WHERE age >= 25 GROUP BY city, age ORDER BY city ASC, age DESC`;
        const result4 = lq.sqlQuery(people, query4);
        console.log(result4);


        const result5 = lq.sqlQueryToGroup<Person>(people, "Select * From Group By city");
    }
}


