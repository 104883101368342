import {useContext, useMemo} from "react";
// import {ListPaginationContext} from "./ListPaginationContext";
import defaults from "lodash-es/defaults";
import {ListPaginationContext} from "@core2/list";

const extractListPaginationContextProps = ({
                                               isLoading,
                                               page,
                                               perPage,
                                               setPage,
                                               setPerPage,
                                               hasPreviousPage,
                                               hasNextPage,
                                               total,
                                               resource,
                                           }) => ({
    isLoading,
    page,
    perPage,
    setPage,
    setPerPage,
    hasPreviousPage,
    hasNextPage,
    total,
    resource,
});

export const useListPaginationContext = (
    props?: any
): any => {
    const context = useContext(ListPaginationContext);
    return useMemo(
        () =>
            defaults(
                {},
                props != null ? extractListPaginationContextProps(props) : {},
                context
            ),
        [context, props]
    );
};
