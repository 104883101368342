import React, { useEffect, useMemo, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// chart.js에 필요한 컴포넌트 등록
ChartJS.register(CategoryScale, LinearScale, PointElement, Tooltip, Legend, ChartDataLabels);

interface BubbleChart2Props {
    data: any[]
    width?: number
    height?: number|string
    showLegend?: boolean
    colorMap?: any[]
}

/**
 * @param {Array} data - [{ name: string, value: number }, ...]
 * @param {number} width - 차트 너비(px)
 * @param {number} height - 차트 높이(px)
 * @param {boolean} showLegend - legend 표시 여부
 */
export const BubbleChart2 = ({ data, width = 800, height = 600, showLegend = true, colorMap = []}: BubbleChart2Props) => {
    // ------------------------------
    // 1) 데이터 스케일링 함수 정의
    // ------------------------------
    /**
     * 범위 [minValue, maxValue] -> [30, 100] 로 스케일링
     * (만약 데이터가 전부 동일하면 중간값(65)으로 통일)
     */
    const scaleValue = (val, minV, maxV) => {
        const targetMin = 50;
        const targetMax = 150;

        if (minV === maxV) {
            // 모든 값이 동일할 때는 중간값으로
            return (targetMin + targetMax) / 2;
        }

        // 선형 스케일링
        const ratio = (val - minV) / (maxV - minV);
        return ratio * (targetMax - targetMin) + targetMin;
    };

    // -------------------------------------
    // 2) 스케일링된 버블 데이터 준비 (x,y,r)
    // -------------------------------------
    // bubblePoints를 빈 배열로 초기화 (map 사용 시 안전)
    const [bubblePoints, setBubblePoints] = useState([]);

    useEffect(() => {
        // data가 없거나, 배열이 아니거나, 비어있으면 setBubblePoints([])로 초기화 후 반환
        if (!data || !Array.isArray(data) || data.length === 0) {
            setBubblePoints([]);
            return;
        }

        // 색상 팔레트
        const colors = [
            '#EF4444', '#F59E0B', '#10B981', '#3B82F6', '#8B5CF6',
            '#EC4899', '#F97316', '#84CC16', '#14B8A6', '#A855F7'
        ];

        const values = data.map((d) => d.value);
        const minValue = Math.min(...values);
        const maxValue = Math.max(...values);
        // 임시로 보관할 버블 배열
        const newBubbles = [];
        //  원 데이터를 크기 기준으로 정렬
        const sorted = data.sort((a, b) => b.value - a.value);
        // 원형 데이터 위치 획득
        const spiralPositions = generateSpiralPositions(data.length);
        sorted.forEach((d, index) => {
            // 값 스케일링
            const scaled = scaleValue(d.value, minValue, maxValue);
            // chart.js에선 r이 픽셀단위 반지름
            const radius = scaled / 2;

            const position = spiralPositions[index];
            newBubbles.push({
                x: position.x,
                y: position.y,
                r: radius,
                labelName: d.name,
                originalValue: d.value,
                scaledValue: scaled,
                color: d.color || colors[index % colors.length]
            });
        });

        const finalPositions = forceSimulation(
            newBubbles,
            12,             // 물리 시뮬레이션 반복 횟수
            0.02,          // 반발력 세기(계수), 커질수록 강하게 밀어냄
            0.4            // 속도 감쇠 계수(0~1). 0이면 즉시 속도가 0이 되고, 1이면 감쇠가 없음
        );


        setBubblePoints(finalPositions);
    }, [data]);


    /**
 * 스파이럴 형태로 n개의 (x,y) 좌표를 생성한다.
 * @param {number} n - 생성할 항목(좌표) 개수
 * @param {number} centerX - 스파이럴의 중심 X 좌표 (기본값: 50)
 * @param {number} centerY - 스파이럴의 중심 Y 좌표 (기본값: 50)
 * @param {number} angleStep - 각도 증가량 (기본값: 0.7)
 * @param {number} distanceStep - 거리 증가량 (기본값: 1.0)
 * @returns {Array} positions - 예: [{ x: 52.3, y: 49.1 }, ...] 형태
 */
    function generateSpiralPositions(
        n,
        centerX = 50,
        centerY = 50,
        angleStep = 0.7,
        distanceStep = 1.0
    ) {
        const positions = [];
        let angle = 0;
        let distance = 0;

        for (let i = 0; i < n; i++) {
            // 스파이럴을 따라 각 항목의 좌표 계산
            const x = centerX + distance * Math.cos(angle);
            const y = centerY + distance * Math.sin(angle);

            positions.push({ x, y });

            // 다음 항목을 위한 각도 & 거리 증가
            angle += angleStep + Math.random() * 0.2; // 랜덤 요소 추가
            distance += distanceStep + Math.random() * 0.5; // 랜덤하게 조금씩 더 멀어짐
        }
        return positions;
    }

    // ---------------------------------
    // 3) Chart.js에 전달할 설정값 구성
    // ---------------------------------
    // bubblePoints가 없거나 비어있다면 datasets도 빈 값으로 반환
    const chartData = useMemo(() => {
        if (!bubblePoints || bubblePoints.length === 0) {
            return { datasets: [] };
        }

        

        return {
            datasets: [
                {
                    label: 'My Bubble Chart',
                    data: bubblePoints.map((bp) => ({
                        x: bp.x,
                        y: bp.y,
                        r: bp.r,
                        labelName: bp.labelName,
                        originalValue: bp.originalValue,
                        scaledValue: bp.scaledValue,
                    })),
                    // 배경색은 데이터 수에 따라 순환
                    // backgroundColor: bubblePoints.map((_, i) => colors[i % colors.length]),
                    backgroundColor: bubblePoints.map((_, i) => _.color),
                },
            ],
        };
    }, [bubblePoints]);


    /**
 * circles 배열(원들): 
 * [
 *   { x: number, y: number, r: number },
 *   { x: number, y: number, r: number },
 *   ...
 * ]
 * 
 * 0번 원: 고정 (움직이지 않음)
 * 나머지 원들: 서로 겹치면 반발력을 통해 멀어진다 (물리 시뮬레이션).
 *
 * @param {Array} circles   - 원의 정보 (0번은 anchor)
 * @param {number} iterations - 물리 시뮬레이션 반복 횟수
 * @param {number} stiffness  - 반발력 세기(계수), 커질수록 강하게 밀어냄
 * @param {number} damping    - 속도 감쇠 계수(0~1). 0이면 즉시 속도가 0이 되고, 
 *                              1이면 감쇠가 없음
 * @returns {Array} circles   - 시뮬레이션 후의 원 배열(최종 위치)
 */
    function forceSimulation(
        circles,
        iterations = 10,
        stiffness = 0.01,
        damping = 0.9
    ) {
        // 초기 속도, 힘을 저장할 수 있도록 확장
        // (원본 circles 배열에 vx, vy, fx, fy 속성을 추가)
        circles = circles.map((c, i) => ({
            ...c,
            vx: 0, // 속도 x
            vy: 0, // 속도 y
            fx: 0, // 힘 x
            fy: 0, // 힘 y
            pinned: i === 0, // 0번 원은 고정
        }));

        for (let iter = 0; iter < iterations; iter++) {
            // 1) 모든 힘을 0으로 초기화
            for (const circle of circles) {
                circle.fx = 0;
                circle.fy = 0;
            }

            // 2) 모든 원 쌍에 대해 반발력 계산
            for (let i = 0; i < circles.length; i++) {
                for (let j = i + 1; j < circles.length; j++) {
                    applyRepulsion(circles[i], circles[j], stiffness);
                }
            }

            // 3) 힘 -> 속도 -> 위치 업데이트
            for (const circle of circles) {
                // 0번 원은 pinned (고정)
                if (circle.pinned) {
                    circle.vx = 0;
                    circle.vy = 0;
                    continue;
                }

                // 가속도 a = F / m (여기서는 질량 m=1 가정)
                // 속도 업데이트: v += a
                circle.vx += circle.fx;
                circle.vy += circle.fy;

                // 속도 감쇠(damping)
                circle.vx *= damping;
                circle.vy *= damping;

                // 위치 업데이트: x += v
                circle.x += circle.vx;
                circle.y += circle.vy;
            }
        }

        // 불필요한 vx,vy,fx,fy,pinned는 제거하거나, 남겨둬도 됨
        // return circles.map(({ x, y, r }) => ({ x, y, r }));
        return circles;
    }

    /**
     * 두 원 사이의 **반발력(repulsion)**을 계산하여
     * 서로 밀어내도록 fx, fy를 업데이트한다.
     * 
     * - 거리가 (r1 + r2)보다 작으면, 거리만큼 겹쳐 있는 상태
     * - 이를 해소하기 위해, 거리 차이에 비례한 반발력을 계산하여
     *   두 원에 반대방향으로 적용
     * - stiffness(탄성계수)가 클수록 강하게 밀어낸다.
     */
    function applyRepulsion(c1, c2, stiffness) {
        const dx = c2.x - c1.x;
        const dy = c2.y - c1.y;

        // 거리
        let dist = Math.sqrt(dx * dx + dy * dy);
        const minDist = c1.r + c2.r;

        // 겹치지 않으면 반발력 필요 없음
        if (dist >= minDist) {
            return;
        }

        // dist가 0이면(동일좌표) 아주 작은 값으로 보정
        if (dist === 0) {
            dist = 0.001;
        }

        // 겹치는 정도
        const overlap = minDist - dist;

        // 반발력 크기 = overlap * stiffness
        // (거리가 겹친 만큼, stiffness 계수로 결정)
        const force = overlap * stiffness;

        // 방향 벡터 = (dx/dist, dy/dist)
        const fx = (dx / dist) * force;
        const fy = (dy / dist) * force;

        // c1은 -fx, -fy,  c2는 +fx, +fy
        // (즉, 서로 반대 방향으로 밀어낸다)
        if (!c1.pinned) {
            c1.fx -= fx;
            c1.fy -= fy;
        }
        if (!c2.pinned) {
            c2.fx += fx;
            c2.fy += fy;
        }
    }

    // tooltip에서 항목명, 원본값, 스케일된 값 표시
    const chartOptions = {
        layout: {
            autoPadding: false,
            padding: 0,
        },
        responsive: true,
        // devicePixelRatio: 1,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: showLegend,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        // context.raw에는 { x, y, r, labelName, originalValue, scaledValue } 등이 들어 있음
                        const d = context.raw;
                        return [
                            // `이름: ${d.labelName}`,
                            // `원본 값: ${d.originalValue}`,
                            // `스케일 값: ${Math.round(d.scaledValue)}`,
                            `${d.labelName}`,
                            `검색량: ${d.originalValue.toLocaleString()}`,
                        ];
                    },
                },
            },
            datalabels: {
                // 버블 중앙에 항목명 표시
                anchor: 'center',
                align: 'center',
                color: '#fff',
                font: {
                    weight: 'bold',
                },
                formatter: function (value, context) {
                    // chartData.datasets[0].data[인덱스].labelName 참조
                    return context.dataset.data[context.dataIndex].labelName;
                },
            },
        },
        scales: {
            x: {
                display: false, // x축 표시 안 함
                // offset: false,
                min: 0,
                max: 100,
            },
            y: {
                display: false, // y축 표시 안 함
                // offset: false,
                min: 0,
                max: 100,
            },
        },
    };

    return (
        <div style={{ width, height }}>
            {/*/@ts-ignore */}
            <Bubble data={chartData} options={chartOptions} />
        </div>
    );
};