

import React from "react";
import { Box } from "@mui/material";
import { keyframes } from "@mui/material/styles";

// 점 깜빡이는 애니메이션
const blink = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

interface EllipsisLoaderProps {
  size?: number
}

// 말줄임 효과 컴포넌트
export const EllipsisLoader = (props: EllipsisLoaderProps) => {
    const { size = 8 } = props
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 0.5, // 점 간격
      }}
    >
      {[0, 1, 2].map((index) => (
        <Box
          key={index}
          sx={{
            width: size,
            height: size,
            borderRadius: "50%",
            backgroundColor: "black", // 점 색상
            animation: `${blink} 1.5s infinite`,
            animationDelay: `${index * 0.3}s`, // 각 점이 순차적으로 깜빡이도록 딜레이 추가
          }}
        />
      ))}
    </Box>
  );
};
