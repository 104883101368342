/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2023/11/05
 */
import {PaginationPayload} from "@core2/index";
import {useCallback, useEffect, useReducer, useRef} from "react";


export interface PaginationHookResult {
    page: number;
    perPage: number;
    pagination: PaginationPayload;
    setPage: (page: number) => void;
    setPerPage: (perPage: number) => void;
    setPagination: (pagination: PaginationPayload) => void;
}

const paginationReducer = (
    prevState: PaginationPayload,
    nextState: Partial<PaginationPayload>
): PaginationPayload => {
    return {
        ...prevState,
        ...nextState,
    };
};


const defaultPagination = {
    page: 1,
    perPage: 25,
};

export const usePaginationState = (
    initialPagination: { perPage?: number; page?: number } = {}
): PaginationHookResult => {
    const [pagination, setPagination] = useReducer(paginationReducer, {
        ...defaultPagination,
        ...initialPagination,
    });
    const isFirstRender = useRef(true);

    const setPerPage = useCallback(
        perPage => setPagination({ perPage, page: 1 }),
        []
    );
    const setPage = useCallback(page => setPagination({ page }), []);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        setPerPage(initialPagination.perPage || 25);
    }, [initialPagination.perPage, setPerPage]);

    return {
        page: pagination.page,
        perPage: pagination.perPage,
        pagination,
        setPage,
        setPerPage,
        setPagination,
    };
};
