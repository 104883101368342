// useLocalDataContext.js

import React, { createContext, useContext, useState } from 'react';

/**
 * 1) Context 생성
 */
export const LocalDataContext = createContext(null);

/**
 * 2) Provider 컴포넌트
 *    - 전역/상위 컴포넌트에서 <LocalDataProvider> 로 감싸고,
 *      그 하위 트리에서는 useLocalDataContext()로 data를 접근 가능
 */
export const LocalDataProvider = ({ children, data }) => {
    // 필요한 state를 정의
    return (
        <LocalDataContext.Provider value={data}>
            {children}
        </LocalDataContext.Provider>
    );
};

/**
 * 3) 커스텀 훅
 *    - Context 내부 값(data, setData 등)에 쉽게 접근할 수 있도록 함
 */
export const useLocalDataContext = () => {
    const context = useContext(LocalDataContext);
    if (!context) {
        throw new Error('useLocalDataContext must be used within a <LocalDataProvider>.');
    }
    return context;
};

/* 
-----------------------------------------------------------------
사용 예시:

import React from 'react';
import { LocalDataProvider, useLocalDataContext } from './useLocalDataContext';

function ChildComponent() {
    const { data, setData } = useLocalDataContext();
    return (
        <div>
            <p>Current Data: {data}</p>
            <button onClick={() => setData('Hello World')}>Change Data</button>
        </div>
    );
}

export default function App() {
    return (
        <LocalDataProvider>
            <ChildComponent />
        </LocalDataProvider>
    );
}
-----------------------------------------------------------------
*/