

export interface ICustomError extends Error {
    code: number;
    errorDetails: any;
    status: any
    headers: any
  }
  
  export class NetworkError extends Error implements ICustomError {
    code: number
    errorDetails: any
    status: any
    headers: any
  
    constructor(message: string, code: number, errorDetails: any, status?: any, headers? : any) {
      super(message);
      this.name = 'NetworkError';
      this.code = code;
      this.errorDetails = errorDetails;
      this.status = status;
      this.headers = headers;
  
      // TypeScript에서의 상속 문제를 해결하기 위해 다음 라인을 추가합니다.
      Object.setPrototypeOf(this, NetworkError.prototype);
    }
  }