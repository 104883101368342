import { CoreTextInput } from "@core2/form";
import { Stack } from "@mui/material";


export const SearchTextInput = (props: any) => {
    let { ...rest } = props;
    return <Stack spacing={1}>
        <CoreTextInput sx={{
            backgroundColor: '#ffffff', // 원하는 배경색으로 변경
        }} {...rest} />
    </Stack>
}