
import { get } from "lodash-es";
import { useRecordContext } from '@core2/record';


export const CoreTextField = (props) => {
    const { source, emptyText = '' } = props
    let record = useRecordContext()
    record = props.record || record
    let text = get(record, source)
    text = text ? text.toString() : emptyText
    return <>
        {text}
    </>
};