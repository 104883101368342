import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { ContentList } from '../../interfaces/content-list.interface';
import { InquiryPropsBrands, InquiryRecordData, InquiryResponseData } from '../../interfaces/inquiry.interface';
import { useGetContentList } from '../../hooks';
import { useContentDataTypeContext } from '../../providers';

//  CrawlContentContext 에 의해서 제공되는 data 의 타입
// export interface CrawlContentContextDataOld {
//     contents: ContentList;
//     channels: string[];
//     channelDetails: {
//         id: number|string
//         value: number|string
//         name: string;
//         label: string;
//     }[];
//     keyword: string;
//     myBrand: InquiryPropsBrands
//     otherBrands: InquiryPropsBrands[]
//     allBrands: InquiryPropsBrands[]
//     inquiryResponse: InquiryResponseData
// }

////////////////////////////////////////////////////////////
// 사용되지 않음
export interface CrawlContentContextData {
    // contents: ContentList;
    channels: string[];
    channelDetails: {
        id: number | string
        value: number | string
        name: string;
        label: string;
    }[];
    // keyword: string;
    // myBrand: InquiryPropsBrands
    // otherBrands: InquiryPropsBrands[]
    // allBrands: InquiryPropsBrands[]
    // inquiryResponse: InquiryResponseData
    record: InquiryRecordData
}
////////////////////////////////////////////////////////////


interface CrawlContentContextType {
    // data: CrawlContentContextData
    isLoading: boolean;
    isError: boolean;
}

// CrawlContentContext 에 입력되는 value 타입
interface CrawlContentContextValueProp {
    entities: string[]
    channels: string[]
    inquiry: InquiryRecordData
}


interface CrawlContentContextProps {
    children?
    value: CrawlContentContextValueProp

    emptyComponent?: React.ReactNode
}


const CrawlContentContext = createContext<CrawlContentContextType | undefined>(undefined);
CrawlContentContext.displayName = 'CrawlContentContext';



// 크롤링된 세부 콘텐츠 데이터를 쿼리해서 inquiry 데이터에 추가한다
export const CrawlContentContextProvider = (props: CrawlContentContextProps) => {
    const { children, value } = props
    const { getDataType, getDataTypeNameById, isLoading: isLoadingDataTypes } = useContentDataTypeContext();
    const { inquiry, entities, channels } = value
    // inquiry 에 연관된 data 를 추가로 쿼리한다
    const { data: contentList, isLoading: isLoadingContentList, ...rest } = useGetContentList({
        table: entities,
        inquiryId: inquiry.id,
        keyword: inquiry.keyword,
        // isSample: inquiry.isSample
    });
    const isLoading = useMemo(() => {
        const isLoading = isLoadingDataTypes || isLoadingContentList
        if (!isLoading) {
            const channelDetails = channels.map(c => getDataType(c))
            if (inquiry?.setContentList) {
                inquiry.setContentList(contentList, channelDetails)
            }
        }
        return isLoading
    }, [isLoadingDataTypes, isLoadingContentList])
    // const isLoading = isLoadingDataTypes || isLoadingContentList
    // const channelDetails = channels.map(c => getDataType(c))
    const providerValue: CrawlContentContextType = {
        // data: {
        //     channels,
        //     channelDetails,
        //     record
        // },
        isLoading,
        isError: rest.isError,
    }
    return <CrawlContentContext.Provider value={providerValue}>
        {/* {contentList.contents.length > 0 ? children : emptyComponent ||'항목이 없습니다'}    */}
        {children}
    </CrawlContentContext.Provider>
}

export const useViralContentContext = (): CrawlContentContextType => {
    const context = useContext(CrawlContentContext);
    if (context === undefined) {
        throw new Error('useViralContent must be used within a ViralContentProvider');
    }
    return context;
}

export const useCrawlContentContext = (): CrawlContentContextType => {
    const context = useContext(CrawlContentContext);
    if (context === undefined) {
        throw new Error('useCrawlContentContext must be used within a CrawlContentContextProvider');
    }
    return context;
}



