
import { ImageStorelink } from "@components/banners";
import { ClientContainer } from "@containers/common/view-container";
import { SIZE_INDEX } from "@interfaces/index";
import { Container, List, ListItemButton, ListItemText, Stack } from "@mui/material";
import { ListItem, Typography } from "@mui/material";
import { Box } from "@mui/material";

export const FooterContainer = props => {

  // Define the footer links with labels and URLs
  const footerLinks = [
    { label: "회사 소개", href: "https://team.storelink.io", target: "_blank" },
    { label: "채용 안내", href: "https://storelink.career.greetinghr.com/", target: "_blank" },
    { label: "개인정보 처리방침", href: "/doc/privacy", target: "_self" },
    { label: "이용약관", href: "/doc/terms", target: "_self" },
  ];
  const app_version = import.meta.env.VITE_APP_VERSION
  // console.log('import.meta.env', import.meta.env)
  // console.log('app_version', app_version)
  // console.log('app_version2', __APP_VERSION__)
  return (

      
        <Box
      sx={{
        backgroundColor: "#1B1C1E",
        color: "#5A5C63",
        py: "60px",
        // fontFamily: "Arial, sans-serif",
      }}
    >
      <ClientContainer>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" spacing={1} alignItems="start" >
            <ImageStorelink style={{height: 17, marginBottom: "5px"}}/>
            <Typography variant="body2" >
              {`Copyright 2024. storelink All rights (v${app_version})`}
            </Typography>
            <Typography variant="body2" sx={{mb: "2px"}}>
              상호: 주식회사 스토어링크 | 대표: 정용은 | 개인정보보호책임자: 권기인
            </Typography>
            <Typography variant="body2" sx={{mb: "1px"}}>
              주소: 서울시 중구 중림로 49 1F 스토어링크
            </Typography>
            <Typography variant="body2" sx={{mb: "1px"}}>
              {`대표 번호: 02-365-7783 | 사업자등록번호: 439-85-01354`}
            </Typography>
            <Typography variant="body2" >
              {`v${app_version}`}
            </Typography>
          </Stack>

          {/* Right Section */}
          <Box>
            <Typography variant="body1" color="white">ABOUT US</Typography>
            <List sx={{ padding: 0 }}>
              {footerLinks.map((link, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    component="a"
                    href={link.href}
                    target={link.target}
                    sx={{
                      fontSize: "14px",
                      // color: "#ccc",
                      padding: 0,
                      // marginBottom: "8px",
                      "&:hover": { color: "white" },
                    }}
                  >
                    <ListItemText primary={link.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Stack>
        </ClientContainer>
    </Box>
    

  );
}