import { useCallback } from 'react';
import ReactDOM from 'react-dom';
import debounce from 'lodash-es/debounce';
import union from 'lodash-es/union';
import isEqual from 'lodash-es/isEqual';

import { useEffect } from 'react';
import { Refetch } from './useQueryWithStore';
import {useSafeSetState} from "@core2/index";
import {DataProviderProxy, Identifier, useDataProvider} from "@core2/index";

type Callback = (args?: any) => void;
type SetState = (args: any) => void;
interface Query {
    ids: Identifier[];
    onSuccess: Callback;
    onFailure: Callback;
    setState: SetState;
}
interface QueriesToCall {
    [resource: string]: Query[];
}
interface UseGetManyOptions {
    onSuccess?: Callback;
    onFailure?: Callback;
    enabled?: boolean;
}
interface UseGetManyResult {
    // data: Record[];
    data: any[];
    error?: any;
    loading: boolean;
    loaded: boolean;
    refetch: Refetch;
}
let queriesToCall: QueriesToCall = {};
let dataProvider: DataProviderProxy;

// const DataProviderOptions = { action: CRUD_GET_MANY };
const DataProviderOptions = {  };

const useGetMany = (
    resource: string,
    ids: Identifier[],
    options: UseGetManyOptions = { enabled: true }
): UseGetManyResult => {
    // we can't use useQueryWithStore here because we're aggregating queries first
    // therefore part of the useQueryWithStore logic will have to be repeated below
    // const selectMany = useMemo(makeGetManySelector, []);
    // const data = useSelector((state: ReduxState) =>
    //     selectMany(state, resource, ids)
    // );
    const data = []
    // const version = useVersion(); // used to allow force reload
    // used to force a refetch without relying on version
    // which might trigger other queries as well
    const [innerVersion, setInnerVersion] = useSafeSetState(0);

    const refetch = useCallback(() => {
        setInnerVersion(prevInnerVersion => prevInnerVersion + 1);
    }, [setInnerVersion]);

    const [state, setState] = useSafeSetState({
        data,
        error: null,
        loading: ids.length !== 0,
        loaded: data.length !== 0 && !data.includes(undefined),
        refetch,
    });
    if (!isEqual(state.data, data)) {
        setState({
            ...state,
            data,
        });
    }
    dataProvider = useDataProvider(); // not the best way to pass the dataProvider to a function outside the hook, but I couldn't find a better one
    useEffect(
        () => {
            if (options.enabled === false) {
                return;
            }

            if (!queriesToCall[resource]) {
                queriesToCall[resource] = [];
            }
            /**
             * queriesToCall stores the queries to call under the following shape:
             *
             * {
             *   'posts': [
             *     { ids: [1, 2], setState }
             *     { ids: [2, 3], setState, onSuccess }
             *     { ids: [4, 5], setState }
             *   ],
             *   'comments': [
             *     { ids: [345], setState, onFailure }
             *   ]
             * }
             */
            queriesToCall[resource] = queriesToCall[resource].concat({
                ids,
                setState,
                onSuccess: options && options.onSuccess,
                onFailure: options && options.onFailure,
            });
            callQueries(); // debounced by lodash
        },
        /* eslint-disable react-hooks/exhaustive-deps */
        [
            JSON.stringify({
                resource,
                ids,
                options,
                version: null,
                innerVersion,
            }),
            dataProvider,
        ]
        /* eslint-enable react-hooks/exhaustive-deps */
    );

    return state;
};

const callQueries = debounce(() => {
    const resources = Object.keys(queriesToCall);
    resources.forEach(resource => {
        const queries = [...queriesToCall[resource]]; // cloning to avoid side effects
        /**
         * Extract ids from queries, aggregate and deduplicate them
         *
         * @example from [[1, 2], [2, null, 3], [4, null]] to [1, 2, 3, 4]
         */
        const accumulatedIds = queries
            .reduce((acc, { ids }) => union(acc, ids), []) // concat + unique
            .filter(v => v != null && v !== ''); // remove null values
        if (accumulatedIds.length === 0) {
            // no need to call the data provider if all the ids are null
            queries.forEach(({ ids, setState, onSuccess }) => {
                setState({
                    data: emptyArray,
                    loading: false,
                    loaded: true,
                });
                if (onSuccess) {
                    onSuccess({ data: emptyArray });
                }
            });
            return;
        }
        dataProvider
            .getMany(resource, { ids: accumulatedIds }, DataProviderOptions)
            .then(response =>
                // Forces batching, see https://stackoverflow.com/questions/48563650/does-react-keep-the-order-for-state-updates/48610973#48610973
                ReactDOM.unstable_batchedUpdates(() =>
                    queries.forEach(({ ids, setState, onSuccess }) => {
                        setState(prevState => ({
                            ...prevState,
                            error: null,
                            loading: false,
                            loaded: true,
                        }));
                        if (onSuccess) {
                            const subData = ids.map(
                                id =>
                                    response.data.find(datum => datum.id == id) // eslint-disable-line eqeqeq
                            );
                            onSuccess({ data: subData });
                        }
                    })
                )
            )
            .catch(error =>
                ReactDOM.unstable_batchedUpdates(() =>
                    queries.forEach(({ setState, onFailure }) => {
                        setState({ error, loading: false, loaded: false });
                        onFailure && onFailure(error);
                    })
                )
            );
        delete queriesToCall[resource];
    });
});

const emptyArray = [];

export default useGetMany;
