
import { createContext } from 'react';

export interface SAVE_CONTEXT_SAVE_ARGS {
    data: any
    ev: any
}

export const SaveContext = createContext(
    undefined
);

SaveContext.displayName = 'SaveContext';
