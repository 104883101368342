/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024/03/01, v1.0.1
 *
 *
 * front v1.1.10 기준으로 해당 프로젝트보다 최신 버전이다
 */
import dayJs from "dayjs";
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
// import XlsxPopulate from 'xlsx-populate';
// import { Buffer } from 'buffer'; 

const CelStyles = {
    header: {
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFC0C0C0' },
        },
        border: {
            top: {
                style: 'thin',
                color: { argb: 'FF000000' },
            },
            left: {
                style: 'thin',
                color: { argb: 'FF000000' },
            },
            bottom: {
                style: 'thin',
                color: { argb: 'FF000000' },
            },
            right: {
                style: 'thin',
                color: { argb: 'FF000000' },
            }
        },
    },

    yellowHeader: {
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF00' },
        },
    },

    box: {
        border: {
            top: {
                style: 'thin',
                color: { argb: 'FF000000' },
            },
            left: {
                style: 'thin',
                color: { argb: 'FF000000' },
            },
            bottom: {
                style: 'thin',
                color: { argb: 'FF000000' },
            },
            right: {
                style: 'thin',
                color: { argb: 'FF000000' },
            }
        }
    },

    subTitle: {
        font: {
            bold: true
        }
    },

    link: {
        font: {
            color: { argb: 'FF0000FF' },  // Font color blue
            underline: true               // underline
        }
    },

    red: {
        font: {
            color: { argb: 'FFF51D21' },  // Font color blue
            // underline: true               // underline
        }
    }
}

const CelAlignments = {
    wrap: {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'left',
    },
    center: {
        horizontal: 'center',
    },
    left: {
        horizontal: 'left',
    },
    middle: {
        vertical: 'middle',
    }
}

type LAYOUT_ITEM = number[]

type XLS_HEADER_ITEM = string | { text: string, width?: number, height?: number }
type XLS_CELL_ITEM = string | number | { text: string, width?: number, height?: number }
type XLS_ROW_ITEM = XLS_CELL_ITEM[]

export interface XLS_TABLE {
    layout: {
        header: LAYOUT_ITEM
        data: LAYOUT_ITEM
        colWidth?: LAYOUT_ITEM
    },
    title?: {
        text: string
    },
    headers: XLS_HEADER_ITEM[]
    data: XLS_ROW_ITEM[]
}

export class XlsSheet {
    workbook: any
    worksheet: any
    fill = null

    create(sheetName: string) {
        this.workbook = new ExcelJS.Workbook();
        if (sheetName) {
            this.worksheet = this.workbook.addWorksheet(sheetName);
        }
    }

    toRange(row1: number, col1: number, row2: number, col2: number) {
        const r1 = String.fromCharCode(64 + col1) + row1;
        const r2 = String.fromCharCode(64 + col2) + row2;
        return `${r1}:${r2}`
    }

    setValue(row: number, col: number, data: object | any, style = null) {
        const cell = this.worksheet.getCell(row, col)
        if (typeof data === 'object') {
            const { value, link, merge, style, size, pos, color } = data
            // console.log(`value ${row}, ${col} : ${value}`)
            if (link) {
                cell.value = {
                    text: value,
                    hyperlink: link,
                    // tooltip: 'Click to open link'
                }
                this.setCellStyle(row, col, 'link')
            } else {
                if (value !== null && value !== undefined) {
                    cell.value = value
                }
            }
            if (color) {
                this.setCellColor(row, col, color)
            }
            if (style) {
                this.setCellStyle(row, col, style)
            }
            if (merge) {
                const { w, h } = merge
                if (w > 0 || h > 0) {
                    // console.log(`merge ${row}, ${col} : ${w}, ${h}`)
                    this.merge(row, col, row + h, col + w)
                }
            }
            if (size) {
                cell.font = {
                    size
                }
            }
            if (pos) {
                this.setCellTextPosition(row, col, pos.h, pos.v)
            }
        } else {
            cell.value = data
            if (style) {
                this.setCellStyle(row, col, style)
            }
        }
    }

    async setImage(image, row1: number, col1: number, row2: number, col2: number) {
        const imageId = this.workbook.addImage({
            base64: image,
            extension: 'png',
        })
        const r = this.toRange(row1, col1, row2, col2)
        await this.worksheet.addImage(imageId, r);
    }

    setMergedValue(row: number, col: number, width: number, height: number, value, style = null) {
        this.setValue(row, col, value, style)
        this.merge(row, col, row + height, col + width)
    }

    setCellAlignment(row: number, col: number, alignment) {
        const cell = this.worksheet.getCell(row, col)
        cell.alignment = Object.assign(cell.alignment || {}, CelAlignments[alignment])
    }

    setCellTextPosition(row: number, col: number, h, v) {
        const cell = this.worksheet.getCell(row, col)
        const alignment = cell.alignment || {}
        if (v) alignment.vertical = v
        if (h) alignment.horizontal = h
        cell.alignment = alignment
    }

    setCellStyle(row: number, col: number, style) {
        const cell = this.worksheet.getCell(row, col)
        if (CelStyles[style].fill) {
            cell.fill = CelStyles[style].fill
        }
        if (CelStyles[style].border) {
            cell.border = CelStyles[style].border
        }
        if (CelStyles[style].font) {
            cell.font = CelStyles[style].font
        }
    }

    setCellColor(row: number, col: number, color: any) {
        const cell = this.worksheet.getCell(row, col)
        cell.font = {
            color: { argb: 'FF' + color },  // Font color blue
        }
    }

    setCellFontSize(row: number, col: number, size) {
        const cell = this.worksheet.getCell(row, col)
        cell.font = {
            size
        }
    }

    setCellStyleArea(startRowIndex: number, startColumnIndex: number, endRowIndex: number, endColumnIndex: number, style) {
        for (let i = startColumnIndex; i <= endColumnIndex; i++) {
            for (let j = startRowIndex; j <= endRowIndex; j++) {
                this.setCellStyle(j, i, style)
            }
        }
    }


    merge(startRowIndex: number, startColumnIndex: number, endRowIndex: number, endColumnIndex: number) {
        this.worksheet.mergeCells(startRowIndex, startColumnIndex, endRowIndex, endColumnIndex);
        // console.log(startRowIndex, startColumnIndex, endRowIndex, endColumnIndex)
    }

    writeFile(name: string) {
        this.workbook.xlsx.writeBuffer()
            .then(buffer => FileSaver.saveAs(new Blob([buffer]), `${name}_${dayJs().format('YYYY-MM-DD')}.xlsx`))
            .catch(err => console.log('Error writing excel export', err))
    }

    async writeFileWithPassword(name: string, password: string) {
        // 2️⃣ ExcelJS 워크북을 메모리에서 `Buffer`로 변환
        const excelBuffer = await this.workbook.xlsx.writeBuffer();
        // ✅ 3. Buffer를 Uint8Array로 변환 (xlsx-populate 호환)
        const uint8Array = new Uint8Array(excelBuffer);

        // 3️⃣ XlsxPopulate에서 `Buffer`를 읽어 비밀번호 보호 적용
        // const xlsxWorkbook = await XlsxPopulate.fromDataAsync(excelBuffer);
        // ✅ 4. XlsxPopulate에서 Buffer를 읽고 암호화
        //@ts-ignore
        const xlsxWorkbook = await XlsxPopulate.fromDataAsync(uint8Array);
        const protectedBuffer = await xlsxWorkbook.toBufferAsync({ password });

        // 4️⃣ Blob으로 변환 후 파일 다운로드
        const blob = new Blob([protectedBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, 'protected_excel.xlsx');
    }

    setColumnWidth(col: number, width: number) {
        this.worksheet.getColumn(col).width = width;
    }

    setRowHeight(row: number, height: number) {
        this.worksheet.getRow(row).height = height;
    }

    /////////////////////////////////////////////////////

    setDateField(row: number, col: number, date: string, width, height) {
        this.setValue(row, col, date)
        if (width > 1 || height > 1) {
            this.merge(row, col, row, col + width - 1)
        }
        this.setCellTextPosition(row, col, 'right', null)
    }

    /////////////////////////////////////////////////////

    getTableTextAndSize(value: object | any, options: any = {}) {
        const { width = 1, height = 1 } = options
        let w = width, h = height
        let text = value
        if (typeof value === 'object') {
            // const {text} = value
            w = (value.width || width)
            h = (value.height || height)
            text = value.text
        }
        return [text, w, h]
    }

    setTableValue(row: number, col: number, value, options: any = {}) {
        const { width, height, style, horizontal, vertical } = options
        let w = width, h = height
        let horiz = horizontal
        let vert = vertical
        if (typeof value === 'object') {
            const { text, link, color } = value
            w = (value.width || width)
            h = (value.height || height)
            horiz = value.horizontal || horizontal
            vert = value.vertical || vertical
            this.setValue(row, col, {
                value: text,
                link,
                style,
                merge: { w: w - 1, h: h - 1 },
                color,
            })
        } else {
            this.setValue(row, col, {
                value,
                style,
                merge: { w: w - 1, h: h - 1 },
            })
        }
        if (horiz) {
            this.setCellAlignment(row, col, horiz)
        }
        if (vert) {
            this.setCellAlignment(row, col, vert)
        }
        return [w, h]
    }

    setTableTitle(row: number, col: number, title) {
        const { text, rest } = title
        this.setValue(row, col, {
            value: text,
            merge: {
                w: 0,
                h: 0,
            },
            style: 'subTitle',
            ...rest
        })
    }

    getListByRow(data, row: number) {
        let rowData = []
        for (let j = 0; j < data.length; j++) {
            rowData.push(data[j][row])
        }
        return rowData
    }

    getMergeGroup(data, options) {
        const list = []
        if (!data.length) {
            return list
        }
        const [text, w, h] = this.getTableTextAndSize(data[0], options)
        let group = {
            text,
            count: h,
        }
        list.push(group)
        for (let i = 1; i < data.length; i++) {
            const res = this.getTableTextAndSize(data[i], options)
            const text = res[0]
            if (text === '') {
                // 이전 행과 머지
                group.count++;
            } else {
                // new text
                group = {
                    text,
                    count: res[2],
                }
                list.push(group)
            }
        }
        return list
    }

    setHeaderWidth(col: number, colWidth: number[]) {
        for (let i = 0; i < colWidth.length; i++) {
            if (colWidth[i] > 0) {
                this.setColumnWidth(col + i, colWidth[i])
            }
        }
    }

    setVerticalTable(row: number, col: number, table: XLS_TABLE, options: any = {}) {
        const { horizontal, vertical, merge = true } = options
        if (table.title) {
            this.setTableTitle(row, col, table.title)
            row++
        }
        if (table.layout.colWidth && table.layout.colWidth[0]) {
            this.setHeaderWidth(col, table.layout.colWidth)
        }
        let rowIndex = row
        let colIndex = col
        const { layout, headers, data } = table
        const headerWidth = layout.header[0] || 1
        const headerHeight = layout.header[1] || 1
        const dataWidth = layout.data[0] || 1
        const dataHeight = layout.data[1] || 1
        for (const value of headers) {
            const [w, h] = this.setTableValue(rowIndex, colIndex, value, {
                width: headerWidth,
                height: headerHeight,
                style: 'header',
                horizontal,
                vertical
            })
            colIndex += w
        }
        rowIndex += headerHeight
        if (data ) {
            for (const values of data) {
                colIndex = col
                for (const value of values) {
                    const [w, h] = this.setTableValue(rowIndex, colIndex, value, {
                        width: dataWidth,
                        height: dataHeight,
                        horizontal,
                        vertical
                    })
                    colIndex += w
                }
                rowIndex += dataHeight
            }

            if (merge) {
                for (let i = 0; i < headers.length; i++) {
                    const rowData = this.getListByRow(data, i)
                    const groupList = this.getMergeGroup(rowData, {
                        width: headerWidth,
                        height: headerHeight,
                    })
                    let start = row + layout.header[1];

                    for (const group of groupList) {
                        if (group.count > 1) {
                            // console.log('merge', start, i, start + group.count - 1, i)
                            this.merge(start, i + col, start + group.count - 1, i + col)
                            this.setCellAlignment(start, i + col, 'middle')
                            start += group.count - 1
                        }
                        start++
                    }
                }
            }
        }


        this.setCellStyleArea(row, col, rowIndex - 1, colIndex - 1, 'box')
        return rowIndex
    }

    setHorizontalTable(row: number, col: number, table: XLS_TABLE, options: any = {}) {
        const { horizontal, vertical } = options
        const { title } = table
        if (title) {
            this.setTableTitle(row, col, title)
            row++
        }

        let rowIndex = row
        let colIndex = col
        const { layout, headers, data } = table
        const headerWidth = layout.header[0] || 1
        const headerHeight = layout.header[1] || 1
        const dataWidth = layout.data[0] || 1
        const dataHeight = layout.data[1] || 1
        for (const value of headers) {
            const [w, h] = this.setTableValue(rowIndex, colIndex, value, {
                width: headerWidth,
                height: headerHeight,
                style: 'header',
                horizontal,
                vertical
            })
            rowIndex += h
        }
        colIndex += headerWidth
        rowIndex = row
        if (data) {
            for (const values of data) {
                for (const value of values) {
                    const [w, h] = this.setTableValue(rowIndex, colIndex, value, {
                        width: dataWidth,
                        height: dataHeight,
                        horizontal,
                        vertical,
                    })
                    rowIndex += h
                }
                colIndex += dataWidth
            }
        }
        this.setCellStyleArea(row, col, rowIndex - 1, colIndex - 1, 'box')
        return rowIndex
    }
}
