/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2024/07/12
 */

import { useCallback } from "react"


export const useFormHandleController = (props: any) => {
    // const {onSave} = props
    // const save = useCallback(async (data) => {
    //     // do some code
    //     // if (onSave) {
    //     //     onSave(data)
    //     // }
    // }, [])
    const save = useCallback(async (finalValues, event) => {
        try {
            // console.log('save')
            // if (transform) {
            //     await transform(finalValues)
            // }
            // const data = await dataProvider.create(resource, {
            //     // id: finalValues.id,
            //     data: finalValues,
            // })
            // if (onSuccess) {
            //     await onSuccess(data) //, variables, context)
            // }
            // switch (typeof finalRedirectTo) {
            //     case 'function':
            //         finalRedirectTo(data)
            //         break
            //     case 'string':
            //         navigate(finalRedirectTo)
            //         break
            //     default:
            //         break
            // }
        }
        catch (error) {
            console.log(error.message)
            // if (onError) {
            //     if (onError(error)) {
            //         return
            //     }
            // }
            throw error
        }
    }, [])
    return {
        // error,
        // isLoading,
        // isFetching,
        // record,
        // refetch,
        // resource,
        save,
    }
}