import { Box } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";



interface BackgroundImageContainerProps {
    imgSrc: string; // 배경 이미지 URL
    children: React.ReactNode;
    containerStyle?: React.CSSProperties
}

export const BackgroundImaginContainer: React.FC<BackgroundImageContainerProps> = ({
    imgSrc,
    children,
    containerStyle = {},
}) => {
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    useEffect(() => {
        const img = new Image();
        img.src = imgSrc;
        img.onload = () => {
            setImageSize({ width: img.width, height: img.height });
        };
    }, [imgSrc]);
    return (
        <Box
            sx={{
                position: "relative",
                backgroundImage: `url(${imgSrc})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                // maxWidth: `${imageSize.width}px`, // 이미지 크기 반영
                minHeight: `${imageSize.height}px`,
                padding: "48px",
                ...containerStyle,
            }}
        >
            {children}
        </Box>
    );
};
