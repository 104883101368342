import { BackgroundImaginContainer, LinkArrow, MultilineTypography } from "@components/custom-ui";
import { Box, Stack, Typography } from "@mui/material";


export const BannerWelcomeOld = () => {
    return <img src="/img/welcome.png" alt="Welcome" />
}

const ImageAndTextBanner2 = (props: any) => {
    const { id, imgSrc, copy, title, description, link } = props;
    return <BackgroundImaginContainer imgSrc={imgSrc} containerStyle={{ borderRadius: "16px"}}>
        <Box sx={{mt: 4}}></Box>        
        <MultilineTypography variant="h4" lineHeight={"36px"} text={description} color="white" />
    </BackgroundImaginContainer>
}

export const BannerWelcome = () => {
    return <ImageAndTextBanner2
        id="banner-home-2"
        imgSrc="/img/welcome2.png"
        description={`트렌드링크의 다양한 서비스와 기능들을 활용하여,
        트렌드 분석에 필요한 모든것을 경험하세요!`}
    />
}


export const BannerHome1 = (props) => {
    return <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img
            src="/img/home-1.png"
            alt="Home banner"
            style={{
                maxWidth: '1920px',
                height: '395px'
            }}
        />
    </Box>
}



const ImageAndTextBanner = (props: any) => {
    const { id, imgSrc, copy, title, description, link } = props;
    return <BackgroundImaginContainer imgSrc={imgSrc} containerStyle={{ borderRadius: "16px" }}>
        <Stack direction="column" spacing={"16px"}>
            <MultilineTypography variant="h2" lineHeight={"60px"} text={copy} />
            <Typography variant="h3" >{title}</Typography>
            <MultilineTypography variant="h5" lineHeight={"30px"} text={description} />
            <LinkArrow id={id} variant="h6" url={link}>사용해 보기</LinkArrow>
        </Stack>
    </BackgroundImaginContainer>
}

export const BannerHome2 = () => {
    return <ImageAndTextBanner
        id="banner-home-2"
        imgSrc="/img/home-2.png"
        copy={`
            클릭 한 번으로
            내 브랜드의 트렌드를 한눈에
        `}
        title="트렌드 분석"
        description={`내 브랜드 트렌드 현황을 한 눈에 분석해줘요.
            점유율, 콘텐츠 수와 함께 비교 브랜드를 선택하여
            비교 분석할 수 있어요.`}
        link="/trend"
    />
}

export const BannerHome3 = () => {
    return <ImageAndTextBanner
        id="banner-home-3"
        imgSrc="/img/home-3.png"
        copy={`
            트렌드 콘텐츠를
            빠르게 분석
        `}
        title="콘텐츠 분석"
        description={`인기 콘텐츠를 분석하여 콘텐츠의 핵심 내용, 키워드 등 
            중요한 내용을 요약해줘요. 트렌드 콘텐츠에 필요한 
            가이드 생성도 확인할 수 있어요.`}
        link="/content"
    />
}

export const BannerHome4 = () => {
    return <ImageAndTextBanner
        id="banner-home-4"
        imgSrc="/img/home-4.png"
        copy={`
            창의성을 더한
            AI 콘텐츠 생성
        `}
        title="콘텐츠 생성"
        description={`AI가 인기 광고, 블로그 콘텐츠를 분석하여
            자동으로 생성해줘요. 콘텐츠를 만드는 시간을 
            획기적으로 줄여보세요.`}
        link="/generate"
    />
}



export const ImageStorelink = (props) => {
    return <img src="/img/img-SLlogo-footer.svg" alt="Storelink" {...props} />
}
