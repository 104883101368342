/**
 * Author : Bruce.Decent, the Sunbed Nomad
 * Date: 2021/05/01
 */

import {
    getAuth,
    setPersistence,
    browserSessionPersistence,
    signInWithEmailAndPassword,
    signOut,
    signInWithCustomToken,
    EmailAuthProvider,
    reauthenticateWithCredential,
} from "firebase/auth";
import { firebaseApp } from "./firebaseProvider";
import { navigateWithReload, UserIdentity } from "@core2/index";
import { getQueryClient } from "./QueryClient";

// const AuthStorageName = "auth";
export const AUTH_IDENTITY_KEY = "authIdentity";
export const IDENTITY_QUERY_KEY = "identity";
const PrivilegeStorageName = "pr";

const queryClient = getQueryClient();

export function retrieveStatusTxt(status) {
    // Make sure any successful status is OK.
    if (status >= 200 && status < 300) {
        return "ok";
    }
    switch (status) {
        case 401: // 'unauthenticated'
        case 403: // 'permission-denied'
            return "unauthenticated";

        case 0: // 'internal'
        case 400: // 'invalid-argument'
        case 404: // 'not-found'
        case 409: // 'aborted'
        case 429: // 'resource-exhausted'
        case 499: // 'cancelled'
        case 500: // 'internal'
        case 501: // 'unimplemented'
        case 503: // 'unavailable'
        case 504: // 'deadline-exceeded'
        default:
            // ignore
            return "ok";
    }
}



function getIdentityByIdTokenResult(idTokenResult): UserIdentity {
    const { token, expirationTime, claims } = idTokenResult;
    const { type, userId, email, name, picture, sub } = claims;
    const identity = {
        // id: sub,
        id: userId,
        fId: sub,
        type,
        name,
        token,
        expirationTime,
        email,
        picture,
    };
    return identity;
}



export function getPermission2() {
    let privilege = localStorage.getItem(PrivilegeStorageName);
    if (privilege && privilege !== "undefined") {
        privilege = JSON.parse(privilege);
        return privilege;
    }
    return {};
}

export const AuthProvider = {
    init: async () => {
        try {
            const auth = getAuth(firebaseApp);
            await setPersistence(auth, browserSessionPersistence);
        } catch (err) {
            console.error(err);
        }
    },

    // called when the user attempts to log in
    login: async ({ email, password }) => {
        // accept all username/password combinations
        // try {
        const auth = getAuth(firebaseApp);
        if (email && password) {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);

            // ID 토큰 Result 가져오기
            const idTokenResult = await userCredential.user.getIdTokenResult();
            const identity = getIdentityByIdTokenResult(idTokenResult);
            // 로컬 스토리지 저장
            localStorage.setItem(AUTH_IDENTITY_KEY, JSON.stringify(identity));

            console.log("login ok (email)");
            return userCredential;
        } else {
            return AuthProvider.getUserLogin2();
        }
    },
    // called when the user clicks on the logout button
    logout: async () => {
        const auth = getAuth(firebaseApp);
        /////////////////////////////////
        await signOut(auth);
        // queyy 무효화
        queryClient.invalidateQueries({ queryKey: [IDENTITY_QUERY_KEY] });
        console.log("logout");
        /////////////////////////////
    },

    // 비밀번호 변경 후 재로그인
    relogin: async (newPassword: string) => {
        const auth = getAuth(firebaseApp);
        const user = auth.currentUser;
        const email = user.email;
        // 비밀번호 변경 후 기존 로그인 세션은 더 이상 유효하지 않으므로 로그아웃합니다.
        await signOut(auth);
        // 새 비밀번호로 재로그인합니다.
        await signInWithEmailAndPassword(auth, email, newPassword);
    },

    // called when the API returns an error
    checkError: (errorHttp) => {
        const status = !!errorHttp && errorHttp.status;
        const statusTxt = retrieveStatusTxt(status);
        if (statusTxt === "ok") {
            // log('API is actually authenticated');
            return Promise.resolve();
        }
        console.warn("Received authentication error from API");
        return Promise.reject();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async () => {
        return AuthProvider.getUserLogin2();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: async () => {
        const privileges = getPermission2();
        // return Promise.resolve(privileges)
        return privileges;
    },
    getIdentity: async (): Promise<UserIdentity> => {
        // try {
        const user = await AuthProvider.getUserLogin2();
        if (!user) {
            // 사용자가 로그 아웃 됨
            return null;
        }
        if (!user.emailVerified) {
            // 이메일 인증 요망
        }
        const idToken = await user.getIdTokenResult();
        const identity = getIdentityByIdTokenResult(idToken);
        return identity;
    },

    getUserLogin2: (): any => {
        return new Promise((resolve, reject) => {
            const auth = getAuth(firebaseApp);
            if (auth.currentUser) {
                // 유저 정보 유효. 기존 정보
                return resolve({
                    user: auth.currentUser,
                    refresh: false
                });
            }
            // 유저 정보 무효, 새로 상태 확인
            const unsubscribe = auth.onAuthStateChanged((user) => {
                // 1회 subscribe 후 해제
                unsubscribe();
                if (user) {
                    // 유저 정보 유효. 새 정보
                    return resolve({
                        user: user,
                        refresh: true
                    });
                } else {
                    // 유저 정보 무효
                    return reject();
                }
            });
        })
    },

    // localstorage 사용하지 않는 버전
    getIdentity2: async (): Promise<UserIdentity> => {
        // 유저 정보가 무효이면 exception 발생
        try {
            const { user, refresh } = await AuthProvider.getUserLogin2();
            // 유효한 유저이나 캐시 무효 (토큰 만료). 새로 생성
            const idToken = await user.getIdTokenResult();
            if (idToken?.claims?.suspended === true) {
                // 중지된 사용자
                AuthProvider.logout()
                navigateWithReload('/suspended')
                return null
            }
            const identity = getIdentityByIdTokenResult(idToken);
            return identity;
        }
        catch (error) {
            if (!error) {
                console.error("사용자 인증 오류")
                return null
            }
            console.error("사용자 인증 오류(정지?)", error.message)
            return null
        }
    },

    refreshIdentity: async () => {
        const { user, refresh } = await AuthProvider.getUserLogin2();
        // 유효한 유저이나 캐시 무효 (토큰 만료). 새로 생성
        const idToken = await user.getIdTokenResult();
        const identity = getIdentityByIdTokenResult(idToken);
        // 캐시 갱신
        localStorage.setItem(AUTH_IDENTITY_KEY, JSON.stringify(identity));
        return identity;
    },

    loginWithCustomToken: async (token: string) => {
        try {
            const auth = getAuth(firebaseApp);
            const userCredential = await signInWithCustomToken(auth, token);
            const idTokenResult = await userCredential.user.getIdTokenResult();
            const identity = getIdentityByIdTokenResult(idTokenResult);
            return identity;
        }
        catch (error) {
            console.error("사용자 인증 오류", error.message)
            return null
        }
    },

    reAuthenticateUser: async (currentPassword: string) => {
        const auth = getAuth(firebaseApp);
        const user = auth.currentUser;

        if (!user) {
            throw new Error("사용자가 로그인되어 있지 않습니다.");
        }
        try {
            // 현재 비밀번호 확인
            const credential = EmailAuthProvider.credential(user.email, currentPassword);
            await reauthenticateWithCredential(user, credential);
    
            console.log("현재 비밀번호가 확인되었습니다.");
            return true
        } catch (error) {
            console.error("비밀번호 변경 실패:", error.message);
            return false
        }
    }

}
