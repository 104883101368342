

export const AppStyle = {
    primaryButton: {
        bgcolor: '#0066FF',
        borderRadius: '8px',
        px: 4,
        '&:hover': {
            bgcolor: '#0052CC'
        }
    }
}