import { useContext } from 'react';
import { RecordContext } from './RecordContext';


export const useRecordContext = (
    props?
) => {
    // Can't find a way to specify the RecordType when CreateContext is declared
    // @ts-ignore
    const context = useContext<RecordType>(RecordContext);
    return (props && props.record) || context;
};
