/**
 * Author : Bruce.Decent, the Sunbed Nomad
 * Date: 2021/08/14
 */


import { useParams } from "react-router";
import { FormHandleContextProvider } from "./FormHandleContextProvider";
import { useFormHandleController } from "./useFormHandleController";
import { ResourceContextProvider } from "@core2/resource";
import { usePickSaveContext } from "@core2/index";
import { SaveContextProvider } from "@core2/index";


export const FormHandleBase = props => {
    const { id, children, ...rest } = props
    const controllerProps = useFormHandleController({ id, ...rest })
    const body = <FormHandleContextProvider value={controllerProps}>
        {children}
    </FormHandleContextProvider>
    return props.resource ? (
        <SaveContextProvider value={usePickSaveContext(controllerProps)}>
        <ResourceContextProvider value={props.resource}>
            {body}
        </ResourceContextProvider>
        </SaveContextProvider>
    ) : body
}


export const FormHandle = (props: any) => {
    const { id, children, ...rest } = props
    const params = useParams()

    return <>
        <FormHandleBase id={id || params.id} {...rest}>
            {children}
        </FormHandleBase>
    </>
}
