/**
 * Author : Bruce.Park, the Eng/DBA
 * Date: 2023/05/13
 */

import {ResourceContext} from './ResourceContext';

export const ResourceContextProvider = (props) => {
    const {children, value} = props
    return value ? <ResourceContext.Provider value={value}>
            {children}
    </ResourceContext.Provider> : children
}